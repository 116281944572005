
nav {
    background: white;
    height:0;
    min-height: 0;
    overflow:hidden;
    transition: min-height .2s ease-in-out;
}

nav li {
    float:left;
    width:100%;
}

nav a {
    display:block;
    height:100%;
    width:100%;
    text-decoration:none;
    color:white;
}

nav .telContainer a {
    padding: 0;
}


.nav-toggle{
    display:block;
    position:absolute;
    right:.5em;
    top:1.5em;
    padding:1em;
    cursor:pointer;
    text-decoration:none;
}

.nav-toggle-subPage{
    display:block;
    position:relative;
    padding:1em;
    cursor:pointer;
    text-decoration:none;
}

#nav-open:target .nav-closed {
    display: block;
}

.nav-closed,
#nav-closed:target .nav-closed,
#nav-open:target .nav-open {
    display:none;
}

@media screen and (max-width: 975px) {

    .nav-toggle-subPage{
        position: absolute;
        top: 0;
        right: 0;
    }

    #nav-open:target .nav-closed {
        display:block;
        z-index: 70;
        font-size: 1.5em;
        position: absolute;
        right: .75em;
        top: .5em;
        height: 0;
        width: 0;
    }

    #nav-open:target nav {
        position: absolute;
        z-index: 50;
        top: 4em;
        height: auto;
        overflow: visible;
        padding-bottom: 20px;
        padding-top: 12px;
    }
    #nav-open {
        height: 64px;
    }

    #nav-open:target .nav-toggle-subPage.nav-closed{
        top: -0.4em;
        right: 1.4em;
    }

    .line {
        position: absolute;
        display: block;
        background: #3d3d3d;
        width: 18px;
        height: .15rem;
        border-radius: .2rem;
        left: 0;
        transition: all .6s cubic-bezier(.5, .1, 0, 1.2);
    }

    .line-1 {
        top: 0;
    }

    .line-2 {
        top: 7px;
        width: 14px;
        transform: translateX(2px);
        -webkit-transform: translateX(2px);
    }

    .line-3 {
        top: 14px;
    }
}

@media screen and (min-width:976px) {

    .nav-toggle {display:none !important;}

    .nav-toggle-subPage{
        left: 2.5em;
    }

    nav {
        width: 100%;
        display:flex !important;
        height:auto;
    }


    nav .servicesIsShowing_label {
        text-align: center;
    }


    nav li {
        flex-grow: 0;
        text-align: center;
        display: grid;
        justify-content: center;
        width: fit-content;
    }

    nav ul {
        gap: 2rem;
        float: right;
        margin: 0 auto;
        width: auto;
    }

    .subPageNav#nav-open:target nav {
        z-index: 50;
        height: auto;
        padding-bottom: 0px;
        padding-top: 12px;
        max-height: 500px;
        transition: max-height 0.25s ease-in;
    }

    .subPageNav nav {
        height: 0;
        max-height: 0;
        transition: max-height 0.15s ease-out;
    }

    .subPageNav#nav-open:target .sortingContainer  {
        display: none;
    }
}

#navServicesList,
#navCarsaleList{
    max-height: 0px;
    overflow: hidden;
    list-style: none;
    transition: all 1s cubic-bezier(.325, 1, .22, 1);
}


#servicesIsShowing,
#carsaleIsShowing{
    display: none;
}

.subPageNav#nav-open:target #servicesIsShowing:checked ~ .grid #navServicesList,
.home #servicesIsShowing:checked ~ .grid #navServicesList,
.subPageNav#nav-open:target #carsaleIsShowing:checked ~ .grid #navCarsaleList,
.home #carsaleIsShowing:checked ~ .grid #navCarsaleList {
    height: auto;
    max-height: 120vh;
    margin-top: .75rem;
    transition: all 1s cubic-bezier(.5, 1, .22, 1);
}


nav .servicesIsShowing_label,
nav .carsaleIsShowing_label {
    width: 100%;
    flex-grow: 1;
    align-items: center;
}
