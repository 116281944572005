.list-site-text {
    @apply text-gray-500;
}
.list-site-text h2  {
    @apply text-xl my-2;
}

.list-site-text h3 {
    @apply text-lg;
}

.list-site-text p {
    @apply mt-4 mb-6;
}

.list-site-text ul {
    @apply list-disc ml-4;
}