.star-rating-00 {
    background-image: url('../public/assets/images/star-rating-00.png');
    background-size: cover;
}
.star-rating-05 {
    background-image: url('../public/assets/images/star-rating-05.png');
    background-size: cover;
}
.star-rating-10 {
    background-image: url('../public/assets/images/star-rating-10.png');
    background-size: cover;
}
.star-rating-15 {
    background-image: url('../public/assets/images/star-rating-15.png');
    background-size: cover;
}
.star-rating-20 {
    background-image: url('../public/assets/images/star-rating-20.png');
    background-size: cover;
}
.star-rating-25 {
    background-image: url('../public/assets/images/star-rating-25.png');
    background-size: cover;
}
.star-rating-30 {
    background-image: url('../public/assets/images/star-rating-30.png');
    background-size: cover;
}
.star-rating-35 {
    background-image: url('../public/assets/images/star-rating-35.png');
    background-size: cover;
}
.star-rating-40 {
    background-image: url('../public/assets/images/star-rating-40.png');
    background-size: cover;
}
.star-rating-45 {
    background-image: url('../public/assets/images/star-rating-45.png');
    background-size: cover;
}
.star-rating-50 {
    background-image: url('../public/assets/images/star-rating-50.png');
    background-size: cover;
}