/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'), url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */ url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/roboto-v20-latin/roboto-v20-latin-regular.svg#Roboto') format('svg');
    /* Legacy iOS */
}


/* roboto-700 - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./fonts/roboto-v20-latin/roboto-v20-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'), url('./fonts/roboto-v20-latin/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/roboto-v20-latin/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-v20-latin/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */ url('./fonts/roboto-v20-latin/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/roboto-v20-latin/roboto-v20-latin-700.svg#Roboto') format('svg');
    /* Legacy iOS */
}


/* roboto-slab-regular - latin */

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'), url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-regular.woff') format('woff'), /* Modern Browsers */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-regular.svg#RobotoSlab') format('svg');
    /* Legacy iOS */
}


/* roboto-slab-700 - latin */

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'), url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-700.woff') format('woff'), /* Modern Browsers */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-700.svg#RobotoSlab') format('svg');
    /* Legacy iOS */
}


/* roboto-slab-600 - latin */

@font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-600.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Slab SemiBold'), local('RobotoSlab-SemiBold'), url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-600.woff') format('woff'), /* Modern Browsers */ url('./fonts/roboto-slab-v9-latin/roboto-slab-v9-latin-600.ttf') format('truetype') /* Safari, Android, iOS */
    /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('./fonts/roboto-v30-latin/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./fonts/roboto-v30-latin/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/roboto-v30-latin/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./fonts/roboto-v30-latin/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
    url('./fonts/roboto-v30-latin/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/roboto-v30-latin/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
