/* purgecss start ignore */
[data-theme="repareo"] {
    --color-primary: #2688D9;
    --color-primary-100: #DBEAFE;
    --color-primary-200: #67ace4;
    --color-primary-300: #51a0e1;
    --color-primary-400: #3c94dd;
    --color-primary-500: #3b82f6;
    --color-primary-600: #2563EB;
    --color-primary-700: #1e6dae;
    --color-primary-800: #1E40AF;
    --color-primary-900: #175282;
    --color-secondary: #10B981;
    --color-tertiary: #B45309;
    --color-success: #52B651;
    --color-warning: #FEF3C7;
    --color-error: #FF1010;
    --color-neutral-100: #F3F4F6;
    --color-neutral-500: #6B7280;
    --color-neutral-700: #E5E7EB;
    --color-neutral-900: #111827;
    --color-green: #14532d;
    --color-gold: #EAB308;
    --color-transparent: rgba(107, 114, 128, 0.75);
}

[data-theme="arval"] {
    --color-primary: #00925B;
    --color-primary-100: #F3F4F6;
    --color-primary-200: #33a87c;
    --color-primary-300: #1a9d6b;
    --color-primary-400: #1a9d6b;
    --color-primary-500: #00925B;
    --color-primary-600: #00925B;
    --color-primary-700: #00925B;
    --color-primary-800: #00925B;
    --color-primary-900: #005837;
    --color-secondary: #014590;
    --color-tertiary: #B45309;
    --color-success: #52B651;
    --color-warning: #FEF3C7;
    --color-error: #FF1010;
    --color-neutral-100: #F3F4F6;
    --color-neutral-500: #6B7280;
    --color-neutral-700: #E5E7EB;
    --color-neutral-900: #111827;
    --color-green: #14532d;
    --color-gold: #EAB308;
    --color-transparent: rgba(107, 114, 128, 0.75);
}

[data-theme="dbconnect"] {
    --color-primary: #E10019;
    --color-primary-100: #F3F4F6;
    --color-primary-200: #f55b5b;
    --color-primary-300: #f34343;
    --color-primary-400: #f22c2c;
    --color-primary-500: #E10019;
    --color-primary-600: #E10019;
    --color-primary-700: #E10019;
    --color-primary-800: #E10019;
    --color-primary-900: #a80e0e;
    --color-secondary: #001731;
    --color-tertiary: #B45309;
    --color-success: #52B651;
    --color-warning: #FEF3C7;
    --color-error: #FF1010;
    --color-neutral-100: #F3F4F6;
    --color-neutral-500: #7E7E7E;
    --color-neutral-700: #282d37;
    --color-neutral-900: #111827;
    --color-green: #14532d;
    --color-gold: #EAB308;
    --color-transparent: rgba(107, 114, 128, 0.75);
}


[data-theme="mobility-concept"] {
    --font-sans-serif: 'Roboto';
    --font-serif: 'Roboto Slab';
    --color-primary: #4478b0;
    --color-primary-100: #ecf1f7;
    --color-primary-200: #67ace4;
    --color-primary-300: #51a0e1;
    --color-primary-400: #3c94dd;
    --color-primary-500: #4478b0;
    --color-primary-600: #4478b0;
    --color-primary-700: #4478b0;
    --color-primary-800: #4478b0;
    --color-primary-900: #244262;
    --color-secondary: #001731;
    --color-tertiary: #e8b12e;
    --color-success: #52b651;
    --color-warning: #e8b12e;
    --color-error: #ef4444;
    --color-error-100: #fee2e2;
    --color-error-200: #fecaca;
    --color-error-300: #fca5a5;
    --color-error-400: #f87171;
    --color-error-500: #ef4444;
    --color-error-600: #dc2626;
    --color-error-700: #b91c1c;
    --color-error-800: #991b1b;
    --color-error-900: #7f1d1d;
    --color-neutral-100: #f8f9fc;
    --color-neutral-300: #D1D5DB;
    --color-neutral-500: #8f96a3;
    --color-neutral-700: #2f3237;
    --color-green: #14532d;
    --color-gold: #EAB308;
}

[data-theme="control-expert"] {
    --font-sans-serif: 'Roboto';
    --font-serif: 'Roboto Slab';
    --color-primary: #005699;
    --color-primary-100: #b3cce0;
    --color-primary-200: #80abcc;
    --color-primary-300: #669ac2;
    --color-primary-400: #3378ad;
    --color-primary-500: #005699;
    --color-primary-600: #005699;
    --color-primary-700: #005699;
    --color-primary-800: #005699;
    --color-primary-900: #001a2e;
    --color-secondary: #001731;
    --color-tertiary: #e8b12e;
    --color-success: #52b651;
    --color-warning: #e8b12e;
    --color-error: #ef4444;
    --color-error-100: #fee2e2;
    --color-error-200: #fecaca;
    --color-error-300: #fca5a5;
    --color-error-400: #f87171;
    --color-error-500: #ef4444;
    --color-error-600: #dc2626;
    --color-error-700: #b91c1c;
    --color-error-800: #991b1b;
    --color-error-900: #7f1d1d;
    --color-neutral-100: #f8f9fc;
    --color-neutral-300: #D1D5DB;
    --color-neutral-500: #8f96a3;
    --color-neutral-700: #2f3237;
    --color-green: #14532d;
    --color-gold: #EAB308;
}

[data-theme="leaseplan"] {
    --font-sans-serif: 'LeasePlan';
    --font-serif: 'LeasePlan';
    --color-primary: #f06400;
    --color-primary-100: #fce0cc;
    --color-primary-200: #fbd1b3;
    --color-primary-300: #f6a266;
    --color-primary-400: #f38333;
    --color-primary-500: #f06400;
    --color-primary-600: #f06400;
    --color-primary-700: #f06400;
    --color-primary-800: #f06400;
    --color-primary-900: #301400;
    --color-secondary: #001731;
    --color-tertiary: #e8b12e;
    --color-success: #52b651;
    --color-warning: #e8b12e;
    --color-error: #ef4444;
    --color-error-100: #fee2e2;
    --color-error-200: #fecaca;
    --color-error-300: #fca5a5;
    --color-error-400: #f87171;
    --color-error-500: #ef4444;
    --color-error-600: #dc2626;
    --color-error-700: #b91c1c;
    --color-error-800: #991b1b;
    --color-error-900: #7f1d1d;
    --color-neutral-100: #f8f9fc;
    --color-neutral-300: #D1D5DB;
    --color-neutral-500: #8f96a3;
    --color-neutral-700: #2f3237;
    --color-green: #14532d;
    --color-gold: #EAB308;
}
[data-theme="mehrmarkencenter"] {
    --color-primary: #1966A6;
    --color-primary-100: #DBEAFE;
    --color-primary-200: #F4F9FD;
    --color-primary-300: #D0E6F8;
    --color-primary-400: #ADD3F3;
    --color-primary-500: #1966A6;
    --color-primary-600: #1966A6;
    --color-primary-700: #1966A6;
    --color-primary-800: #1966A6;
    --color-primary-900: #1966A6;
    --color-secondary: #10B981;
    --color-tertiary: #B45309;
    --color-success: #52B651;
    --color-warning: #FEF3C7;
    --color-error: #FF1010;
    --color-neutral-100: #F3F4F6;
    --color-neutral-500: #6B7280;
    --color-neutral-700: #E5E7EB;
    --color-neutral-900: #111827;
    --color-green: #14532d;
    --color-gold: #EAB308;
    --color-transparent: rgba(107, 114, 128, 0.75);
}

[data-theme="bqc"],
[data-theme="liquimoly"],
[data-theme="autocrew"] {
    --color-primary: #0171ba;
    --color-primary-100: #DBEAFE;
    --color-primary-200: #F4F9FD;
    --color-primary-300: #D0E6F8;
    --color-primary-400: #ADD3F3;
    --color-primary-500: #1966A6;
    --color-primary-600: #1966A6;
    --color-primary-700: #1966A6;
    --color-primary-800: #1966A6;
    --color-primary-900: #1966A6;
    --color-secondary: #10B981;
    --color-tertiary: #B45309;
    --color-success: #52B651;
    --color-warning: #FEF3C7;
    --color-error: #FF1010;
    --color-neutral-100: #F3F4F6;
    --color-neutral-500: #6B7280;
    --color-neutral-700: #E5E7EB;
    --color-neutral-900: #111827;
    --color-green: #14532d;
    --color-gold: #EAB308;
    --color-transparent: rgba(107, 114, 128, 0.75);
}

/* purgecss end ignore */
