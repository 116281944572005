.garageList::-webkit-scrollbar{
    display: none;
}

.gm-fullscreen-control {
    display: none;
}

.garageListItem_slider .splide__arrow{
    display: none;
}
.garageListItem_slider:hover .splide__arrow{
    display: flex;
}

.garageListItem_slider .splide{
    visibility: initial;
}

.garageListItem_slider .splide__slide{
    width: 100%;
    height: 320px;
}

@media screen and (max-width:375px) {
    .garageListItem_slider .splide__slide{
        height: 218px;
    }
}

@media screen and (max-width:767px) {
    .garageListItem_slider .splide__slide{
        height: 275px
    }
}


ul.openingHoursList {
    list-style: none; /* Remove default bullets */
}

ul.openingHoursList li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: green; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    font-size: 2em;
}


ul.openingHoursList li.closed::before {
    color: #374151;
}

ul.openingHoursList li.open::before {
    color: #52B651;
}

.garageListItemCard {
    @apply relative grid grid-cols-12 gap-y-4 md:gap-y-0 rounded-xl bg-white max-w-screen-md font-sans my-12 lg:my-16 z-0;
}

.garageListItemCard__left {
    @apply col-span-12 md:col-span-4 lg:col-span-5 grid grid-cols-12 gap-y-3 h-fit;
}

.garageListItem__separator {
    @apply z-10 max-h-10 col-span-full text-center bg-gray-200 py-2 px-3 md:rounded-md;
}

.garageListItem__separator__inner {
    @apply text-sm leading-5 font-normal text-gray-700;
}

.garageListItemCard__right{
    @apply col-span-12 md:col-span-8 lg:col-span-7 grid gap-y-2 grid-cols-12 h-fit;
}

.garageListItemCard__right__garageConcept {
    @apply col-span-full md:mx-6 flex items-center gap-x-2 text-sm border-b border-gray-200 pb-3;
}

.garageListItemHeadline {
    @apply col-span-12 gap-x-3 md:px-6 flex items-center justify-between mb-2 mt-2;
}

.garageListItemHeadline__inner {
    @apply  text-base inline leading-6 font-semibold flex-grow;
}

.garageListItemHeadline__distance {
    @apply text-base pl-2 leading-6 font-normal;
}

.garageListItemHeadline__rating {
    @apply text-sm leading-5 font-semibold flex-shrink-0;
}

.garageListItem__brandIcons{
    @apply col-span-full  md:mx-6 block items-center text-gray-700 border-b border-gray-200 mb-3 pb-3;
}

.garageListItem__brandIcons__garageText{
   @apply text-sm leading-5 font-normal text-gray-700 bottom-1 relative;
}

.garageListItemCard__right__authorizedBrands{
    @apply col-span-full md:mx-6 border-b border-gray-200 pb-3;
}

.leaflet-popup-content .garageListItemCard__right__authorizedBrands,
.leaflet-popup-content .garageListItem__brandIcons{
    @apply mx-4;
}

.garageListItemCard__right__authorizedBrands__garageText{
    @apply block text-sm leading-5 font-normal text-gray-700;
}

.garageListItemCard__iconHints {
    @apply col-span-full md:mx-6 border-b border-gray-200 pb-1;
}

.garageListItem__iconHint {
    @apply w-full flex items-center mb-2;
}

.garageListItem__iconHint__text {
    @apply block overflow-hidden text-xs text-gray-700;
}

.garageListItem__iconHint__icon {
    @apply mr-4 float-left inline-block;
}

.garageListItemCard__information{
    @apply flex items-center col-span-full md:mx-6 text-base leading-6 font-semibold;
}

.garageListItem__quickview {
    @apply col-span-12 relative md:mx-6 z-10;
}

.garageListItem__quickview__button {
    @apply py-2 mb-2 md:mb-0 text-sm leading-5 font-medium w-full text-center bg-blue-50 rounded-md text-primary-700 shadow-sm;
}
.garageListItemCard__link{
    @apply absolute bottom-0 left-0 h-full w-full z-0 cursor-pointer;
}

.garageListItem__badge{
    @apply  md:col-span-4 text-center flex-grow py-2;
}

.garageListItem__badge__inner{
    @apply items-center rounded-full px-2.5 py-2 text-sm font-semibold leading-5 bg-secondary text-white mr-1 shadow-lg;
}

.garageListItemCard__badgeContainer{
    @apply  col-span-full flex flex-wrap absolute -top-6 lg:-top-10 z-10;
}

.garageListItemCard__readmoreButton{
    @apply text-primary text-sm border border-primary rounded-lg py-2 px-5 mt-3 inline-block cursor-pointer z-10;
}

.garageListMapMarker{
    @apply cursor-pointer inline-block relative bottom-6 z-10;
}

.garageListItem_slider {
    @apply  col-span-full drop-shadow-md shadow-black-200 z-10 md:order-1;
}

.garageListMapMarker__overlay{
    @apply z-10 shadow rounded-md bg-white w-screen md:w-80 fixed md:absolute mb-4 -left-[50%] md:left-auto md:right-[12%] bottom-[15vh] md:bottom-8;
}

.garageListItem__infoBlock{
   @apply col-span-9 pl-6 md:pl-8 pr-4;
}

.garageListItem__bookingInformation{
    @apply basis-1/2 p-6 md:p-4 md:pl-8 col-span-6 gap-0;
}

.garageListItem__review {
    @apply col-span-3 pr-6 md:pr-8 my-auto;
}

.garageListItem__review__rating {
    @apply text-xl leading-7 font-bold text-center text-white shadow-sm rounded-md bg-primary-800 block py-2 mx-1 md:mx-4;
}

.garageListHeader {
    @apply lg:shadow-md lg:border-b border-gray-500 w-full text-gray-700 bg-white md:fixed top-0 z-20;
}

.garageListHeader__bottom {
    @apply shadow-md lg:shadow-none grid grid-cols-12 items-center p-4 lg:px-8 lg:py-3;
}

.garageListHeader__inner {
    @apply flex justify-between content-start pb-4 col-span-full md:col-span-2 xl:col-span-3 items-center;
}

.garageListHeader__phoneNumber {
    @apply  basis-1/2 text-right block md:hidden  mr-10 lg:mr-0;
}

.garageListHeader__telContainer {
    @apply  text-right hidden lg:flex justify-end items-center gap-5 col-span-2 md:col-span-3 xl:col-span-2;
}

.garageListHeader__popoverButton {
    @apply  hidden lg:inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500;
}

.pullbar {
    @apply block lg:hidden w-14 h-2 mx-auto bg-gray-400 rounded-lg;
}

.leaflet-div-icon {
    border: none !important;
    background: none !important;
}

.leaflet-popup-content{
    margin: 0 !important;
    width: auto !important;
}


.leaflet-container a.leaflet-popup-close-button{
    right: .5rem !important;
    top: .5rem !important;
    background: white !important;
    border-radius: 50% !important;
}

.leaflet-container a.leaflet-popup-close-button span{
    color: #000000;
    position: relative;
    bottom: 2px;
}

.leaflet-marker-icon.leaflet-interactive svg{
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
}

.leaflet-popup-content-wrapper{
    padding: 0!important;
}


@media screen and (min-width:768px) {
    .leaflet-container .leaflet-control-container .leaflet-top {
        top: 1rem;
    }
}

.garageListMapMarker:hover {
    transform: scale(1.2);
    transform-origin: 25% 50%;
    transition: transform 150ms ease 0s;
}

.leaflet-container a.leaflet-popup-close-button{
    height: 23px;
    width: 23px;
    padding-top: 1px;
}

.markertriangle {
    position: relative;
    bottom: 14px;
    z-index: 0;
    right: 40%;
    width: 14px;
    height: 14px;
}

.leaflet-popup-content .garageListItem_slider{
    box-shadow: none;
    --tw-drop-shadow: none;
}

.leaflet-popup-content .garageListItem_slider .splide__slide,
.leaflet-popup-content .garageListItem_slider .splide__slide img {
    border-radius: 0.375rem 0.375rem 0 0;
}


.leaflet-popup-content .garageListItemCard__right__authorizedBrands,
.leaflet-popup-content .garageListItem__brandIcons {
    border: none;
    padding: 0;
    margin-bottom: 0;
}



.leaflet-popup-content .headlineContainer{
    padding-bottom: 0;
}

.garageProfile__hero .leaflet-container{
    z-index: 20;
}

#garage-location .leaflet-container {
    z-index: 5;
}
