.react-calendar {
    width: 100% !important;
    border-radius: 10px !important;
    border-color: #D1D5DB !important;
}
.react-calendar__navigation {
    text-align: center !important;
    font-weight: 900 !important;
}

.react-calendar__navigation__arrow {
    font-size: 1.7rem !important;
    color: var(--color-primary) !important;
    background: none !important;
}
.react-calendar__navigation__arrow:disabled {
    opacity: 0.6 !important;
}

.react-calendar__navigation__label {
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    background: none !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    font-family: 'Roboto', serif !important;
    text-transform: uppercase !important;
    text-decoration: none !important;
    font-weight: bold !important;
}

.react-calendar__month-view__days {
    font-family: 'Roboto Slab', serif !important;
    justify-content: space-between !important;
}

.react-calendar__month-view__days__day--weekend {
    color: black !important;
}

.react-calendar__tile,
.react-calendar__tile--now:focus {
    font-size: 0.9rem !important;
    font-weight: bold !important;
    flex: 0 0 13% !important;
    border-radius: 50% !important;
    padding-top: 3.5% !important;
    padding-bottom: 3.5% !important;
}

.react-calendar__tile--now, .react-calendar__tile--now:hover {
    background: none !important;
}

.react-calendar__tile:disabled {
    color: #D1D5DB !important;
    background: none !important;
    text-decoration: line-through !important;
}

.react-calendar__tile--active {
    color: white !important;
    background: var(--color-primary) !important;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0;
}

@media screen and (min-width: 768px) {
    .react-calendar {
        /*width: 300px !important;*/
    }
}
