.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa-solid,
.fa-regular,
.fa-brands,
.fas,
.far,
.fab,
.fal,
.fat,
.fad,
.fass,
.fasr,
.fasl,
.fast,
.fasds,
.fa-light,
.fa-thin,
.fa-duotone,
.fa-sharp,
.fa-sharp-duotone,
.fa-sharp-solid,
.fa-classic,
.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

.fas,
.far,
.fal,
.fat,
.fa-solid,
.fa-regular,
.fa-light,
.fa-thin,
.fa-classic {
  font-family: 'Font Awesome 6 Pro'; }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands'; }

.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: 'Font Awesome 6 Duotone'; }

.fasds,
.fa-sharp-duotone {
  font-family: 'Font Awesome 6 Sharp Duotone'; }

.fasds,
.fa-sharp-duotone {
  font-weight: 900; }

.fass,
.fasr,
.fasl,
.fast,
.fa-sharp {
  font-family: 'Font Awesome 6 Sharp'; }

.fass,
.fa-sharp {
  font-weight: 900; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  transform: rotate(90deg); }

.fa-rotate-180 {
  transform: rotate(180deg); }

.fa-rotate-270 {
  transform: rotate(270deg); }

.fa-flip-horizontal {
  transform: scale(-1, 1); }

.fa-flip-vertical {
  transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0)); }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto); }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before { content: "\30"; }
.fa-00::before { content: "\e467"; }
.fa-1::before { content: "\31"; }
.fa-100::before { content: "\e41c"; }
.fa-2::before { content: "\32"; }
.fa-3::before { content: "\33"; }
.fa-360-degrees::before { content: "\e2dc"; }
.fa-4::before { content: "\34"; }
.fa-5::before { content: "\35"; }
.fa-6::before { content: "\36"; }
.fa-7::before { content: "\37"; }
.fa-8::before { content: "\38"; }
.fa-9::before { content: "\39"; }
.fa-a::before { content: "\41"; }
.fa-abacus::before { content: "\f640"; }
.fa-accent-grave::before { content: "\60"; }
.fa-acorn::before { content: "\f6ae"; }
.fa-ad::before { content: "\f641"; }
.fa-add::before { content: "\2b"; }
.fa-address-book::before { content: "\f2b9"; }
.fa-address-card::before { content: "\f2bb"; }
.fa-adjust::before { content: "\f042"; }
.fa-air-conditioner::before { content: "\f8f4"; }
.fa-air-freshener::before { content: "\f5d0"; }
.fa-airplay::before { content: "\e089"; }
.fa-alarm-clock::before { content: "\f34e"; }
.fa-alarm-exclamation::before { content: "\f843"; }
.fa-alarm-plus::before { content: "\f844"; }
.fa-alarm-snooze::before { content: "\f845"; }
.fa-album::before { content: "\f89f"; }
.fa-album-circle-plus::before { content: "\e48c"; }
.fa-album-circle-user::before { content: "\e48d"; }
.fa-album-collection::before { content: "\f8a0"; }
.fa-album-collection-circle-plus::before { content: "\e48e"; }
.fa-album-collection-circle-user::before { content: "\e48f"; }
.fa-alicorn::before { content: "\f6b0"; }
.fa-alien::before { content: "\f8f5"; }
.fa-alien-8bit::before { content: "\f8f6"; }
.fa-alien-monster::before { content: "\f8f6"; }
.fa-align-center::before { content: "\f037"; }
.fa-align-justify::before { content: "\f039"; }
.fa-align-left::before { content: "\f036"; }
.fa-align-right::before { content: "\f038"; }
.fa-align-slash::before { content: "\f846"; }
.fa-allergies::before { content: "\f461"; }
.fa-alt::before { content: "\e08a"; }
.fa-ambulance::before { content: "\f0f9"; }
.fa-american-sign-language-interpreting::before { content: "\f2a3"; }
.fa-amp-guitar::before { content: "\f8a1"; }
.fa-ampersand::before { content: "\26"; }
.fa-analytics::before { content: "\f643"; }
.fa-anchor::before { content: "\f13d"; }
.fa-anchor-circle-check::before { content: "\e4aa"; }
.fa-anchor-circle-exclamation::before { content: "\e4ab"; }
.fa-anchor-circle-xmark::before { content: "\e4ac"; }
.fa-anchor-lock::before { content: "\e4ad"; }
.fa-angel::before { content: "\f779"; }
.fa-angle::before { content: "\e08c"; }
.fa-angle-90::before { content: "\e08d"; }
.fa-angle-double-down::before { content: "\f103"; }
.fa-angle-double-left::before { content: "\f100"; }
.fa-angle-double-right::before { content: "\f101"; }
.fa-angle-double-up::before { content: "\f102"; }
.fa-angle-down::before { content: "\f107"; }
.fa-angle-left::before { content: "\f104"; }
.fa-angle-right::before { content: "\f105"; }
.fa-angle-up::before { content: "\f106"; }
.fa-angles-down::before { content: "\f103"; }
.fa-angles-left::before { content: "\f100"; }
.fa-angles-right::before { content: "\f101"; }
.fa-angles-up::before { content: "\f102"; }
.fa-angles-up-down::before { content: "\e60d"; }
.fa-angry::before { content: "\f556"; }
.fa-ankh::before { content: "\f644"; }
.fa-ant::before { content: "\e680"; }
.fa-apartment::before { content: "\e468"; }
.fa-aperture::before { content: "\e2df"; }
.fa-apostrophe::before { content: "\27"; }
.fa-apple-alt::before { content: "\f5d1"; }
.fa-apple-core::before { content: "\e08f"; }
.fa-apple-crate::before { content: "\f6b1"; }
.fa-apple-whole::before { content: "\f5d1"; }
.fa-archive::before { content: "\f187"; }
.fa-archway::before { content: "\f557"; }
.fa-area-chart::before { content: "\f1fe"; }
.fa-arrow-alt-circle-down::before { content: "\f358"; }
.fa-arrow-alt-circle-left::before { content: "\f359"; }
.fa-arrow-alt-circle-right::before { content: "\f35a"; }
.fa-arrow-alt-circle-up::before { content: "\f35b"; }
.fa-arrow-alt-down::before { content: "\f354"; }
.fa-arrow-alt-from-bottom::before { content: "\f346"; }
.fa-arrow-alt-from-left::before { content: "\f347"; }
.fa-arrow-alt-from-right::before { content: "\f348"; }
.fa-arrow-alt-from-top::before { content: "\f349"; }
.fa-arrow-alt-left::before { content: "\f355"; }
.fa-arrow-alt-right::before { content: "\f356"; }
.fa-arrow-alt-square-down::before { content: "\f350"; }
.fa-arrow-alt-square-left::before { content: "\f351"; }
.fa-arrow-alt-square-right::before { content: "\f352"; }
.fa-arrow-alt-square-up::before { content: "\f353"; }
.fa-arrow-alt-to-bottom::before { content: "\f34a"; }
.fa-arrow-alt-to-left::before { content: "\f34b"; }
.fa-arrow-alt-to-right::before { content: "\f34c"; }
.fa-arrow-alt-to-top::before { content: "\f34d"; }
.fa-arrow-alt-up::before { content: "\f357"; }
.fa-arrow-circle-down::before { content: "\f0ab"; }
.fa-arrow-circle-left::before { content: "\f0a8"; }
.fa-arrow-circle-right::before { content: "\f0a9"; }
.fa-arrow-circle-up::before { content: "\f0aa"; }
.fa-arrow-down::before { content: "\f063"; }
.fa-arrow-down-1-9::before { content: "\f162"; }
.fa-arrow-down-9-1::before { content: "\f886"; }
.fa-arrow-down-a-z::before { content: "\f15d"; }
.fa-arrow-down-arrow-up::before { content: "\f883"; }
.fa-arrow-down-big-small::before { content: "\f88c"; }
.fa-arrow-down-from-arc::before { content: "\e614"; }
.fa-arrow-down-from-bracket::before { content: "\e667"; }
.fa-arrow-down-from-dotted-line::before { content: "\e090"; }
.fa-arrow-down-from-line::before { content: "\f345"; }
.fa-arrow-down-left::before { content: "\e091"; }
.fa-arrow-down-left-and-arrow-up-right-to-center::before { content: "\e092"; }
.fa-arrow-down-long::before { content: "\f175"; }
.fa-arrow-down-right::before { content: "\e093"; }
.fa-arrow-down-short-wide::before { content: "\f884"; }
.fa-arrow-down-small-big::before { content: "\f88d"; }
.fa-arrow-down-square-triangle::before { content: "\f889"; }
.fa-arrow-down-to-arc::before { content: "\e4ae"; }
.fa-arrow-down-to-bracket::before { content: "\e094"; }
.fa-arrow-down-to-dotted-line::before { content: "\e095"; }
.fa-arrow-down-to-line::before { content: "\f33d"; }
.fa-arrow-down-to-square::before { content: "\e096"; }
.fa-arrow-down-triangle-square::before { content: "\f888"; }
.fa-arrow-down-up-across-line::before { content: "\e4af"; }
.fa-arrow-down-up-lock::before { content: "\e4b0"; }
.fa-arrow-down-wide-short::before { content: "\f160"; }
.fa-arrow-down-z-a::before { content: "\f881"; }
.fa-arrow-from-bottom::before { content: "\f342"; }
.fa-arrow-from-left::before { content: "\f343"; }
.fa-arrow-from-right::before { content: "\f344"; }
.fa-arrow-from-top::before { content: "\f345"; }
.fa-arrow-left::before { content: "\f060"; }
.fa-arrow-left-from-arc::before { content: "\e615"; }
.fa-arrow-left-from-bracket::before { content: "\e668"; }
.fa-arrow-left-from-line::before { content: "\f344"; }
.fa-arrow-left-long::before { content: "\f177"; }
.fa-arrow-left-long-to-line::before { content: "\e3d4"; }
.fa-arrow-left-rotate::before { content: "\f0e2"; }
.fa-arrow-left-to-arc::before { content: "\e616"; }
.fa-arrow-left-to-bracket::before { content: "\e669"; }
.fa-arrow-left-to-line::before { content: "\f33e"; }
.fa-arrow-pointer::before { content: "\f245"; }
.fa-arrow-progress::before { content: "\e5df"; }
.fa-arrow-right::before { content: "\f061"; }
.fa-arrow-right-arrow-left::before { content: "\f0ec"; }
.fa-arrow-right-from-arc::before { content: "\e4b1"; }
.fa-arrow-right-from-bracket::before { content: "\f08b"; }
.fa-arrow-right-from-file::before { content: "\f56e"; }
.fa-arrow-right-from-line::before { content: "\f343"; }
.fa-arrow-right-long::before { content: "\f178"; }
.fa-arrow-right-long-to-line::before { content: "\e3d5"; }
.fa-arrow-right-rotate::before { content: "\f01e"; }
.fa-arrow-right-to-arc::before { content: "\e4b2"; }
.fa-arrow-right-to-bracket::before { content: "\f090"; }
.fa-arrow-right-to-city::before { content: "\e4b3"; }
.fa-arrow-right-to-file::before { content: "\f56f"; }
.fa-arrow-right-to-line::before { content: "\f340"; }
.fa-arrow-rotate-back::before { content: "\f0e2"; }
.fa-arrow-rotate-backward::before { content: "\f0e2"; }
.fa-arrow-rotate-forward::before { content: "\f01e"; }
.fa-arrow-rotate-left::before { content: "\f0e2"; }
.fa-arrow-rotate-right::before { content: "\f01e"; }
.fa-arrow-square-down::before { content: "\f339"; }
.fa-arrow-square-left::before { content: "\f33a"; }
.fa-arrow-square-right::before { content: "\f33b"; }
.fa-arrow-square-up::before { content: "\f33c"; }
.fa-arrow-to-bottom::before { content: "\f33d"; }
.fa-arrow-to-left::before { content: "\f33e"; }
.fa-arrow-to-right::before { content: "\f340"; }
.fa-arrow-to-top::before { content: "\f341"; }
.fa-arrow-trend-down::before { content: "\e097"; }
.fa-arrow-trend-up::before { content: "\e098"; }
.fa-arrow-turn-down::before { content: "\f149"; }
.fa-arrow-turn-down-left::before { content: "\e2e1"; }
.fa-arrow-turn-down-right::before { content: "\e3d6"; }
.fa-arrow-turn-left::before { content: "\e632"; }
.fa-arrow-turn-left-down::before { content: "\e633"; }
.fa-arrow-turn-left-up::before { content: "\e634"; }
.fa-arrow-turn-right::before { content: "\e635"; }
.fa-arrow-turn-up::before { content: "\f148"; }
.fa-arrow-up::before { content: "\f062"; }
.fa-arrow-up-1-9::before { content: "\f163"; }
.fa-arrow-up-9-1::before { content: "\f887"; }
.fa-arrow-up-a-z::before { content: "\f15e"; }
.fa-arrow-up-arrow-down::before { content: "\e099"; }
.fa-arrow-up-big-small::before { content: "\f88e"; }
.fa-arrow-up-from-arc::before { content: "\e4b4"; }
.fa-arrow-up-from-bracket::before { content: "\e09a"; }
.fa-arrow-up-from-dotted-line::before { content: "\e09b"; }
.fa-arrow-up-from-ground-water::before { content: "\e4b5"; }
.fa-arrow-up-from-line::before { content: "\f342"; }
.fa-arrow-up-from-square::before { content: "\e09c"; }
.fa-arrow-up-from-water-pump::before { content: "\e4b6"; }
.fa-arrow-up-left::before { content: "\e09d"; }
.fa-arrow-up-left-from-circle::before { content: "\e09e"; }
.fa-arrow-up-long::before { content: "\f176"; }
.fa-arrow-up-right::before { content: "\e09f"; }
.fa-arrow-up-right-and-arrow-down-left-from-center::before { content: "\e0a0"; }
.fa-arrow-up-right-dots::before { content: "\e4b7"; }
.fa-arrow-up-right-from-square::before { content: "\f08e"; }
.fa-arrow-up-short-wide::before { content: "\f885"; }
.fa-arrow-up-small-big::before { content: "\f88f"; }
.fa-arrow-up-square-triangle::before { content: "\f88b"; }
.fa-arrow-up-to-arc::before { content: "\e617"; }
.fa-arrow-up-to-bracket::before { content: "\e66a"; }
.fa-arrow-up-to-dotted-line::before { content: "\e0a1"; }
.fa-arrow-up-to-line::before { content: "\f341"; }
.fa-arrow-up-triangle-square::before { content: "\f88a"; }
.fa-arrow-up-wide-short::before { content: "\f161"; }
.fa-arrow-up-z-a::before { content: "\f882"; }
.fa-arrows::before { content: "\f047"; }
.fa-arrows-alt::before { content: "\f0b2"; }
.fa-arrows-alt-h::before { content: "\f337"; }
.fa-arrows-alt-v::before { content: "\f338"; }
.fa-arrows-cross::before { content: "\e0a2"; }
.fa-arrows-down-to-line::before { content: "\e4b8"; }
.fa-arrows-down-to-people::before { content: "\e4b9"; }
.fa-arrows-from-dotted-line::before { content: "\e0a3"; }
.fa-arrows-from-line::before { content: "\e0a4"; }
.fa-arrows-h::before { content: "\f07e"; }
.fa-arrows-left-right::before { content: "\f07e"; }
.fa-arrows-left-right-to-line::before { content: "\e4ba"; }
.fa-arrows-maximize::before { content: "\f31d"; }
.fa-arrows-minimize::before { content: "\e0a5"; }
.fa-arrows-repeat::before { content: "\f364"; }
.fa-arrows-repeat-1::before { content: "\f366"; }
.fa-arrows-retweet::before { content: "\f361"; }
.fa-arrows-rotate::before { content: "\f021"; }
.fa-arrows-rotate-reverse::before { content: "\e630"; }
.fa-arrows-spin::before { content: "\e4bb"; }
.fa-arrows-split-up-and-left::before { content: "\e4bc"; }
.fa-arrows-to-circle::before { content: "\e4bd"; }
.fa-arrows-to-dot::before { content: "\e4be"; }
.fa-arrows-to-dotted-line::before { content: "\e0a6"; }
.fa-arrows-to-eye::before { content: "\e4bf"; }
.fa-arrows-to-line::before { content: "\e0a7"; }
.fa-arrows-turn-right::before { content: "\e4c0"; }
.fa-arrows-turn-to-dots::before { content: "\e4c1"; }
.fa-arrows-up-down::before { content: "\f07d"; }
.fa-arrows-up-down-left-right::before { content: "\f047"; }
.fa-arrows-up-to-line::before { content: "\e4c2"; }
.fa-arrows-v::before { content: "\f07d"; }
.fa-asl-interpreting::before { content: "\f2a3"; }
.fa-assistive-listening-systems::before { content: "\f2a2"; }
.fa-asterisk::before { content: "\2a"; }
.fa-at::before { content: "\40"; }
.fa-atlas::before { content: "\f558"; }
.fa-atom::before { content: "\f5d2"; }
.fa-atom-alt::before { content: "\f5d3"; }
.fa-atom-simple::before { content: "\f5d3"; }
.fa-audio-description::before { content: "\f29e"; }
.fa-audio-description-slash::before { content: "\e0a8"; }
.fa-austral-sign::before { content: "\e0a9"; }
.fa-automobile::before { content: "\f1b9"; }
.fa-avocado::before { content: "\e0aa"; }
.fa-award::before { content: "\f559"; }
.fa-award-simple::before { content: "\e0ab"; }
.fa-axe::before { content: "\f6b2"; }
.fa-axe-battle::before { content: "\f6b3"; }
.fa-b::before { content: "\42"; }
.fa-baby::before { content: "\f77c"; }
.fa-baby-carriage::before { content: "\f77d"; }
.fa-backpack::before { content: "\f5d4"; }
.fa-backspace::before { content: "\f55a"; }
.fa-backward::before { content: "\f04a"; }
.fa-backward-fast::before { content: "\f049"; }
.fa-backward-step::before { content: "\f048"; }
.fa-bacon::before { content: "\f7e5"; }
.fa-bacteria::before { content: "\e059"; }
.fa-bacterium::before { content: "\e05a"; }
.fa-badge::before { content: "\f335"; }
.fa-badge-check::before { content: "\f336"; }
.fa-badge-dollar::before { content: "\f645"; }
.fa-badge-percent::before { content: "\f646"; }
.fa-badge-sheriff::before { content: "\f8a2"; }
.fa-badger-honey::before { content: "\f6b4"; }
.fa-badminton::before { content: "\e33a"; }
.fa-bag-seedling::before { content: "\e5f2"; }
.fa-bag-shopping::before { content: "\f290"; }
.fa-bag-shopping-minus::before { content: "\e650"; }
.fa-bag-shopping-plus::before { content: "\e651"; }
.fa-bagel::before { content: "\e3d7"; }
.fa-bags-shopping::before { content: "\f847"; }
.fa-baguette::before { content: "\e3d8"; }
.fa-bahai::before { content: "\f666"; }
.fa-baht-sign::before { content: "\e0ac"; }
.fa-balance-scale::before { content: "\f24e"; }
.fa-balance-scale-left::before { content: "\f515"; }
.fa-balance-scale-right::before { content: "\f516"; }
.fa-ball-pile::before { content: "\f77e"; }
.fa-balloon::before { content: "\e2e3"; }
.fa-balloons::before { content: "\e2e4"; }
.fa-ballot::before { content: "\f732"; }
.fa-ballot-check::before { content: "\f733"; }
.fa-ban::before { content: "\f05e"; }
.fa-ban-bug::before { content: "\f7f9"; }
.fa-ban-parking::before { content: "\f616"; }
.fa-ban-smoking::before { content: "\f54d"; }
.fa-banana::before { content: "\e2e5"; }
.fa-band-aid::before { content: "\f462"; }
.fa-bandage::before { content: "\f462"; }
.fa-bangladeshi-taka-sign::before { content: "\e2e6"; }
.fa-banjo::before { content: "\f8a3"; }
.fa-bank::before { content: "\f19c"; }
.fa-bar-chart::before { content: "\f080"; }
.fa-barcode::before { content: "\f02a"; }
.fa-barcode-alt::before { content: "\f463"; }
.fa-barcode-read::before { content: "\f464"; }
.fa-barcode-scan::before { content: "\f465"; }
.fa-barn-silo::before { content: "\f864"; }
.fa-bars::before { content: "\f0c9"; }
.fa-bars-filter::before { content: "\e0ad"; }
.fa-bars-progress::before { content: "\f828"; }
.fa-bars-sort::before { content: "\e0ae"; }
.fa-bars-staggered::before { content: "\f550"; }
.fa-baseball::before { content: "\f433"; }
.fa-baseball-ball::before { content: "\f433"; }
.fa-baseball-bat-ball::before { content: "\f432"; }
.fa-basket-shopping::before { content: "\f291"; }
.fa-basket-shopping-minus::before { content: "\e652"; }
.fa-basket-shopping-plus::before { content: "\e653"; }
.fa-basket-shopping-simple::before { content: "\e0af"; }
.fa-basketball::before { content: "\f434"; }
.fa-basketball-ball::before { content: "\f434"; }
.fa-basketball-hoop::before { content: "\f435"; }
.fa-bat::before { content: "\f6b5"; }
.fa-bath::before { content: "\f2cd"; }
.fa-bathtub::before { content: "\f2cd"; }
.fa-battery::before { content: "\f240"; }
.fa-battery-0::before { content: "\f244"; }
.fa-battery-1::before { content: "\e0b1"; }
.fa-battery-2::before { content: "\f243"; }
.fa-battery-3::before { content: "\f242"; }
.fa-battery-4::before { content: "\f241"; }
.fa-battery-5::before { content: "\f240"; }
.fa-battery-bolt::before { content: "\f376"; }
.fa-battery-car::before { content: "\f5df"; }
.fa-battery-empty::before { content: "\f244"; }
.fa-battery-exclamation::before { content: "\e0b0"; }
.fa-battery-full::before { content: "\f240"; }
.fa-battery-half::before { content: "\f242"; }
.fa-battery-low::before { content: "\e0b1"; }
.fa-battery-quarter::before { content: "\f243"; }
.fa-battery-slash::before { content: "\f377"; }
.fa-battery-three-quarters::before { content: "\f241"; }
.fa-bed::before { content: "\f236"; }
.fa-bed-alt::before { content: "\f8f7"; }
.fa-bed-bunk::before { content: "\f8f8"; }
.fa-bed-empty::before { content: "\f8f9"; }
.fa-bed-front::before { content: "\f8f7"; }
.fa-bed-pulse::before { content: "\f487"; }
.fa-bee::before { content: "\e0b2"; }
.fa-beer::before { content: "\f0fc"; }
.fa-beer-foam::before { content: "\e0b3"; }
.fa-beer-mug::before { content: "\e0b3"; }
.fa-beer-mug-empty::before { content: "\f0fc"; }
.fa-bell::before { content: "\f0f3"; }
.fa-bell-concierge::before { content: "\f562"; }
.fa-bell-exclamation::before { content: "\f848"; }
.fa-bell-on::before { content: "\f8fa"; }
.fa-bell-plus::before { content: "\f849"; }
.fa-bell-ring::before { content: "\e62c"; }
.fa-bell-school::before { content: "\f5d5"; }
.fa-bell-school-slash::before { content: "\f5d6"; }
.fa-bell-slash::before { content: "\f1f6"; }
.fa-bells::before { content: "\f77f"; }
.fa-bench-tree::before { content: "\e2e7"; }
.fa-betamax::before { content: "\f8a4"; }
.fa-bezier-curve::before { content: "\f55b"; }
.fa-bible::before { content: "\f647"; }
.fa-bicycle::before { content: "\f206"; }
.fa-biking::before { content: "\f84a"; }
.fa-biking-mountain::before { content: "\f84b"; }
.fa-billboard::before { content: "\e5cd"; }
.fa-bin-bottles::before { content: "\e5f5"; }
.fa-bin-bottles-recycle::before { content: "\e5f6"; }
.fa-bin-recycle::before { content: "\e5f7"; }
.fa-binary::before { content: "\e33b"; }
.fa-binary-circle-check::before { content: "\e33c"; }
.fa-binary-lock::before { content: "\e33d"; }
.fa-binary-slash::before { content: "\e33e"; }
.fa-binoculars::before { content: "\f1e5"; }
.fa-biohazard::before { content: "\f780"; }
.fa-bird::before { content: "\e469"; }
.fa-birthday-cake::before { content: "\f1fd"; }
.fa-bitcoin-sign::before { content: "\e0b4"; }
.fa-blackboard::before { content: "\f51b"; }
.fa-blanket::before { content: "\f498"; }
.fa-blanket-fire::before { content: "\e3da"; }
.fa-blender::before { content: "\f517"; }
.fa-blender-phone::before { content: "\f6b6"; }
.fa-blind::before { content: "\f29d"; }
.fa-blinds::before { content: "\f8fb"; }
.fa-blinds-open::before { content: "\f8fc"; }
.fa-blinds-raised::before { content: "\f8fd"; }
.fa-block::before { content: "\e46a"; }
.fa-block-brick::before { content: "\e3db"; }
.fa-block-brick-fire::before { content: "\e3dc"; }
.fa-block-question::before { content: "\e3dd"; }
.fa-block-quote::before { content: "\e0b5"; }
.fa-blog::before { content: "\f781"; }
.fa-blueberries::before { content: "\e2e8"; }
.fa-bluetooth::before { content: "\f293"; }
.fa-bold::before { content: "\f032"; }
.fa-bolt::before { content: "\f0e7"; }
.fa-bolt-auto::before { content: "\e0b6"; }
.fa-bolt-lightning::before { content: "\e0b7"; }
.fa-bolt-slash::before { content: "\e0b8"; }
.fa-bomb::before { content: "\f1e2"; }
.fa-bone::before { content: "\f5d7"; }
.fa-bone-break::before { content: "\f5d8"; }
.fa-bong::before { content: "\f55c"; }
.fa-book::before { content: "\f02d"; }
.fa-book-alt::before { content: "\f5d9"; }
.fa-book-arrow-right::before { content: "\e0b9"; }
.fa-book-arrow-up::before { content: "\e0ba"; }
.fa-book-atlas::before { content: "\f558"; }
.fa-book-bible::before { content: "\f647"; }
.fa-book-blank::before { content: "\f5d9"; }
.fa-book-bookmark::before { content: "\e0bb"; }
.fa-book-circle::before { content: "\e0ff"; }
.fa-book-circle-arrow-right::before { content: "\e0bc"; }
.fa-book-circle-arrow-up::before { content: "\e0bd"; }
.fa-book-copy::before { content: "\e0be"; }
.fa-book-dead::before { content: "\f6b7"; }
.fa-book-font::before { content: "\e0bf"; }
.fa-book-heart::before { content: "\f499"; }
.fa-book-journal-whills::before { content: "\f66a"; }
.fa-book-law::before { content: "\e0c1"; }
.fa-book-medical::before { content: "\f7e6"; }
.fa-book-open::before { content: "\f518"; }
.fa-book-open-alt::before { content: "\e0c0"; }
.fa-book-open-cover::before { content: "\e0c0"; }
.fa-book-open-reader::before { content: "\f5da"; }
.fa-book-quran::before { content: "\f687"; }
.fa-book-reader::before { content: "\f5da"; }
.fa-book-section::before { content: "\e0c1"; }
.fa-book-skull::before { content: "\f6b7"; }
.fa-book-sparkles::before { content: "\f6b8"; }
.fa-book-spells::before { content: "\f6b8"; }
.fa-book-tanakh::before { content: "\f827"; }
.fa-book-user::before { content: "\f7e7"; }
.fa-bookmark::before { content: "\f02e"; }
.fa-bookmark-circle::before { content: "\e100"; }
.fa-bookmark-slash::before { content: "\e0c2"; }
.fa-books::before { content: "\f5db"; }
.fa-books-medical::before { content: "\f7e8"; }
.fa-boombox::before { content: "\f8a5"; }
.fa-boot::before { content: "\f782"; }
.fa-boot-heeled::before { content: "\e33f"; }
.fa-booth-curtain::before { content: "\f734"; }
.fa-border-all::before { content: "\f84c"; }
.fa-border-bottom::before { content: "\f84d"; }
.fa-border-bottom-right::before { content: "\f854"; }
.fa-border-center-h::before { content: "\f89c"; }
.fa-border-center-v::before { content: "\f89d"; }
.fa-border-inner::before { content: "\f84e"; }
.fa-border-left::before { content: "\f84f"; }
.fa-border-none::before { content: "\f850"; }
.fa-border-outer::before { content: "\f851"; }
.fa-border-right::before { content: "\f852"; }
.fa-border-style::before { content: "\f853"; }
.fa-border-style-alt::before { content: "\f854"; }
.fa-border-top::before { content: "\f855"; }
.fa-border-top-left::before { content: "\f853"; }
.fa-bore-hole::before { content: "\e4c3"; }
.fa-bottle-baby::before { content: "\e673"; }
.fa-bottle-droplet::before { content: "\e4c4"; }
.fa-bottle-water::before { content: "\e4c5"; }
.fa-bow-arrow::before { content: "\f6b9"; }
.fa-bowl-chopsticks::before { content: "\e2e9"; }
.fa-bowl-chopsticks-noodles::before { content: "\e2ea"; }
.fa-bowl-food::before { content: "\e4c6"; }
.fa-bowl-hot::before { content: "\f823"; }
.fa-bowl-rice::before { content: "\e2eb"; }
.fa-bowl-salad::before { content: "\f81e"; }
.fa-bowl-scoop::before { content: "\e3de"; }
.fa-bowl-scoops::before { content: "\e3df"; }
.fa-bowl-shaved-ice::before { content: "\e3de"; }
.fa-bowl-soft-serve::before { content: "\e46b"; }
.fa-bowl-spoon::before { content: "\e3e0"; }
.fa-bowling-ball::before { content: "\f436"; }
.fa-bowling-ball-pin::before { content: "\e0c3"; }
.fa-bowling-pins::before { content: "\f437"; }
.fa-box::before { content: "\f466"; }
.fa-box-alt::before { content: "\f49a"; }
.fa-box-archive::before { content: "\f187"; }
.fa-box-ballot::before { content: "\f735"; }
.fa-box-check::before { content: "\f467"; }
.fa-box-circle-check::before { content: "\e0c4"; }
.fa-box-dollar::before { content: "\f4a0"; }
.fa-box-fragile::before { content: "\f49b"; }
.fa-box-full::before { content: "\f49c"; }
.fa-box-heart::before { content: "\f49d"; }
.fa-box-open::before { content: "\f49e"; }
.fa-box-open-full::before { content: "\f49c"; }
.fa-box-taped::before { content: "\f49a"; }
.fa-box-tissue::before { content: "\e05b"; }
.fa-box-up::before { content: "\f49f"; }
.fa-box-usd::before { content: "\f4a0"; }
.fa-boxes::before { content: "\f468"; }
.fa-boxes-alt::before { content: "\f468"; }
.fa-boxes-packing::before { content: "\e4c7"; }
.fa-boxes-stacked::before { content: "\f468"; }
.fa-boxing-glove::before { content: "\f438"; }
.fa-bracket::before { content: "\5b"; }
.fa-bracket-curly::before { content: "\7b"; }
.fa-bracket-curly-left::before { content: "\7b"; }
.fa-bracket-curly-right::before { content: "\7d"; }
.fa-bracket-left::before { content: "\5b"; }
.fa-bracket-round::before { content: "\28"; }
.fa-bracket-round-right::before { content: "\29"; }
.fa-bracket-square::before { content: "\5b"; }
.fa-bracket-square-right::before { content: "\5d"; }
.fa-brackets::before { content: "\f7e9"; }
.fa-brackets-curly::before { content: "\f7ea"; }
.fa-brackets-round::before { content: "\e0c5"; }
.fa-brackets-square::before { content: "\f7e9"; }
.fa-braille::before { content: "\f2a1"; }
.fa-brain::before { content: "\f5dc"; }
.fa-brain-arrow-curved-right::before { content: "\f677"; }
.fa-brain-circuit::before { content: "\e0c6"; }
.fa-brake-warning::before { content: "\e0c7"; }
.fa-brazilian-real-sign::before { content: "\e46c"; }
.fa-bread-loaf::before { content: "\f7eb"; }
.fa-bread-slice::before { content: "\f7ec"; }
.fa-bread-slice-butter::before { content: "\e3e1"; }
.fa-bridge::before { content: "\e4c8"; }
.fa-bridge-circle-check::before { content: "\e4c9"; }
.fa-bridge-circle-exclamation::before { content: "\e4ca"; }
.fa-bridge-circle-xmark::before { content: "\e4cb"; }
.fa-bridge-lock::before { content: "\e4cc"; }
.fa-bridge-suspension::before { content: "\e4cd"; }
.fa-bridge-water::before { content: "\e4ce"; }
.fa-briefcase::before { content: "\f0b1"; }
.fa-briefcase-arrow-right::before { content: "\e2f2"; }
.fa-briefcase-blank::before { content: "\e0c8"; }
.fa-briefcase-clock::before { content: "\f64a"; }
.fa-briefcase-medical::before { content: "\f469"; }
.fa-brightness::before { content: "\e0c9"; }
.fa-brightness-low::before { content: "\e0ca"; }
.fa-bring-forward::before { content: "\f856"; }
.fa-bring-front::before { content: "\f857"; }
.fa-broadcast-tower::before { content: "\f519"; }
.fa-broccoli::before { content: "\e3e2"; }
.fa-broom::before { content: "\f51a"; }
.fa-broom-ball::before { content: "\f458"; }
.fa-broom-wide::before { content: "\e5d1"; }
.fa-browser::before { content: "\f37e"; }
.fa-browsers::before { content: "\e0cb"; }
.fa-brush::before { content: "\f55d"; }
.fa-bucket::before { content: "\e4cf"; }
.fa-bug::before { content: "\f188"; }
.fa-bug-slash::before { content: "\e490"; }
.fa-bugs::before { content: "\e4d0"; }
.fa-building::before { content: "\f1ad"; }
.fa-building-circle-arrow-right::before { content: "\e4d1"; }
.fa-building-circle-check::before { content: "\e4d2"; }
.fa-building-circle-exclamation::before { content: "\e4d3"; }
.fa-building-circle-xmark::before { content: "\e4d4"; }
.fa-building-columns::before { content: "\f19c"; }
.fa-building-flag::before { content: "\e4d5"; }
.fa-building-lock::before { content: "\e4d6"; }
.fa-building-magnifying-glass::before { content: "\e61c"; }
.fa-building-memo::before { content: "\e61e"; }
.fa-building-ngo::before { content: "\e4d7"; }
.fa-building-shield::before { content: "\e4d8"; }
.fa-building-un::before { content: "\e4d9"; }
.fa-building-user::before { content: "\e4da"; }
.fa-building-wheat::before { content: "\e4db"; }
.fa-buildings::before { content: "\e0cc"; }
.fa-bulldozer::before { content: "\e655"; }
.fa-bullhorn::before { content: "\f0a1"; }
.fa-bullseye::before { content: "\f140"; }
.fa-bullseye-arrow::before { content: "\f648"; }
.fa-bullseye-pointer::before { content: "\f649"; }
.fa-buoy::before { content: "\e5b5"; }
.fa-buoy-mooring::before { content: "\e5b6"; }
.fa-burger::before { content: "\f805"; }
.fa-burger-cheese::before { content: "\f7f1"; }
.fa-burger-fries::before { content: "\e0cd"; }
.fa-burger-glass::before { content: "\e0ce"; }
.fa-burger-lettuce::before { content: "\e3e3"; }
.fa-burger-soda::before { content: "\f858"; }
.fa-burn::before { content: "\f46a"; }
.fa-burrito::before { content: "\f7ed"; }
.fa-burst::before { content: "\e4dc"; }
.fa-bus::before { content: "\f207"; }
.fa-bus-alt::before { content: "\f55e"; }
.fa-bus-school::before { content: "\f5dd"; }
.fa-bus-simple::before { content: "\f55e"; }
.fa-business-front::before { content: "\e45c"; }
.fa-business-time::before { content: "\f64a"; }
.fa-butter::before { content: "\e3e4"; }
.fa-c::before { content: "\43"; }
.fa-cab::before { content: "\f1ba"; }
.fa-cabin::before { content: "\e46d"; }
.fa-cabinet-filing::before { content: "\f64b"; }
.fa-cable-car::before { content: "\f7da"; }
.fa-cactus::before { content: "\f8a7"; }
.fa-caduceus::before { content: "\e681"; }
.fa-cake::before { content: "\f1fd"; }
.fa-cake-candles::before { content: "\f1fd"; }
.fa-cake-slice::before { content: "\e3e5"; }
.fa-calculator::before { content: "\f1ec"; }
.fa-calculator-alt::before { content: "\f64c"; }
.fa-calculator-simple::before { content: "\f64c"; }
.fa-calendar::before { content: "\f133"; }
.fa-calendar-alt::before { content: "\f073"; }
.fa-calendar-arrow-down::before { content: "\e0d0"; }
.fa-calendar-arrow-up::before { content: "\e0d1"; }
.fa-calendar-check::before { content: "\f274"; }
.fa-calendar-circle::before { content: "\e102"; }
.fa-calendar-circle-exclamation::before { content: "\e46e"; }
.fa-calendar-circle-minus::before { content: "\e46f"; }
.fa-calendar-circle-plus::before { content: "\e470"; }
.fa-calendar-circle-user::before { content: "\e471"; }
.fa-calendar-clock::before { content: "\e0d2"; }
.fa-calendar-day::before { content: "\f783"; }
.fa-calendar-days::before { content: "\f073"; }
.fa-calendar-download::before { content: "\e0d0"; }
.fa-calendar-edit::before { content: "\f333"; }
.fa-calendar-exclamation::before { content: "\f334"; }
.fa-calendar-heart::before { content: "\e0d3"; }
.fa-calendar-image::before { content: "\e0d4"; }
.fa-calendar-lines::before { content: "\e0d5"; }
.fa-calendar-lines-pen::before { content: "\e472"; }
.fa-calendar-minus::before { content: "\f272"; }
.fa-calendar-note::before { content: "\e0d5"; }
.fa-calendar-pen::before { content: "\f333"; }
.fa-calendar-plus::before { content: "\f271"; }
.fa-calendar-range::before { content: "\e0d6"; }
.fa-calendar-star::before { content: "\f736"; }
.fa-calendar-time::before { content: "\e0d2"; }
.fa-calendar-times::before { content: "\f273"; }
.fa-calendar-upload::before { content: "\e0d1"; }
.fa-calendar-users::before { content: "\e5e2"; }
.fa-calendar-week::before { content: "\f784"; }
.fa-calendar-xmark::before { content: "\f273"; }
.fa-calendars::before { content: "\e0d7"; }
.fa-camcorder::before { content: "\f8a8"; }
.fa-camera::before { content: "\f030"; }
.fa-camera-alt::before { content: "\f030"; }
.fa-camera-cctv::before { content: "\f8ac"; }
.fa-camera-circle::before { content: "\e103"; }
.fa-camera-home::before { content: "\f8fe"; }
.fa-camera-movie::before { content: "\f8a9"; }
.fa-camera-polaroid::before { content: "\f8aa"; }
.fa-camera-retro::before { content: "\f083"; }
.fa-camera-rotate::before { content: "\e0d8"; }
.fa-camera-security::before { content: "\f8fe"; }
.fa-camera-slash::before { content: "\e0d9"; }
.fa-camera-viewfinder::before { content: "\e0da"; }
.fa-camera-web::before { content: "\f832"; }
.fa-camera-web-slash::before { content: "\f833"; }
.fa-campfire::before { content: "\f6ba"; }
.fa-campground::before { content: "\f6bb"; }
.fa-can-food::before { content: "\e3e6"; }
.fa-cancel::before { content: "\f05e"; }
.fa-candle-holder::before { content: "\f6bc"; }
.fa-candy::before { content: "\e3e7"; }
.fa-candy-bar::before { content: "\e3e8"; }
.fa-candy-cane::before { content: "\f786"; }
.fa-candy-corn::before { content: "\f6bd"; }
.fa-cannabis::before { content: "\f55f"; }
.fa-cannon::before { content: "\e642"; }
.fa-capsules::before { content: "\f46b"; }
.fa-car::before { content: "\f1b9"; }
.fa-car-alt::before { content: "\f5de"; }
.fa-car-battery::before { content: "\f5df"; }
.fa-car-bolt::before { content: "\e341"; }
.fa-car-building::before { content: "\f859"; }
.fa-car-bump::before { content: "\f5e0"; }
.fa-car-burst::before { content: "\f5e1"; }
.fa-car-bus::before { content: "\f85a"; }
.fa-car-circle-bolt::before { content: "\e342"; }
.fa-car-crash::before { content: "\f5e1"; }
.fa-car-garage::before { content: "\f5e2"; }
.fa-car-mechanic::before { content: "\f5e3"; }
.fa-car-mirrors::before { content: "\e343"; }
.fa-car-on::before { content: "\e4dd"; }
.fa-car-rear::before { content: "\f5de"; }
.fa-car-side::before { content: "\f5e4"; }
.fa-car-side-bolt::before { content: "\e344"; }
.fa-car-tilt::before { content: "\f5e5"; }
.fa-car-tunnel::before { content: "\e4de"; }
.fa-car-wash::before { content: "\f5e6"; }
.fa-car-wrench::before { content: "\f5e3"; }
.fa-caravan::before { content: "\f8ff"; }
.fa-caravan-alt::before { content: "\e000"; }
.fa-caravan-simple::before { content: "\e000"; }
.fa-card-club::before { content: "\e3e9"; }
.fa-card-diamond::before { content: "\e3ea"; }
.fa-card-heart::before { content: "\e3eb"; }
.fa-card-spade::before { content: "\e3ec"; }
.fa-cards::before { content: "\e3ed"; }
.fa-cards-blank::before { content: "\e4df"; }
.fa-caret-circle-down::before { content: "\f32d"; }
.fa-caret-circle-left::before { content: "\f32e"; }
.fa-caret-circle-right::before { content: "\f330"; }
.fa-caret-circle-up::before { content: "\f331"; }
.fa-caret-down::before { content: "\f0d7"; }
.fa-caret-left::before { content: "\f0d9"; }
.fa-caret-right::before { content: "\f0da"; }
.fa-caret-square-down::before { content: "\f150"; }
.fa-caret-square-left::before { content: "\f191"; }
.fa-caret-square-right::before { content: "\f152"; }
.fa-caret-square-up::before { content: "\f151"; }
.fa-caret-up::before { content: "\f0d8"; }
.fa-carriage-baby::before { content: "\f77d"; }
.fa-carrot::before { content: "\f787"; }
.fa-cars::before { content: "\f85b"; }
.fa-cart-arrow-down::before { content: "\f218"; }
.fa-cart-arrow-up::before { content: "\e3ee"; }
.fa-cart-circle-arrow-down::before { content: "\e3ef"; }
.fa-cart-circle-arrow-up::before { content: "\e3f0"; }
.fa-cart-circle-check::before { content: "\e3f1"; }
.fa-cart-circle-exclamation::before { content: "\e3f2"; }
.fa-cart-circle-plus::before { content: "\e3f3"; }
.fa-cart-circle-xmark::before { content: "\e3f4"; }
.fa-cart-flatbed::before { content: "\f474"; }
.fa-cart-flatbed-boxes::before { content: "\f475"; }
.fa-cart-flatbed-empty::before { content: "\f476"; }
.fa-cart-flatbed-suitcase::before { content: "\f59d"; }
.fa-cart-minus::before { content: "\e0db"; }
.fa-cart-plus::before { content: "\f217"; }
.fa-cart-shopping::before { content: "\f07a"; }
.fa-cart-shopping-fast::before { content: "\e0dc"; }
.fa-cart-xmark::before { content: "\e0dd"; }
.fa-cash-register::before { content: "\f788"; }
.fa-cassette-betamax::before { content: "\f8a4"; }
.fa-cassette-tape::before { content: "\f8ab"; }
.fa-cassette-vhs::before { content: "\f8ec"; }
.fa-castle::before { content: "\e0de"; }
.fa-cat::before { content: "\f6be"; }
.fa-cat-space::before { content: "\e001"; }
.fa-cauldron::before { content: "\f6bf"; }
.fa-cctv::before { content: "\f8ac"; }
.fa-cedi-sign::before { content: "\e0df"; }
.fa-cent-sign::before { content: "\e3f5"; }
.fa-certificate::before { content: "\f0a3"; }
.fa-chain::before { content: "\f0c1"; }
.fa-chain-broken::before { content: "\f127"; }
.fa-chain-horizontal::before { content: "\e1cb"; }
.fa-chain-horizontal-slash::before { content: "\e1cc"; }
.fa-chain-slash::before { content: "\f127"; }
.fa-chair::before { content: "\f6c0"; }
.fa-chair-office::before { content: "\f6c1"; }
.fa-chalkboard::before { content: "\f51b"; }
.fa-chalkboard-teacher::before { content: "\f51c"; }
.fa-chalkboard-user::before { content: "\f51c"; }
.fa-champagne-glass::before { content: "\f79e"; }
.fa-champagne-glasses::before { content: "\f79f"; }
.fa-charging-station::before { content: "\f5e7"; }
.fa-chart-area::before { content: "\f1fe"; }
.fa-chart-bar::before { content: "\f080"; }
.fa-chart-bullet::before { content: "\e0e1"; }
.fa-chart-candlestick::before { content: "\e0e2"; }
.fa-chart-column::before { content: "\e0e3"; }
.fa-chart-gantt::before { content: "\e0e4"; }
.fa-chart-kanban::before { content: "\e64f"; }
.fa-chart-line::before { content: "\f201"; }
.fa-chart-line-down::before { content: "\f64d"; }
.fa-chart-line-up::before { content: "\e0e5"; }
.fa-chart-line-up-down::before { content: "\e5d7"; }
.fa-chart-mixed::before { content: "\f643"; }
.fa-chart-mixed-up-circle-currency::before { content: "\e5d8"; }
.fa-chart-mixed-up-circle-dollar::before { content: "\e5d9"; }
.fa-chart-network::before { content: "\f78a"; }
.fa-chart-pie::before { content: "\f200"; }
.fa-chart-pie-alt::before { content: "\f64e"; }
.fa-chart-pie-simple::before { content: "\f64e"; }
.fa-chart-pie-simple-circle-currency::before { content: "\e604"; }
.fa-chart-pie-simple-circle-dollar::before { content: "\e605"; }
.fa-chart-pyramid::before { content: "\e0e6"; }
.fa-chart-radar::before { content: "\e0e7"; }
.fa-chart-scatter::before { content: "\f7ee"; }
.fa-chart-scatter-3d::before { content: "\e0e8"; }
.fa-chart-scatter-bubble::before { content: "\e0e9"; }
.fa-chart-simple::before { content: "\e473"; }
.fa-chart-simple-horizontal::before { content: "\e474"; }
.fa-chart-tree-map::before { content: "\e0ea"; }
.fa-chart-user::before { content: "\f6a3"; }
.fa-chart-waterfall::before { content: "\e0eb"; }
.fa-check::before { content: "\f00c"; }
.fa-check-circle::before { content: "\f058"; }
.fa-check-double::before { content: "\f560"; }
.fa-check-square::before { content: "\f14a"; }
.fa-check-to-slot::before { content: "\f772"; }
.fa-cheese::before { content: "\f7ef"; }
.fa-cheese-swiss::before { content: "\f7f0"; }
.fa-cheeseburger::before { content: "\f7f1"; }
.fa-cherries::before { content: "\e0ec"; }
.fa-chess::before { content: "\f439"; }
.fa-chess-bishop::before { content: "\f43a"; }
.fa-chess-bishop-alt::before { content: "\f43b"; }
.fa-chess-bishop-piece::before { content: "\f43b"; }
.fa-chess-board::before { content: "\f43c"; }
.fa-chess-clock::before { content: "\f43d"; }
.fa-chess-clock-alt::before { content: "\f43e"; }
.fa-chess-clock-flip::before { content: "\f43e"; }
.fa-chess-king::before { content: "\f43f"; }
.fa-chess-king-alt::before { content: "\f440"; }
.fa-chess-king-piece::before { content: "\f440"; }
.fa-chess-knight::before { content: "\f441"; }
.fa-chess-knight-alt::before { content: "\f442"; }
.fa-chess-knight-piece::before { content: "\f442"; }
.fa-chess-pawn::before { content: "\f443"; }
.fa-chess-pawn-alt::before { content: "\f444"; }
.fa-chess-pawn-piece::before { content: "\f444"; }
.fa-chess-queen::before { content: "\f445"; }
.fa-chess-queen-alt::before { content: "\f446"; }
.fa-chess-queen-piece::before { content: "\f446"; }
.fa-chess-rook::before { content: "\f447"; }
.fa-chess-rook-alt::before { content: "\f448"; }
.fa-chess-rook-piece::before { content: "\f448"; }
.fa-chestnut::before { content: "\e3f6"; }
.fa-chevron-circle-down::before { content: "\f13a"; }
.fa-chevron-circle-left::before { content: "\f137"; }
.fa-chevron-circle-right::before { content: "\f138"; }
.fa-chevron-circle-up::before { content: "\f139"; }
.fa-chevron-double-down::before { content: "\f322"; }
.fa-chevron-double-left::before { content: "\f323"; }
.fa-chevron-double-right::before { content: "\f324"; }
.fa-chevron-double-up::before { content: "\f325"; }
.fa-chevron-down::before { content: "\f078"; }
.fa-chevron-left::before { content: "\f053"; }
.fa-chevron-right::before { content: "\f054"; }
.fa-chevron-square-down::before { content: "\f329"; }
.fa-chevron-square-left::before { content: "\f32a"; }
.fa-chevron-square-right::before { content: "\f32b"; }
.fa-chevron-square-up::before { content: "\f32c"; }
.fa-chevron-up::before { content: "\f077"; }
.fa-chevrons-down::before { content: "\f322"; }
.fa-chevrons-left::before { content: "\f323"; }
.fa-chevrons-right::before { content: "\f324"; }
.fa-chevrons-up::before { content: "\f325"; }
.fa-chf-sign::before { content: "\e602"; }
.fa-child::before { content: "\f1ae"; }
.fa-child-combatant::before { content: "\e4e0"; }
.fa-child-dress::before { content: "\e59c"; }
.fa-child-reaching::before { content: "\e59d"; }
.fa-child-rifle::before { content: "\e4e0"; }
.fa-children::before { content: "\e4e1"; }
.fa-chimney::before { content: "\f78b"; }
.fa-chocolate-bar::before { content: "\e3e8"; }
.fa-chopsticks::before { content: "\e3f7"; }
.fa-church::before { content: "\f51d"; }
.fa-circle::before { content: "\f111"; }
.fa-circle-0::before { content: "\e0ed"; }
.fa-circle-1::before { content: "\e0ee"; }
.fa-circle-2::before { content: "\e0ef"; }
.fa-circle-3::before { content: "\e0f0"; }
.fa-circle-4::before { content: "\e0f1"; }
.fa-circle-5::before { content: "\e0f2"; }
.fa-circle-6::before { content: "\e0f3"; }
.fa-circle-7::before { content: "\e0f4"; }
.fa-circle-8::before { content: "\e0f5"; }
.fa-circle-9::before { content: "\e0f6"; }
.fa-circle-a::before { content: "\e0f7"; }
.fa-circle-ampersand::before { content: "\e0f8"; }
.fa-circle-arrow-down::before { content: "\f0ab"; }
.fa-circle-arrow-down-left::before { content: "\e0f9"; }
.fa-circle-arrow-down-right::before { content: "\e0fa"; }
.fa-circle-arrow-left::before { content: "\f0a8"; }
.fa-circle-arrow-right::before { content: "\f0a9"; }
.fa-circle-arrow-up::before { content: "\f0aa"; }
.fa-circle-arrow-up-left::before { content: "\e0fb"; }
.fa-circle-arrow-up-right::before { content: "\e0fc"; }
.fa-circle-b::before { content: "\e0fd"; }
.fa-circle-bolt::before { content: "\e0fe"; }
.fa-circle-book-open::before { content: "\e0ff"; }
.fa-circle-bookmark::before { content: "\e100"; }
.fa-circle-c::before { content: "\e101"; }
.fa-circle-calendar::before { content: "\e102"; }
.fa-circle-camera::before { content: "\e103"; }
.fa-circle-caret-down::before { content: "\f32d"; }
.fa-circle-caret-left::before { content: "\f32e"; }
.fa-circle-caret-right::before { content: "\f330"; }
.fa-circle-caret-up::before { content: "\f331"; }
.fa-circle-check::before { content: "\f058"; }
.fa-circle-chevron-down::before { content: "\f13a"; }
.fa-circle-chevron-left::before { content: "\f137"; }
.fa-circle-chevron-right::before { content: "\f138"; }
.fa-circle-chevron-up::before { content: "\f139"; }
.fa-circle-d::before { content: "\e104"; }
.fa-circle-dashed::before { content: "\e105"; }
.fa-circle-divide::before { content: "\e106"; }
.fa-circle-dollar::before { content: "\f2e8"; }
.fa-circle-dollar-to-slot::before { content: "\f4b9"; }
.fa-circle-dot::before { content: "\f192"; }
.fa-circle-down::before { content: "\f358"; }
.fa-circle-down-left::before { content: "\e107"; }
.fa-circle-down-right::before { content: "\e108"; }
.fa-circle-e::before { content: "\e109"; }
.fa-circle-ellipsis::before { content: "\e10a"; }
.fa-circle-ellipsis-vertical::before { content: "\e10b"; }
.fa-circle-envelope::before { content: "\e10c"; }
.fa-circle-euro::before { content: "\e5ce"; }
.fa-circle-exclamation::before { content: "\f06a"; }
.fa-circle-exclamation-check::before { content: "\e10d"; }
.fa-circle-f::before { content: "\e10e"; }
.fa-circle-g::before { content: "\e10f"; }
.fa-circle-gf::before { content: "\e67f"; }
.fa-circle-h::before { content: "\f47e"; }
.fa-circle-half::before { content: "\e110"; }
.fa-circle-half-stroke::before { content: "\f042"; }
.fa-circle-heart::before { content: "\f4c7"; }
.fa-circle-i::before { content: "\e111"; }
.fa-circle-info::before { content: "\f05a"; }
.fa-circle-j::before { content: "\e112"; }
.fa-circle-k::before { content: "\e113"; }
.fa-circle-l::before { content: "\e114"; }
.fa-circle-left::before { content: "\f359"; }
.fa-circle-location-arrow::before { content: "\f602"; }
.fa-circle-m::before { content: "\e115"; }
.fa-circle-microphone::before { content: "\e116"; }
.fa-circle-microphone-lines::before { content: "\e117"; }
.fa-circle-minus::before { content: "\f056"; }
.fa-circle-n::before { content: "\e118"; }
.fa-circle-nodes::before { content: "\e4e2"; }
.fa-circle-notch::before { content: "\f1ce"; }
.fa-circle-o::before { content: "\e119"; }
.fa-circle-p::before { content: "\e11a"; }
.fa-circle-parking::before { content: "\f615"; }
.fa-circle-pause::before { content: "\f28b"; }
.fa-circle-phone::before { content: "\e11b"; }
.fa-circle-phone-flip::before { content: "\e11c"; }
.fa-circle-phone-hangup::before { content: "\e11d"; }
.fa-circle-play::before { content: "\f144"; }
.fa-circle-plus::before { content: "\f055"; }
.fa-circle-q::before { content: "\e11e"; }
.fa-circle-quarter::before { content: "\e11f"; }
.fa-circle-quarter-stroke::before { content: "\e5d3"; }
.fa-circle-quarters::before { content: "\e3f8"; }
.fa-circle-question::before { content: "\f059"; }
.fa-circle-r::before { content: "\e120"; }
.fa-circle-radiation::before { content: "\f7ba"; }
.fa-circle-right::before { content: "\f35a"; }
.fa-circle-s::before { content: "\e121"; }
.fa-circle-small::before { content: "\e122"; }
.fa-circle-sort::before { content: "\e030"; }
.fa-circle-sort-down::before { content: "\e031"; }
.fa-circle-sort-up::before { content: "\e032"; }
.fa-circle-star::before { content: "\e123"; }
.fa-circle-sterling::before { content: "\e5cf"; }
.fa-circle-stop::before { content: "\f28d"; }
.fa-circle-t::before { content: "\e124"; }
.fa-circle-three-quarters::before { content: "\e125"; }
.fa-circle-three-quarters-stroke::before { content: "\e5d4"; }
.fa-circle-trash::before { content: "\e126"; }
.fa-circle-u::before { content: "\e127"; }
.fa-circle-up::before { content: "\f35b"; }
.fa-circle-up-left::before { content: "\e128"; }
.fa-circle-up-right::before { content: "\e129"; }
.fa-circle-user::before { content: "\f2bd"; }
.fa-circle-v::before { content: "\e12a"; }
.fa-circle-video::before { content: "\e12b"; }
.fa-circle-w::before { content: "\e12c"; }
.fa-circle-waveform-lines::before { content: "\e12d"; }
.fa-circle-wifi::before { content: "\e67d"; }
.fa-circle-wifi-circle-wifi::before { content: "\e67e"; }
.fa-circle-wifi-group::before { content: "\e67e"; }
.fa-circle-x::before { content: "\e12e"; }
.fa-circle-xmark::before { content: "\f057"; }
.fa-circle-y::before { content: "\e12f"; }
.fa-circle-yen::before { content: "\e5d0"; }
.fa-circle-z::before { content: "\e130"; }
.fa-circles-overlap::before { content: "\e600"; }
.fa-citrus::before { content: "\e2f4"; }
.fa-citrus-slice::before { content: "\e2f5"; }
.fa-city::before { content: "\f64f"; }
.fa-clapperboard::before { content: "\e131"; }
.fa-clapperboard-play::before { content: "\e132"; }
.fa-clarinet::before { content: "\f8ad"; }
.fa-claw-marks::before { content: "\f6c2"; }
.fa-clinic-medical::before { content: "\f7f2"; }
.fa-clipboard::before { content: "\f328"; }
.fa-clipboard-check::before { content: "\f46c"; }
.fa-clipboard-list::before { content: "\f46d"; }
.fa-clipboard-list-check::before { content: "\f737"; }
.fa-clipboard-medical::before { content: "\e133"; }
.fa-clipboard-prescription::before { content: "\f5e8"; }
.fa-clipboard-question::before { content: "\e4e3"; }
.fa-clipboard-user::before { content: "\f7f3"; }
.fa-clock::before { content: "\f017"; }
.fa-clock-desk::before { content: "\e134"; }
.fa-clock-eight::before { content: "\e345"; }
.fa-clock-eight-thirty::before { content: "\e346"; }
.fa-clock-eleven::before { content: "\e347"; }
.fa-clock-eleven-thirty::before { content: "\e348"; }
.fa-clock-five::before { content: "\e349"; }
.fa-clock-five-thirty::before { content: "\e34a"; }
.fa-clock-four::before { content: "\f017"; }
.fa-clock-four-thirty::before { content: "\e34b"; }
.fa-clock-nine::before { content: "\e34c"; }
.fa-clock-nine-thirty::before { content: "\e34d"; }
.fa-clock-one::before { content: "\e34e"; }
.fa-clock-one-thirty::before { content: "\e34f"; }
.fa-clock-rotate-left::before { content: "\f1da"; }
.fa-clock-seven::before { content: "\e350"; }
.fa-clock-seven-thirty::before { content: "\e351"; }
.fa-clock-six::before { content: "\e352"; }
.fa-clock-six-thirty::before { content: "\e353"; }
.fa-clock-ten::before { content: "\e354"; }
.fa-clock-ten-thirty::before { content: "\e355"; }
.fa-clock-three::before { content: "\e356"; }
.fa-clock-three-thirty::before { content: "\e357"; }
.fa-clock-twelve::before { content: "\e358"; }
.fa-clock-twelve-thirty::before { content: "\e359"; }
.fa-clock-two::before { content: "\e35a"; }
.fa-clock-two-thirty::before { content: "\e35b"; }
.fa-clone::before { content: "\f24d"; }
.fa-close::before { content: "\f00d"; }
.fa-closed-captioning::before { content: "\f20a"; }
.fa-closed-captioning-slash::before { content: "\e135"; }
.fa-clothes-hanger::before { content: "\e136"; }
.fa-cloud::before { content: "\f0c2"; }
.fa-cloud-arrow-down::before { content: "\f0ed"; }
.fa-cloud-arrow-up::before { content: "\f0ee"; }
.fa-cloud-binary::before { content: "\e601"; }
.fa-cloud-bolt::before { content: "\f76c"; }
.fa-cloud-bolt-moon::before { content: "\f76d"; }
.fa-cloud-bolt-sun::before { content: "\f76e"; }
.fa-cloud-check::before { content: "\e35c"; }
.fa-cloud-download::before { content: "\f0ed"; }
.fa-cloud-download-alt::before { content: "\f0ed"; }
.fa-cloud-drizzle::before { content: "\f738"; }
.fa-cloud-exclamation::before { content: "\e491"; }
.fa-cloud-fog::before { content: "\f74e"; }
.fa-cloud-hail::before { content: "\f739"; }
.fa-cloud-hail-mixed::before { content: "\f73a"; }
.fa-cloud-meatball::before { content: "\f73b"; }
.fa-cloud-minus::before { content: "\e35d"; }
.fa-cloud-moon::before { content: "\f6c3"; }
.fa-cloud-moon-rain::before { content: "\f73c"; }
.fa-cloud-music::before { content: "\f8ae"; }
.fa-cloud-plus::before { content: "\e35e"; }
.fa-cloud-question::before { content: "\e492"; }
.fa-cloud-rain::before { content: "\f73d"; }
.fa-cloud-rainbow::before { content: "\f73e"; }
.fa-cloud-showers::before { content: "\f73f"; }
.fa-cloud-showers-heavy::before { content: "\f740"; }
.fa-cloud-showers-water::before { content: "\e4e4"; }
.fa-cloud-slash::before { content: "\e137"; }
.fa-cloud-sleet::before { content: "\f741"; }
.fa-cloud-snow::before { content: "\f742"; }
.fa-cloud-sun::before { content: "\f6c4"; }
.fa-cloud-sun-rain::before { content: "\f743"; }
.fa-cloud-upload::before { content: "\f0ee"; }
.fa-cloud-upload-alt::before { content: "\f0ee"; }
.fa-cloud-word::before { content: "\e138"; }
.fa-cloud-xmark::before { content: "\e35f"; }
.fa-clouds::before { content: "\f744"; }
.fa-clouds-moon::before { content: "\f745"; }
.fa-clouds-sun::before { content: "\f746"; }
.fa-clover::before { content: "\e139"; }
.fa-club::before { content: "\f327"; }
.fa-cny::before { content: "\f157"; }
.fa-cocktail::before { content: "\f561"; }
.fa-coconut::before { content: "\e2f6"; }
.fa-code::before { content: "\f121"; }
.fa-code-branch::before { content: "\f126"; }
.fa-code-commit::before { content: "\f386"; }
.fa-code-compare::before { content: "\e13a"; }
.fa-code-fork::before { content: "\e13b"; }
.fa-code-merge::before { content: "\f387"; }
.fa-code-pull-request::before { content: "\e13c"; }
.fa-code-pull-request-closed::before { content: "\e3f9"; }
.fa-code-pull-request-draft::before { content: "\e3fa"; }
.fa-code-simple::before { content: "\e13d"; }
.fa-coffee::before { content: "\f0f4"; }
.fa-coffee-bean::before { content: "\e13e"; }
.fa-coffee-beans::before { content: "\e13f"; }
.fa-coffee-pot::before { content: "\e002"; }
.fa-coffee-togo::before { content: "\f6c5"; }
.fa-coffin::before { content: "\f6c6"; }
.fa-coffin-cross::before { content: "\e051"; }
.fa-cog::before { content: "\f013"; }
.fa-cogs::before { content: "\f085"; }
.fa-coin::before { content: "\f85c"; }
.fa-coin-blank::before { content: "\e3fb"; }
.fa-coin-front::before { content: "\e3fc"; }
.fa-coin-vertical::before { content: "\e3fd"; }
.fa-coins::before { content: "\f51e"; }
.fa-colon::before { content: "\3a"; }
.fa-colon-sign::before { content: "\e140"; }
.fa-columns::before { content: "\f0db"; }
.fa-columns-3::before { content: "\e361"; }
.fa-comet::before { content: "\e003"; }
.fa-comma::before { content: "\2c"; }
.fa-command::before { content: "\e142"; }
.fa-comment::before { content: "\f075"; }
.fa-comment-alt::before { content: "\f27a"; }
.fa-comment-alt-arrow-down::before { content: "\e1db"; }
.fa-comment-alt-arrow-up::before { content: "\e1dc"; }
.fa-comment-alt-captions::before { content: "\e1de"; }
.fa-comment-alt-check::before { content: "\f4a2"; }
.fa-comment-alt-dollar::before { content: "\f650"; }
.fa-comment-alt-dots::before { content: "\f4a3"; }
.fa-comment-alt-edit::before { content: "\f4a4"; }
.fa-comment-alt-exclamation::before { content: "\f4a5"; }
.fa-comment-alt-image::before { content: "\e1e0"; }
.fa-comment-alt-lines::before { content: "\f4a6"; }
.fa-comment-alt-medical::before { content: "\f7f4"; }
.fa-comment-alt-minus::before { content: "\f4a7"; }
.fa-comment-alt-music::before { content: "\f8af"; }
.fa-comment-alt-plus::before { content: "\f4a8"; }
.fa-comment-alt-quote::before { content: "\e1e4"; }
.fa-comment-alt-slash::before { content: "\f4a9"; }
.fa-comment-alt-smile::before { content: "\f4aa"; }
.fa-comment-alt-text::before { content: "\e1e6"; }
.fa-comment-alt-times::before { content: "\f4ab"; }
.fa-comment-arrow-down::before { content: "\e143"; }
.fa-comment-arrow-up::before { content: "\e144"; }
.fa-comment-arrow-up-right::before { content: "\e145"; }
.fa-comment-captions::before { content: "\e146"; }
.fa-comment-check::before { content: "\f4ac"; }
.fa-comment-code::before { content: "\e147"; }
.fa-comment-dollar::before { content: "\f651"; }
.fa-comment-dots::before { content: "\f4ad"; }
.fa-comment-edit::before { content: "\f4ae"; }
.fa-comment-exclamation::before { content: "\f4af"; }
.fa-comment-heart::before { content: "\e5c8"; }
.fa-comment-image::before { content: "\e148"; }
.fa-comment-lines::before { content: "\f4b0"; }
.fa-comment-medical::before { content: "\f7f5"; }
.fa-comment-middle::before { content: "\e149"; }
.fa-comment-middle-alt::before { content: "\e1e1"; }
.fa-comment-middle-top::before { content: "\e14a"; }
.fa-comment-middle-top-alt::before { content: "\e1e2"; }
.fa-comment-minus::before { content: "\f4b1"; }
.fa-comment-music::before { content: "\f8b0"; }
.fa-comment-pen::before { content: "\f4ae"; }
.fa-comment-plus::before { content: "\f4b2"; }
.fa-comment-question::before { content: "\e14b"; }
.fa-comment-quote::before { content: "\e14c"; }
.fa-comment-slash::before { content: "\f4b3"; }
.fa-comment-smile::before { content: "\f4b4"; }
.fa-comment-sms::before { content: "\f7cd"; }
.fa-comment-text::before { content: "\e14d"; }
.fa-comment-times::before { content: "\f4b5"; }
.fa-comment-xmark::before { content: "\f4b5"; }
.fa-commenting::before { content: "\f4ad"; }
.fa-comments::before { content: "\f086"; }
.fa-comments-alt::before { content: "\f4b6"; }
.fa-comments-alt-dollar::before { content: "\f652"; }
.fa-comments-dollar::before { content: "\f653"; }
.fa-comments-question::before { content: "\e14e"; }
.fa-comments-question-check::before { content: "\e14f"; }
.fa-compact-disc::before { content: "\f51f"; }
.fa-compass::before { content: "\f14e"; }
.fa-compass-drafting::before { content: "\f568"; }
.fa-compass-slash::before { content: "\f5e9"; }
.fa-compress::before { content: "\f066"; }
.fa-compress-alt::before { content: "\f422"; }
.fa-compress-arrows::before { content: "\e0a5"; }
.fa-compress-arrows-alt::before { content: "\f78c"; }
.fa-compress-wide::before { content: "\f326"; }
.fa-computer::before { content: "\e4e5"; }
.fa-computer-classic::before { content: "\f8b1"; }
.fa-computer-mouse::before { content: "\f8cc"; }
.fa-computer-mouse-scrollwheel::before { content: "\f8cd"; }
.fa-computer-speaker::before { content: "\f8b2"; }
.fa-concierge-bell::before { content: "\f562"; }
.fa-construction::before { content: "\f85d"; }
.fa-contact-book::before { content: "\f2b9"; }
.fa-contact-card::before { content: "\f2bb"; }
.fa-container-storage::before { content: "\f4b7"; }
.fa-conveyor-belt::before { content: "\f46e"; }
.fa-conveyor-belt-alt::before { content: "\f46f"; }
.fa-conveyor-belt-arm::before { content: "\e5f8"; }
.fa-conveyor-belt-boxes::before { content: "\f46f"; }
.fa-conveyor-belt-empty::before { content: "\e150"; }
.fa-cookie::before { content: "\f563"; }
.fa-cookie-bite::before { content: "\f564"; }
.fa-copy::before { content: "\f0c5"; }
.fa-copyright::before { content: "\f1f9"; }
.fa-corn::before { content: "\f6c7"; }
.fa-corner::before { content: "\e3fe"; }
.fa-couch::before { content: "\f4b8"; }
.fa-couch-small::before { content: "\f4cc"; }
.fa-court-sport::before { content: "\e643"; }
.fa-cow::before { content: "\f6c8"; }
.fa-cowbell::before { content: "\f8b3"; }
.fa-cowbell-circle-plus::before { content: "\f8b4"; }
.fa-cowbell-more::before { content: "\f8b4"; }
.fa-crab::before { content: "\e3ff"; }
.fa-crate-apple::before { content: "\f6b1"; }
.fa-crate-empty::before { content: "\e151"; }
.fa-credit-card::before { content: "\f09d"; }
.fa-credit-card-alt::before { content: "\f09d"; }
.fa-credit-card-blank::before { content: "\f389"; }
.fa-credit-card-front::before { content: "\f38a"; }
.fa-creemee::before { content: "\e400"; }
.fa-cricket::before { content: "\f449"; }
.fa-cricket-bat-ball::before { content: "\f449"; }
.fa-croissant::before { content: "\f7f6"; }
.fa-crop::before { content: "\f125"; }
.fa-crop-alt::before { content: "\f565"; }
.fa-crop-simple::before { content: "\f565"; }
.fa-cross::before { content: "\f654"; }
.fa-crosshairs::before { content: "\f05b"; }
.fa-crosshairs-simple::before { content: "\e59f"; }
.fa-crow::before { content: "\f520"; }
.fa-crown::before { content: "\f521"; }
.fa-crutch::before { content: "\f7f7"; }
.fa-crutches::before { content: "\f7f8"; }
.fa-cruzeiro-sign::before { content: "\e152"; }
.fa-crystal-ball::before { content: "\e362"; }
.fa-cube::before { content: "\f1b2"; }
.fa-cubes::before { content: "\f1b3"; }
.fa-cubes-stacked::before { content: "\e4e6"; }
.fa-cucumber::before { content: "\e401"; }
.fa-cup-straw::before { content: "\e363"; }
.fa-cup-straw-swoosh::before { content: "\e364"; }
.fa-cup-togo::before { content: "\f6c5"; }
.fa-cupcake::before { content: "\e402"; }
.fa-curling::before { content: "\f44a"; }
.fa-curling-stone::before { content: "\f44a"; }
.fa-custard::before { content: "\e403"; }
.fa-cut::before { content: "\f0c4"; }
.fa-cutlery::before { content: "\f2e7"; }
.fa-d::before { content: "\44"; }
.fa-dagger::before { content: "\f6cb"; }
.fa-dash::before { content: "\e404"; }
.fa-dashboard::before { content: "\f624"; }
.fa-database::before { content: "\f1c0"; }
.fa-deaf::before { content: "\f2a4"; }
.fa-deafness::before { content: "\f2a4"; }
.fa-debug::before { content: "\f7f9"; }
.fa-dedent::before { content: "\f03b"; }
.fa-deer::before { content: "\f78e"; }
.fa-deer-rudolph::before { content: "\f78f"; }
.fa-delete-left::before { content: "\f55a"; }
.fa-delete-right::before { content: "\e154"; }
.fa-democrat::before { content: "\f747"; }
.fa-desktop::before { content: "\f390"; }
.fa-desktop-alt::before { content: "\f390"; }
.fa-desktop-arrow-down::before { content: "\e155"; }
.fa-desktop-code::before { content: "\e165"; }
.fa-desktop-medical::before { content: "\e166"; }
.fa-desktop-slash::before { content: "\e2fa"; }
.fa-dewpoint::before { content: "\f748"; }
.fa-dharmachakra::before { content: "\f655"; }
.fa-diagnoses::before { content: "\f470"; }
.fa-diagram-cells::before { content: "\e475"; }
.fa-diagram-lean-canvas::before { content: "\e156"; }
.fa-diagram-nested::before { content: "\e157"; }
.fa-diagram-next::before { content: "\e476"; }
.fa-diagram-predecessor::before { content: "\e477"; }
.fa-diagram-previous::before { content: "\e478"; }
.fa-diagram-project::before { content: "\f542"; }
.fa-diagram-sankey::before { content: "\e158"; }
.fa-diagram-subtask::before { content: "\e479"; }
.fa-diagram-successor::before { content: "\e47a"; }
.fa-diagram-venn::before { content: "\e15a"; }
.fa-dial::before { content: "\e15b"; }
.fa-dial-high::before { content: "\e15c"; }
.fa-dial-low::before { content: "\e15d"; }
.fa-dial-max::before { content: "\e15e"; }
.fa-dial-med::before { content: "\e15f"; }
.fa-dial-med-high::before { content: "\e15b"; }
.fa-dial-med-low::before { content: "\e160"; }
.fa-dial-min::before { content: "\e161"; }
.fa-dial-off::before { content: "\e162"; }
.fa-diamond::before { content: "\f219"; }
.fa-diamond-exclamation::before { content: "\e405"; }
.fa-diamond-half::before { content: "\e5b7"; }
.fa-diamond-half-stroke::before { content: "\e5b8"; }
.fa-diamond-turn-right::before { content: "\f5eb"; }
.fa-diamonds-4::before { content: "\e68b"; }
.fa-dice::before { content: "\f522"; }
.fa-dice-d10::before { content: "\f6cd"; }
.fa-dice-d12::before { content: "\f6ce"; }
.fa-dice-d20::before { content: "\f6cf"; }
.fa-dice-d4::before { content: "\f6d0"; }
.fa-dice-d6::before { content: "\f6d1"; }
.fa-dice-d8::before { content: "\f6d2"; }
.fa-dice-five::before { content: "\f523"; }
.fa-dice-four::before { content: "\f524"; }
.fa-dice-one::before { content: "\f525"; }
.fa-dice-six::before { content: "\f526"; }
.fa-dice-three::before { content: "\f527"; }
.fa-dice-two::before { content: "\f528"; }
.fa-digging::before { content: "\f85e"; }
.fa-digital-tachograph::before { content: "\f566"; }
.fa-dinosaur::before { content: "\e5fe"; }
.fa-diploma::before { content: "\f5ea"; }
.fa-directions::before { content: "\f5eb"; }
.fa-disc-drive::before { content: "\f8b5"; }
.fa-disease::before { content: "\f7fa"; }
.fa-display::before { content: "\e163"; }
.fa-display-arrow-down::before { content: "\e164"; }
.fa-display-chart-up::before { content: "\e5e3"; }
.fa-display-chart-up-circle-currency::before { content: "\e5e5"; }
.fa-display-chart-up-circle-dollar::before { content: "\e5e6"; }
.fa-display-code::before { content: "\e165"; }
.fa-display-medical::before { content: "\e166"; }
.fa-display-slash::before { content: "\e2fa"; }
.fa-distribute-spacing-horizontal::before { content: "\e365"; }
.fa-distribute-spacing-vertical::before { content: "\e366"; }
.fa-ditto::before { content: "\22"; }
.fa-divide::before { content: "\f529"; }
.fa-dizzy::before { content: "\f567"; }
.fa-dna::before { content: "\f471"; }
.fa-do-not-enter::before { content: "\f5ec"; }
.fa-dog::before { content: "\f6d3"; }
.fa-dog-leashed::before { content: "\f6d4"; }
.fa-dollar::before { content: "\24"; }
.fa-dollar-circle::before { content: "\f2e8"; }
.fa-dollar-sign::before { content: "\24"; }
.fa-dollar-square::before { content: "\f2e9"; }
.fa-dolly::before { content: "\f472"; }
.fa-dolly-box::before { content: "\f472"; }
.fa-dolly-empty::before { content: "\f473"; }
.fa-dolly-flatbed::before { content: "\f474"; }
.fa-dolly-flatbed-alt::before { content: "\f475"; }
.fa-dolly-flatbed-empty::before { content: "\f476"; }
.fa-dolphin::before { content: "\e168"; }
.fa-donate::before { content: "\f4b9"; }
.fa-dong-sign::before { content: "\e169"; }
.fa-donut::before { content: "\e406"; }
.fa-door-closed::before { content: "\f52a"; }
.fa-door-open::before { content: "\f52b"; }
.fa-dot-circle::before { content: "\f192"; }
.fa-doughnut::before { content: "\e406"; }
.fa-dove::before { content: "\f4ba"; }
.fa-down::before { content: "\f354"; }
.fa-down-from-bracket::before { content: "\e66b"; }
.fa-down-from-dotted-line::before { content: "\e407"; }
.fa-down-from-line::before { content: "\f349"; }
.fa-down-left::before { content: "\e16a"; }
.fa-down-left-and-up-right-to-center::before { content: "\f422"; }
.fa-down-long::before { content: "\f309"; }
.fa-down-right::before { content: "\e16b"; }
.fa-down-to-bracket::before { content: "\e4e7"; }
.fa-down-to-dotted-line::before { content: "\e408"; }
.fa-down-to-line::before { content: "\f34a"; }
.fa-download::before { content: "\f019"; }
.fa-drafting-compass::before { content: "\f568"; }
.fa-dragon::before { content: "\f6d5"; }
.fa-draw-circle::before { content: "\f5ed"; }
.fa-draw-polygon::before { content: "\f5ee"; }
.fa-draw-square::before { content: "\f5ef"; }
.fa-dreidel::before { content: "\f792"; }
.fa-drivers-license::before { content: "\f2c2"; }
.fa-drone::before { content: "\f85f"; }
.fa-drone-alt::before { content: "\f860"; }
.fa-drone-front::before { content: "\f860"; }
.fa-droplet::before { content: "\f043"; }
.fa-droplet-degree::before { content: "\f748"; }
.fa-droplet-percent::before { content: "\f750"; }
.fa-droplet-slash::before { content: "\f5c7"; }
.fa-drum::before { content: "\f569"; }
.fa-drum-steelpan::before { content: "\f56a"; }
.fa-drumstick::before { content: "\f6d6"; }
.fa-drumstick-bite::before { content: "\f6d7"; }
.fa-dryer::before { content: "\f861"; }
.fa-dryer-alt::before { content: "\f862"; }
.fa-dryer-heat::before { content: "\f862"; }
.fa-duck::before { content: "\f6d8"; }
.fa-dumbbell::before { content: "\f44b"; }
.fa-dumpster::before { content: "\f793"; }
.fa-dumpster-fire::before { content: "\f794"; }
.fa-dungeon::before { content: "\f6d9"; }
.fa-e::before { content: "\45"; }
.fa-ear::before { content: "\f5f0"; }
.fa-ear-deaf::before { content: "\f2a4"; }
.fa-ear-listen::before { content: "\f2a2"; }
.fa-ear-muffs::before { content: "\f795"; }
.fa-earth::before { content: "\f57d"; }
.fa-earth-africa::before { content: "\f57c"; }
.fa-earth-america::before { content: "\f57d"; }
.fa-earth-americas::before { content: "\f57d"; }
.fa-earth-asia::before { content: "\f57e"; }
.fa-earth-europe::before { content: "\f7a2"; }
.fa-earth-oceania::before { content: "\e47b"; }
.fa-eclipse::before { content: "\f749"; }
.fa-eclipse-alt::before { content: "\f74a"; }
.fa-edit::before { content: "\f044"; }
.fa-egg::before { content: "\f7fb"; }
.fa-egg-fried::before { content: "\f7fc"; }
.fa-eggplant::before { content: "\e16c"; }
.fa-eject::before { content: "\f052"; }
.fa-elephant::before { content: "\f6da"; }
.fa-elevator::before { content: "\e16d"; }
.fa-ellipsis::before { content: "\f141"; }
.fa-ellipsis-h::before { content: "\f141"; }
.fa-ellipsis-h-alt::before { content: "\f39b"; }
.fa-ellipsis-stroke::before { content: "\f39b"; }
.fa-ellipsis-stroke-vertical::before { content: "\f39c"; }
.fa-ellipsis-v::before { content: "\f142"; }
.fa-ellipsis-v-alt::before { content: "\f39c"; }
.fa-ellipsis-vertical::before { content: "\f142"; }
.fa-empty-set::before { content: "\f656"; }
.fa-engine::before { content: "\e16e"; }
.fa-engine-exclamation::before { content: "\f5f2"; }
.fa-engine-warning::before { content: "\f5f2"; }
.fa-envelope::before { content: "\f0e0"; }
.fa-envelope-badge::before { content: "\e16f"; }
.fa-envelope-circle::before { content: "\e10c"; }
.fa-envelope-circle-check::before { content: "\e4e8"; }
.fa-envelope-dot::before { content: "\e16f"; }
.fa-envelope-open::before { content: "\f2b6"; }
.fa-envelope-open-dollar::before { content: "\f657"; }
.fa-envelope-open-text::before { content: "\f658"; }
.fa-envelope-square::before { content: "\f199"; }
.fa-envelopes::before { content: "\e170"; }
.fa-envelopes-bulk::before { content: "\f674"; }
.fa-equals::before { content: "\3d"; }
.fa-eraser::before { content: "\f12d"; }
.fa-escalator::before { content: "\e171"; }
.fa-ethernet::before { content: "\f796"; }
.fa-eur::before { content: "\f153"; }
.fa-euro::before { content: "\f153"; }
.fa-euro-sign::before { content: "\f153"; }
.fa-excavator::before { content: "\e656"; }
.fa-exchange::before { content: "\f0ec"; }
.fa-exchange-alt::before { content: "\f362"; }
.fa-exclamation::before { content: "\21"; }
.fa-exclamation-circle::before { content: "\f06a"; }
.fa-exclamation-square::before { content: "\f321"; }
.fa-exclamation-triangle::before { content: "\f071"; }
.fa-expand::before { content: "\f065"; }
.fa-expand-alt::before { content: "\f424"; }
.fa-expand-arrows::before { content: "\f31d"; }
.fa-expand-arrows-alt::before { content: "\f31e"; }
.fa-expand-wide::before { content: "\f320"; }
.fa-exploding-head::before { content: "\e2fe"; }
.fa-explosion::before { content: "\e4e9"; }
.fa-external-link::before { content: "\f08e"; }
.fa-external-link-alt::before { content: "\f35d"; }
.fa-external-link-square::before { content: "\f14c"; }
.fa-external-link-square-alt::before { content: "\f360"; }
.fa-eye::before { content: "\f06e"; }
.fa-eye-dropper::before { content: "\f1fb"; }
.fa-eye-dropper-empty::before { content: "\f1fb"; }
.fa-eye-dropper-full::before { content: "\e172"; }
.fa-eye-dropper-half::before { content: "\e173"; }
.fa-eye-evil::before { content: "\f6db"; }
.fa-eye-low-vision::before { content: "\f2a8"; }
.fa-eye-slash::before { content: "\f070"; }
.fa-eyedropper::before { content: "\f1fb"; }
.fa-eyes::before { content: "\e367"; }
.fa-f::before { content: "\46"; }
.fa-face-angry::before { content: "\f556"; }
.fa-face-angry-horns::before { content: "\e368"; }
.fa-face-anguished::before { content: "\e369"; }
.fa-face-anxious-sweat::before { content: "\e36a"; }
.fa-face-astonished::before { content: "\e36b"; }
.fa-face-awesome::before { content: "\e409"; }
.fa-face-beam-hand-over-mouth::before { content: "\e47c"; }
.fa-face-clouds::before { content: "\e47d"; }
.fa-face-confounded::before { content: "\e36c"; }
.fa-face-confused::before { content: "\e36d"; }
.fa-face-cowboy-hat::before { content: "\e36e"; }
.fa-face-diagonal-mouth::before { content: "\e47e"; }
.fa-face-disappointed::before { content: "\e36f"; }
.fa-face-disguise::before { content: "\e370"; }
.fa-face-dizzy::before { content: "\f567"; }
.fa-face-dotted::before { content: "\e47f"; }
.fa-face-downcast-sweat::before { content: "\e371"; }
.fa-face-drooling::before { content: "\e372"; }
.fa-face-exhaling::before { content: "\e480"; }
.fa-face-explode::before { content: "\e2fe"; }
.fa-face-expressionless::before { content: "\e373"; }
.fa-face-eyes-xmarks::before { content: "\e374"; }
.fa-face-fearful::before { content: "\e375"; }
.fa-face-flushed::before { content: "\f579"; }
.fa-face-frown::before { content: "\f119"; }
.fa-face-frown-open::before { content: "\f57a"; }
.fa-face-frown-slight::before { content: "\e376"; }
.fa-face-glasses::before { content: "\e377"; }
.fa-face-grimace::before { content: "\f57f"; }
.fa-face-grin::before { content: "\f580"; }
.fa-face-grin-beam::before { content: "\f582"; }
.fa-face-grin-beam-sweat::before { content: "\f583"; }
.fa-face-grin-hearts::before { content: "\f584"; }
.fa-face-grin-squint::before { content: "\f585"; }
.fa-face-grin-squint-tears::before { content: "\f586"; }
.fa-face-grin-stars::before { content: "\f587"; }
.fa-face-grin-tears::before { content: "\f588"; }
.fa-face-grin-tongue::before { content: "\f589"; }
.fa-face-grin-tongue-squint::before { content: "\f58a"; }
.fa-face-grin-tongue-wink::before { content: "\f58b"; }
.fa-face-grin-wide::before { content: "\f581"; }
.fa-face-grin-wink::before { content: "\f58c"; }
.fa-face-hand-over-mouth::before { content: "\e378"; }
.fa-face-hand-peeking::before { content: "\e481"; }
.fa-face-hand-yawn::before { content: "\e379"; }
.fa-face-head-bandage::before { content: "\e37a"; }
.fa-face-holding-back-tears::before { content: "\e482"; }
.fa-face-hushed::before { content: "\e37b"; }
.fa-face-icicles::before { content: "\e37c"; }
.fa-face-kiss::before { content: "\f596"; }
.fa-face-kiss-beam::before { content: "\f597"; }
.fa-face-kiss-closed-eyes::before { content: "\e37d"; }
.fa-face-kiss-wink-heart::before { content: "\f598"; }
.fa-face-laugh::before { content: "\f599"; }
.fa-face-laugh-beam::before { content: "\f59a"; }
.fa-face-laugh-squint::before { content: "\f59b"; }
.fa-face-laugh-wink::before { content: "\f59c"; }
.fa-face-lying::before { content: "\e37e"; }
.fa-face-mask::before { content: "\e37f"; }
.fa-face-meh::before { content: "\f11a"; }
.fa-face-meh-blank::before { content: "\f5a4"; }
.fa-face-melting::before { content: "\e483"; }
.fa-face-monocle::before { content: "\e380"; }
.fa-face-nauseated::before { content: "\e381"; }
.fa-face-nose-steam::before { content: "\e382"; }
.fa-face-party::before { content: "\e383"; }
.fa-face-pensive::before { content: "\e384"; }
.fa-face-persevering::before { content: "\e385"; }
.fa-face-pleading::before { content: "\e386"; }
.fa-face-pouting::before { content: "\e387"; }
.fa-face-raised-eyebrow::before { content: "\e388"; }
.fa-face-relieved::before { content: "\e389"; }
.fa-face-rolling-eyes::before { content: "\f5a5"; }
.fa-face-sad-cry::before { content: "\f5b3"; }
.fa-face-sad-sweat::before { content: "\e38a"; }
.fa-face-sad-tear::before { content: "\f5b4"; }
.fa-face-saluting::before { content: "\e484"; }
.fa-face-scream::before { content: "\e38b"; }
.fa-face-shush::before { content: "\e38c"; }
.fa-face-sleeping::before { content: "\e38d"; }
.fa-face-sleepy::before { content: "\e38e"; }
.fa-face-smile::before { content: "\f118"; }
.fa-face-smile-beam::before { content: "\f5b8"; }
.fa-face-smile-halo::before { content: "\e38f"; }
.fa-face-smile-hearts::before { content: "\e390"; }
.fa-face-smile-horns::before { content: "\e391"; }
.fa-face-smile-plus::before { content: "\f5b9"; }
.fa-face-smile-relaxed::before { content: "\e392"; }
.fa-face-smile-tear::before { content: "\e393"; }
.fa-face-smile-tongue::before { content: "\e394"; }
.fa-face-smile-upside-down::before { content: "\e395"; }
.fa-face-smile-wink::before { content: "\f4da"; }
.fa-face-smiling-hands::before { content: "\e396"; }
.fa-face-smirking::before { content: "\e397"; }
.fa-face-spiral-eyes::before { content: "\e485"; }
.fa-face-sunglasses::before { content: "\e398"; }
.fa-face-surprise::before { content: "\f5c2"; }
.fa-face-swear::before { content: "\e399"; }
.fa-face-thermometer::before { content: "\e39a"; }
.fa-face-thinking::before { content: "\e39b"; }
.fa-face-tired::before { content: "\f5c8"; }
.fa-face-tissue::before { content: "\e39c"; }
.fa-face-tongue-money::before { content: "\e39d"; }
.fa-face-tongue-sweat::before { content: "\e39e"; }
.fa-face-unamused::before { content: "\e39f"; }
.fa-face-viewfinder::before { content: "\e2ff"; }
.fa-face-vomit::before { content: "\e3a0"; }
.fa-face-weary::before { content: "\e3a1"; }
.fa-face-woozy::before { content: "\e3a2"; }
.fa-face-worried::before { content: "\e3a3"; }
.fa-face-zany::before { content: "\e3a4"; }
.fa-face-zipper::before { content: "\e3a5"; }
.fa-falafel::before { content: "\e40a"; }
.fa-family::before { content: "\e300"; }
.fa-family-dress::before { content: "\e301"; }
.fa-family-pants::before { content: "\e302"; }
.fa-fan::before { content: "\f863"; }
.fa-fan-table::before { content: "\e004"; }
.fa-farm::before { content: "\f864"; }
.fa-fast-backward::before { content: "\f049"; }
.fa-fast-forward::before { content: "\f050"; }
.fa-faucet::before { content: "\e005"; }
.fa-faucet-drip::before { content: "\e006"; }
.fa-fax::before { content: "\f1ac"; }
.fa-feather::before { content: "\f52d"; }
.fa-feather-alt::before { content: "\f56b"; }
.fa-feather-pointed::before { content: "\f56b"; }
.fa-feed::before { content: "\f09e"; }
.fa-female::before { content: "\f182"; }
.fa-fence::before { content: "\e303"; }
.fa-ferris-wheel::before { content: "\e174"; }
.fa-ferry::before { content: "\e4ea"; }
.fa-field-hockey::before { content: "\f44c"; }
.fa-field-hockey-stick-ball::before { content: "\f44c"; }
.fa-fighter-jet::before { content: "\f0fb"; }
.fa-file::before { content: "\f15b"; }
.fa-file-alt::before { content: "\f15c"; }
.fa-file-archive::before { content: "\f1c6"; }
.fa-file-arrow-down::before { content: "\f56d"; }
.fa-file-arrow-up::before { content: "\f574"; }
.fa-file-audio::before { content: "\f1c7"; }
.fa-file-award::before { content: "\f5f3"; }
.fa-file-binary::before { content: "\e175"; }
.fa-file-cad::before { content: "\e672"; }
.fa-file-caret-down::before { content: "\e429"; }
.fa-file-caret-up::before { content: "\e42a"; }
.fa-file-certificate::before { content: "\f5f3"; }
.fa-file-chart-column::before { content: "\f659"; }
.fa-file-chart-line::before { content: "\f659"; }
.fa-file-chart-pie::before { content: "\f65a"; }
.fa-file-check::before { content: "\f316"; }
.fa-file-circle-check::before { content: "\e5a0"; }
.fa-file-circle-exclamation::before { content: "\e4eb"; }
.fa-file-circle-info::before { content: "\e493"; }
.fa-file-circle-minus::before { content: "\e4ed"; }
.fa-file-circle-plus::before { content: "\e494"; }
.fa-file-circle-question::before { content: "\e4ef"; }
.fa-file-circle-xmark::before { content: "\e5a1"; }
.fa-file-clipboard::before { content: "\f0ea"; }
.fa-file-code::before { content: "\f1c9"; }
.fa-file-contract::before { content: "\f56c"; }
.fa-file-csv::before { content: "\f6dd"; }
.fa-file-dashed-line::before { content: "\f877"; }
.fa-file-doc::before { content: "\e5ed"; }
.fa-file-download::before { content: "\f56d"; }
.fa-file-edit::before { content: "\f31c"; }
.fa-file-eps::before { content: "\e644"; }
.fa-file-excel::before { content: "\f1c3"; }
.fa-file-exclamation::before { content: "\f31a"; }
.fa-file-export::before { content: "\f56e"; }
.fa-file-gif::before { content: "\e645"; }
.fa-file-heart::before { content: "\e176"; }
.fa-file-image::before { content: "\f1c5"; }
.fa-file-import::before { content: "\f56f"; }
.fa-file-invoice::before { content: "\f570"; }
.fa-file-invoice-dollar::before { content: "\f571"; }
.fa-file-jpg::before { content: "\e646"; }
.fa-file-lines::before { content: "\f15c"; }
.fa-file-lock::before { content: "\e3a6"; }
.fa-file-magnifying-glass::before { content: "\f865"; }
.fa-file-medical::before { content: "\f477"; }
.fa-file-medical-alt::before { content: "\f478"; }
.fa-file-minus::before { content: "\f318"; }
.fa-file-mov::before { content: "\e647"; }
.fa-file-mp3::before { content: "\e648"; }
.fa-file-mp4::before { content: "\e649"; }
.fa-file-music::before { content: "\f8b6"; }
.fa-file-pdf::before { content: "\f1c1"; }
.fa-file-pen::before { content: "\f31c"; }
.fa-file-plus::before { content: "\f319"; }
.fa-file-plus-minus::before { content: "\e177"; }
.fa-file-png::before { content: "\e666"; }
.fa-file-powerpoint::before { content: "\f1c4"; }
.fa-file-ppt::before { content: "\e64a"; }
.fa-file-prescription::before { content: "\f572"; }
.fa-file-search::before { content: "\f865"; }
.fa-file-shield::before { content: "\e4f0"; }
.fa-file-signature::before { content: "\f573"; }
.fa-file-slash::before { content: "\e3a7"; }
.fa-file-spreadsheet::before { content: "\f65b"; }
.fa-file-svg::before { content: "\e64b"; }
.fa-file-text::before { content: "\f15c"; }
.fa-file-times::before { content: "\f317"; }
.fa-file-upload::before { content: "\f574"; }
.fa-file-user::before { content: "\f65c"; }
.fa-file-vector::before { content: "\e64c"; }
.fa-file-video::before { content: "\f1c8"; }
.fa-file-waveform::before { content: "\f478"; }
.fa-file-word::before { content: "\f1c2"; }
.fa-file-xls::before { content: "\e64d"; }
.fa-file-xmark::before { content: "\f317"; }
.fa-file-xml::before { content: "\e654"; }
.fa-file-zip::before { content: "\e5ee"; }
.fa-file-zipper::before { content: "\f1c6"; }
.fa-files::before { content: "\e178"; }
.fa-files-medical::before { content: "\f7fd"; }
.fa-fill::before { content: "\f575"; }
.fa-fill-drip::before { content: "\f576"; }
.fa-film::before { content: "\f008"; }
.fa-film-alt::before { content: "\f3a0"; }
.fa-film-canister::before { content: "\f8b7"; }
.fa-film-cannister::before { content: "\f8b7"; }
.fa-film-simple::before { content: "\f3a0"; }
.fa-film-slash::before { content: "\e179"; }
.fa-films::before { content: "\e17a"; }
.fa-filter::before { content: "\f0b0"; }
.fa-filter-circle-dollar::before { content: "\f662"; }
.fa-filter-circle-xmark::before { content: "\e17b"; }
.fa-filter-list::before { content: "\e17c"; }
.fa-filter-slash::before { content: "\e17d"; }
.fa-filters::before { content: "\e17e"; }
.fa-fingerprint::before { content: "\f577"; }
.fa-fire::before { content: "\f06d"; }
.fa-fire-alt::before { content: "\f7e4"; }
.fa-fire-burner::before { content: "\e4f1"; }
.fa-fire-extinguisher::before { content: "\f134"; }
.fa-fire-flame::before { content: "\f6df"; }
.fa-fire-flame-curved::before { content: "\f7e4"; }
.fa-fire-flame-simple::before { content: "\f46a"; }
.fa-fire-hydrant::before { content: "\e17f"; }
.fa-fire-smoke::before { content: "\f74b"; }
.fa-fireplace::before { content: "\f79a"; }
.fa-firewall::before { content: "\e3dc"; }
.fa-first-aid::before { content: "\f479"; }
.fa-fish::before { content: "\f578"; }
.fa-fish-bones::before { content: "\e304"; }
.fa-fish-cooked::before { content: "\f7fe"; }
.fa-fish-fins::before { content: "\e4f2"; }
.fa-fishing-rod::before { content: "\e3a8"; }
.fa-fist-raised::before { content: "\f6de"; }
.fa-flag::before { content: "\f024"; }
.fa-flag-alt::before { content: "\f74c"; }
.fa-flag-checkered::before { content: "\f11e"; }
.fa-flag-pennant::before { content: "\f456"; }
.fa-flag-swallowtail::before { content: "\f74c"; }
.fa-flag-usa::before { content: "\f74d"; }
.fa-flame::before { content: "\f6df"; }
.fa-flashlight::before { content: "\f8b8"; }
.fa-flask::before { content: "\f0c3"; }
.fa-flask-gear::before { content: "\e5f1"; }
.fa-flask-poison::before { content: "\f6e0"; }
.fa-flask-potion::before { content: "\f6e1"; }
.fa-flask-round-poison::before { content: "\f6e0"; }
.fa-flask-round-potion::before { content: "\f6e1"; }
.fa-flask-vial::before { content: "\e4f3"; }
.fa-flatbread::before { content: "\e40b"; }
.fa-flatbread-stuffed::before { content: "\e40c"; }
.fa-floppy-disk::before { content: "\f0c7"; }
.fa-floppy-disk-circle-arrow-right::before { content: "\e180"; }
.fa-floppy-disk-circle-xmark::before { content: "\e181"; }
.fa-floppy-disk-pen::before { content: "\e182"; }
.fa-floppy-disk-times::before { content: "\e181"; }
.fa-floppy-disks::before { content: "\e183"; }
.fa-florin-sign::before { content: "\e184"; }
.fa-flower::before { content: "\f7ff"; }
.fa-flower-daffodil::before { content: "\f800"; }
.fa-flower-tulip::before { content: "\f801"; }
.fa-flushed::before { content: "\f579"; }
.fa-flute::before { content: "\f8b9"; }
.fa-flux-capacitor::before { content: "\f8ba"; }
.fa-flying-disc::before { content: "\e3a9"; }
.fa-fog::before { content: "\f74e"; }
.fa-folder::before { content: "\f07b"; }
.fa-folder-arrow-down::before { content: "\e053"; }
.fa-folder-arrow-up::before { content: "\e054"; }
.fa-folder-blank::before { content: "\f07b"; }
.fa-folder-bookmark::before { content: "\e186"; }
.fa-folder-check::before { content: "\e64e"; }
.fa-folder-closed::before { content: "\e185"; }
.fa-folder-cog::before { content: "\e187"; }
.fa-folder-download::before { content: "\e053"; }
.fa-folder-gear::before { content: "\e187"; }
.fa-folder-grid::before { content: "\e188"; }
.fa-folder-heart::before { content: "\e189"; }
.fa-folder-image::before { content: "\e18a"; }
.fa-folder-magnifying-glass::before { content: "\e18b"; }
.fa-folder-medical::before { content: "\e18c"; }
.fa-folder-minus::before { content: "\f65d"; }
.fa-folder-music::before { content: "\e18d"; }
.fa-folder-open::before { content: "\f07c"; }
.fa-folder-plus::before { content: "\f65e"; }
.fa-folder-search::before { content: "\e18b"; }
.fa-folder-times::before { content: "\f65f"; }
.fa-folder-tree::before { content: "\f802"; }
.fa-folder-upload::before { content: "\e054"; }
.fa-folder-user::before { content: "\e18e"; }
.fa-folder-xmark::before { content: "\f65f"; }
.fa-folders::before { content: "\f660"; }
.fa-fondue-pot::before { content: "\e40d"; }
.fa-font::before { content: "\f031"; }
.fa-font-awesome::before { content: "\f2b4"; }
.fa-font-awesome-flag::before { content: "\f2b4"; }
.fa-font-awesome-logo-full::before { content: "\f2b4"; }
.fa-font-case::before { content: "\f866"; }
.fa-football::before { content: "\f44e"; }
.fa-football-ball::before { content: "\f44e"; }
.fa-football-helmet::before { content: "\f44f"; }
.fa-fork::before { content: "\f2e3"; }
.fa-fork-knife::before { content: "\f2e6"; }
.fa-forklift::before { content: "\f47a"; }
.fa-fort::before { content: "\e486"; }
.fa-forward::before { content: "\f04e"; }
.fa-forward-fast::before { content: "\f050"; }
.fa-forward-step::before { content: "\f051"; }
.fa-fragile::before { content: "\f4bb"; }
.fa-frame::before { content: "\e495"; }
.fa-franc-sign::before { content: "\e18f"; }
.fa-french-fries::before { content: "\f803"; }
.fa-frog::before { content: "\f52e"; }
.fa-frosty-head::before { content: "\f79b"; }
.fa-frown::before { content: "\f119"; }
.fa-frown-open::before { content: "\f57a"; }
.fa-function::before { content: "\f661"; }
.fa-funnel-dollar::before { content: "\f662"; }
.fa-futbol::before { content: "\f1e3"; }
.fa-futbol-ball::before { content: "\f1e3"; }
.fa-g::before { content: "\47"; }
.fa-galaxy::before { content: "\e008"; }
.fa-gallery-thumbnails::before { content: "\e3aa"; }
.fa-game-board::before { content: "\f867"; }
.fa-game-board-alt::before { content: "\f868"; }
.fa-game-board-simple::before { content: "\f868"; }
.fa-game-console-handheld::before { content: "\f8bb"; }
.fa-game-console-handheld-crank::before { content: "\e5b9"; }
.fa-gamepad::before { content: "\f11b"; }
.fa-gamepad-alt::before { content: "\e5a2"; }
.fa-gamepad-modern::before { content: "\e5a2"; }
.fa-garage::before { content: "\e009"; }
.fa-garage-car::before { content: "\e00a"; }
.fa-garage-open::before { content: "\e00b"; }
.fa-garlic::before { content: "\e40e"; }
.fa-gas-pump::before { content: "\f52f"; }
.fa-gas-pump-slash::before { content: "\f5f4"; }
.fa-gauge::before { content: "\f624"; }
.fa-gauge-circle-bolt::before { content: "\e496"; }
.fa-gauge-circle-minus::before { content: "\e497"; }
.fa-gauge-circle-plus::before { content: "\e498"; }
.fa-gauge-high::before { content: "\f625"; }
.fa-gauge-low::before { content: "\f627"; }
.fa-gauge-max::before { content: "\f626"; }
.fa-gauge-med::before { content: "\f624"; }
.fa-gauge-min::before { content: "\f628"; }
.fa-gauge-simple::before { content: "\f629"; }
.fa-gauge-simple-high::before { content: "\f62a"; }
.fa-gauge-simple-low::before { content: "\f62c"; }
.fa-gauge-simple-max::before { content: "\f62b"; }
.fa-gauge-simple-med::before { content: "\f629"; }
.fa-gauge-simple-min::before { content: "\f62d"; }
.fa-gave-dandy::before { content: "\e409"; }
.fa-gavel::before { content: "\f0e3"; }
.fa-gbp::before { content: "\f154"; }
.fa-gear::before { content: "\f013"; }
.fa-gear-code::before { content: "\e5e8"; }
.fa-gear-complex::before { content: "\e5e9"; }
.fa-gear-complex-code::before { content: "\e5eb"; }
.fa-gears::before { content: "\f085"; }
.fa-gem::before { content: "\f3a5"; }
.fa-genderless::before { content: "\f22d"; }
.fa-ghost::before { content: "\f6e2"; }
.fa-gif::before { content: "\e190"; }
.fa-gift::before { content: "\f06b"; }
.fa-gift-card::before { content: "\f663"; }
.fa-gifts::before { content: "\f79c"; }
.fa-gingerbread-man::before { content: "\f79d"; }
.fa-glass::before { content: "\f804"; }
.fa-glass-champagne::before { content: "\f79e"; }
.fa-glass-cheers::before { content: "\f79f"; }
.fa-glass-citrus::before { content: "\f869"; }
.fa-glass-empty::before { content: "\e191"; }
.fa-glass-half::before { content: "\e192"; }
.fa-glass-half-empty::before { content: "\e192"; }
.fa-glass-half-full::before { content: "\e192"; }
.fa-glass-martini::before { content: "\f000"; }
.fa-glass-martini-alt::before { content: "\f57b"; }
.fa-glass-water::before { content: "\e4f4"; }
.fa-glass-water-droplet::before { content: "\e4f5"; }
.fa-glass-whiskey::before { content: "\f7a0"; }
.fa-glass-whiskey-rocks::before { content: "\f7a1"; }
.fa-glasses::before { content: "\f530"; }
.fa-glasses-alt::before { content: "\f5f5"; }
.fa-glasses-round::before { content: "\f5f5"; }
.fa-globe::before { content: "\f0ac"; }
.fa-globe-africa::before { content: "\f57c"; }
.fa-globe-americas::before { content: "\f57d"; }
.fa-globe-asia::before { content: "\f57e"; }
.fa-globe-europe::before { content: "\f7a2"; }
.fa-globe-oceania::before { content: "\e47b"; }
.fa-globe-pointer::before { content: "\e60e"; }
.fa-globe-snow::before { content: "\f7a3"; }
.fa-globe-stand::before { content: "\f5f6"; }
.fa-globe-wifi::before { content: "\e685"; }
.fa-glove-boxing::before { content: "\f438"; }
.fa-goal-net::before { content: "\e3ab"; }
.fa-golf-ball::before { content: "\f450"; }
.fa-golf-ball-tee::before { content: "\f450"; }
.fa-golf-club::before { content: "\f451"; }
.fa-golf-flag-hole::before { content: "\e3ac"; }
.fa-gopuram::before { content: "\f664"; }
.fa-graduation-cap::before { content: "\f19d"; }
.fa-gramophone::before { content: "\f8bd"; }
.fa-grapes::before { content: "\e306"; }
.fa-grate::before { content: "\e193"; }
.fa-grate-droplet::before { content: "\e194"; }
.fa-greater-than::before { content: "\3e"; }
.fa-greater-than-equal::before { content: "\f532"; }
.fa-grid::before { content: "\e195"; }
.fa-grid-2::before { content: "\e196"; }
.fa-grid-2-plus::before { content: "\e197"; }
.fa-grid-3::before { content: "\e195"; }
.fa-grid-4::before { content: "\e198"; }
.fa-grid-5::before { content: "\e199"; }
.fa-grid-dividers::before { content: "\e3ad"; }
.fa-grid-horizontal::before { content: "\e307"; }
.fa-grid-round::before { content: "\e5da"; }
.fa-grid-round-2::before { content: "\e5db"; }
.fa-grid-round-2-plus::before { content: "\e5dc"; }
.fa-grid-round-4::before { content: "\e5dd"; }
.fa-grid-round-5::before { content: "\e5de"; }
.fa-grill::before { content: "\e5a3"; }
.fa-grill-fire::before { content: "\e5a4"; }
.fa-grill-hot::before { content: "\e5a5"; }
.fa-grimace::before { content: "\f57f"; }
.fa-grin::before { content: "\f580"; }
.fa-grin-alt::before { content: "\f581"; }
.fa-grin-beam::before { content: "\f582"; }
.fa-grin-beam-sweat::before { content: "\f583"; }
.fa-grin-hearts::before { content: "\f584"; }
.fa-grin-squint::before { content: "\f585"; }
.fa-grin-squint-tears::before { content: "\f586"; }
.fa-grin-stars::before { content: "\f587"; }
.fa-grin-tears::before { content: "\f588"; }
.fa-grin-tongue::before { content: "\f589"; }
.fa-grin-tongue-squint::before { content: "\f58a"; }
.fa-grin-tongue-wink::before { content: "\f58b"; }
.fa-grin-wink::before { content: "\f58c"; }
.fa-grip::before { content: "\f58d"; }
.fa-grip-dots::before { content: "\e410"; }
.fa-grip-dots-vertical::before { content: "\e411"; }
.fa-grip-horizontal::before { content: "\f58d"; }
.fa-grip-lines::before { content: "\f7a4"; }
.fa-grip-lines-vertical::before { content: "\f7a5"; }
.fa-grip-vertical::before { content: "\f58e"; }
.fa-group-arrows-rotate::before { content: "\e4f6"; }
.fa-guarani-sign::before { content: "\e19a"; }
.fa-guitar::before { content: "\f7a6"; }
.fa-guitar-electric::before { content: "\f8be"; }
.fa-guitars::before { content: "\f8bf"; }
.fa-gun::before { content: "\e19b"; }
.fa-gun-slash::before { content: "\e19c"; }
.fa-gun-squirt::before { content: "\e19d"; }
.fa-h::before { content: "\48"; }
.fa-h-square::before { content: "\f0fd"; }
.fa-h1::before { content: "\f313"; }
.fa-h2::before { content: "\f314"; }
.fa-h3::before { content: "\f315"; }
.fa-h4::before { content: "\f86a"; }
.fa-h5::before { content: "\e412"; }
.fa-h6::before { content: "\e413"; }
.fa-hamburger::before { content: "\f805"; }
.fa-hammer::before { content: "\f6e3"; }
.fa-hammer-brush::before { content: "\e620"; }
.fa-hammer-crash::before { content: "\e414"; }
.fa-hammer-war::before { content: "\f6e4"; }
.fa-hamsa::before { content: "\f665"; }
.fa-hand::before { content: "\f256"; }
.fa-hand-back-fist::before { content: "\f255"; }
.fa-hand-back-point-down::before { content: "\e19e"; }
.fa-hand-back-point-left::before { content: "\e19f"; }
.fa-hand-back-point-ribbon::before { content: "\e1a0"; }
.fa-hand-back-point-right::before { content: "\e1a1"; }
.fa-hand-back-point-up::before { content: "\e1a2"; }
.fa-hand-dots::before { content: "\f461"; }
.fa-hand-fingers-crossed::before { content: "\e1a3"; }
.fa-hand-fist::before { content: "\f6de"; }
.fa-hand-heart::before { content: "\f4bc"; }
.fa-hand-holding::before { content: "\f4bd"; }
.fa-hand-holding-box::before { content: "\f47b"; }
.fa-hand-holding-circle-dollar::before { content: "\e621"; }
.fa-hand-holding-dollar::before { content: "\f4c0"; }
.fa-hand-holding-droplet::before { content: "\f4c1"; }
.fa-hand-holding-hand::before { content: "\e4f7"; }
.fa-hand-holding-heart::before { content: "\f4be"; }
.fa-hand-holding-magic::before { content: "\f6e5"; }
.fa-hand-holding-medical::before { content: "\e05c"; }
.fa-hand-holding-seedling::before { content: "\f4bf"; }
.fa-hand-holding-skull::before { content: "\e1a4"; }
.fa-hand-holding-usd::before { content: "\f4c0"; }
.fa-hand-holding-water::before { content: "\f4c1"; }
.fa-hand-horns::before { content: "\e1a9"; }
.fa-hand-lizard::before { content: "\f258"; }
.fa-hand-love::before { content: "\e1a5"; }
.fa-hand-middle-finger::before { content: "\f806"; }
.fa-hand-paper::before { content: "\f256"; }
.fa-hand-peace::before { content: "\f25b"; }
.fa-hand-point-down::before { content: "\f0a7"; }
.fa-hand-point-left::before { content: "\f0a5"; }
.fa-hand-point-ribbon::before { content: "\e1a6"; }
.fa-hand-point-right::before { content: "\f0a4"; }
.fa-hand-point-up::before { content: "\f0a6"; }
.fa-hand-pointer::before { content: "\f25a"; }
.fa-hand-receiving::before { content: "\f47c"; }
.fa-hand-rock::before { content: "\f255"; }
.fa-hand-scissors::before { content: "\f257"; }
.fa-hand-sparkles::before { content: "\e05d"; }
.fa-hand-spock::before { content: "\f259"; }
.fa-hand-wave::before { content: "\e1a7"; }
.fa-handcuffs::before { content: "\e4f8"; }
.fa-hands::before { content: "\f2a7"; }
.fa-hands-american-sign-language-interpreting::before { content: "\f2a3"; }
.fa-hands-asl-interpreting::before { content: "\f2a3"; }
.fa-hands-bound::before { content: "\e4f9"; }
.fa-hands-bubbles::before { content: "\e05e"; }
.fa-hands-clapping::before { content: "\e1a8"; }
.fa-hands-heart::before { content: "\f4c3"; }
.fa-hands-helping::before { content: "\f4c4"; }
.fa-hands-holding::before { content: "\f4c2"; }
.fa-hands-holding-child::before { content: "\e4fa"; }
.fa-hands-holding-circle::before { content: "\e4fb"; }
.fa-hands-holding-diamond::before { content: "\f47c"; }
.fa-hands-holding-dollar::before { content: "\f4c5"; }
.fa-hands-holding-heart::before { content: "\f4c3"; }
.fa-hands-praying::before { content: "\f684"; }
.fa-hands-usd::before { content: "\f4c5"; }
.fa-hands-wash::before { content: "\e05e"; }
.fa-handshake::before { content: "\f2b5"; }
.fa-handshake-alt::before { content: "\f4c6"; }
.fa-handshake-alt-slash::before { content: "\e05f"; }
.fa-handshake-angle::before { content: "\f4c4"; }
.fa-handshake-simple::before { content: "\f4c6"; }
.fa-handshake-simple-slash::before { content: "\e05f"; }
.fa-handshake-slash::before { content: "\e060"; }
.fa-hanukiah::before { content: "\f6e6"; }
.fa-hard-drive::before { content: "\f0a0"; }
.fa-hard-hat::before { content: "\f807"; }
.fa-hard-of-hearing::before { content: "\f2a4"; }
.fa-hashtag::before { content: "\23"; }
.fa-hashtag-lock::before { content: "\e415"; }
.fa-hat-beach::before { content: "\e606"; }
.fa-hat-chef::before { content: "\f86b"; }
.fa-hat-cowboy::before { content: "\f8c0"; }
.fa-hat-cowboy-side::before { content: "\f8c1"; }
.fa-hat-hard::before { content: "\f807"; }
.fa-hat-santa::before { content: "\f7a7"; }
.fa-hat-winter::before { content: "\f7a8"; }
.fa-hat-witch::before { content: "\f6e7"; }
.fa-hat-wizard::before { content: "\f6e8"; }
.fa-haykal::before { content: "\f666"; }
.fa-hdd::before { content: "\f0a0"; }
.fa-head-side::before { content: "\f6e9"; }
.fa-head-side-brain::before { content: "\f808"; }
.fa-head-side-cough::before { content: "\e061"; }
.fa-head-side-cough-slash::before { content: "\e062"; }
.fa-head-side-gear::before { content: "\e611"; }
.fa-head-side-goggles::before { content: "\f6ea"; }
.fa-head-side-headphones::before { content: "\f8c2"; }
.fa-head-side-heart::before { content: "\e1aa"; }
.fa-head-side-mask::before { content: "\e063"; }
.fa-head-side-medical::before { content: "\f809"; }
.fa-head-side-virus::before { content: "\e064"; }
.fa-head-vr::before { content: "\f6ea"; }
.fa-header::before { content: "\f1dc"; }
.fa-heading::before { content: "\f1dc"; }
.fa-headphones::before { content: "\f025"; }
.fa-headphones-alt::before { content: "\f58f"; }
.fa-headphones-simple::before { content: "\f58f"; }
.fa-headset::before { content: "\f590"; }
.fa-heart::before { content: "\f004"; }
.fa-heart-broken::before { content: "\f7a9"; }
.fa-heart-circle::before { content: "\f4c7"; }
.fa-heart-circle-bolt::before { content: "\e4fc"; }
.fa-heart-circle-check::before { content: "\e4fd"; }
.fa-heart-circle-exclamation::before { content: "\e4fe"; }
.fa-heart-circle-minus::before { content: "\e4ff"; }
.fa-heart-circle-plus::before { content: "\e500"; }
.fa-heart-circle-xmark::before { content: "\e501"; }
.fa-heart-crack::before { content: "\f7a9"; }
.fa-heart-half::before { content: "\e1ab"; }
.fa-heart-half-alt::before { content: "\e1ac"; }
.fa-heart-half-stroke::before { content: "\e1ac"; }
.fa-heart-music-camera-bolt::before { content: "\f86d"; }
.fa-heart-pulse::before { content: "\f21e"; }
.fa-heart-rate::before { content: "\f5f8"; }
.fa-heart-square::before { content: "\f4c8"; }
.fa-heartbeat::before { content: "\f21e"; }
.fa-heat::before { content: "\e00c"; }
.fa-helicopter::before { content: "\f533"; }
.fa-helicopter-symbol::before { content: "\e502"; }
.fa-helmet-battle::before { content: "\f6eb"; }
.fa-helmet-safety::before { content: "\f807"; }
.fa-helmet-un::before { content: "\e503"; }
.fa-hexagon::before { content: "\f312"; }
.fa-hexagon-check::before { content: "\e416"; }
.fa-hexagon-divide::before { content: "\e1ad"; }
.fa-hexagon-exclamation::before { content: "\e417"; }
.fa-hexagon-image::before { content: "\e504"; }
.fa-hexagon-minus::before { content: "\f307"; }
.fa-hexagon-plus::before { content: "\f300"; }
.fa-hexagon-vertical-nft::before { content: "\e505"; }
.fa-hexagon-vertical-nft-slanted::before { content: "\e506"; }
.fa-hexagon-xmark::before { content: "\f2ee"; }
.fa-high-definition::before { content: "\e1ae"; }
.fa-highlighter::before { content: "\f591"; }
.fa-highlighter-line::before { content: "\e1af"; }
.fa-hiking::before { content: "\f6ec"; }
.fa-hill-avalanche::before { content: "\e507"; }
.fa-hill-rockslide::before { content: "\e508"; }
.fa-hippo::before { content: "\f6ed"; }
.fa-history::before { content: "\f1da"; }
.fa-hockey-mask::before { content: "\f6ee"; }
.fa-hockey-puck::before { content: "\f453"; }
.fa-hockey-stick-puck::before { content: "\e3ae"; }
.fa-hockey-sticks::before { content: "\f454"; }
.fa-holly-berry::before { content: "\f7aa"; }
.fa-home::before { content: "\f015"; }
.fa-home-alt::before { content: "\f015"; }
.fa-home-blank::before { content: "\e487"; }
.fa-home-heart::before { content: "\f4c9"; }
.fa-home-lg::before { content: "\e3af"; }
.fa-home-lg-alt::before { content: "\f015"; }
.fa-home-user::before { content: "\e1b0"; }
.fa-honey-pot::before { content: "\e418"; }
.fa-hood-cloak::before { content: "\f6ef"; }
.fa-horizontal-rule::before { content: "\f86c"; }
.fa-horse::before { content: "\f6f0"; }
.fa-horse-head::before { content: "\f7ab"; }
.fa-horse-saddle::before { content: "\f8c3"; }
.fa-hose::before { content: "\e419"; }
.fa-hose-reel::before { content: "\e41a"; }
.fa-hospital::before { content: "\f0f8"; }
.fa-hospital-alt::before { content: "\f0f8"; }
.fa-hospital-symbol::before { content: "\f47e"; }
.fa-hospital-user::before { content: "\f80d"; }
.fa-hospital-wide::before { content: "\f0f8"; }
.fa-hospitals::before { content: "\f80e"; }
.fa-hot-tub::before { content: "\f593"; }
.fa-hot-tub-person::before { content: "\f593"; }
.fa-hotdog::before { content: "\f80f"; }
.fa-hotel::before { content: "\f594"; }
.fa-hourglass::before { content: "\f254"; }
.fa-hourglass-1::before { content: "\f251"; }
.fa-hourglass-2::before { content: "\f252"; }
.fa-hourglass-3::before { content: "\f253"; }
.fa-hourglass-clock::before { content: "\e41b"; }
.fa-hourglass-empty::before { content: "\f254"; }
.fa-hourglass-end::before { content: "\f253"; }
.fa-hourglass-half::before { content: "\f252"; }
.fa-hourglass-start::before { content: "\f251"; }
.fa-house::before { content: "\f015"; }
.fa-house-blank::before { content: "\e487"; }
.fa-house-building::before { content: "\e1b1"; }
.fa-house-chimney::before { content: "\e3af"; }
.fa-house-chimney-blank::before { content: "\e3b0"; }
.fa-house-chimney-crack::before { content: "\f6f1"; }
.fa-house-chimney-heart::before { content: "\e1b2"; }
.fa-house-chimney-medical::before { content: "\f7f2"; }
.fa-house-chimney-user::before { content: "\e065"; }
.fa-house-chimney-window::before { content: "\e00d"; }
.fa-house-circle-check::before { content: "\e509"; }
.fa-house-circle-exclamation::before { content: "\e50a"; }
.fa-house-circle-xmark::before { content: "\e50b"; }
.fa-house-crack::before { content: "\e3b1"; }
.fa-house-damage::before { content: "\f6f1"; }
.fa-house-day::before { content: "\e00e"; }
.fa-house-fire::before { content: "\e50c"; }
.fa-house-flag::before { content: "\e50d"; }
.fa-house-flood::before { content: "\f74f"; }
.fa-house-flood-water::before { content: "\e50e"; }
.fa-house-flood-water-circle-arrow-right::before { content: "\e50f"; }
.fa-house-heart::before { content: "\f4c9"; }
.fa-house-laptop::before { content: "\e066"; }
.fa-house-leave::before { content: "\e00f"; }
.fa-house-lock::before { content: "\e510"; }
.fa-house-medical::before { content: "\e3b2"; }
.fa-house-medical-circle-check::before { content: "\e511"; }
.fa-house-medical-circle-exclamation::before { content: "\e512"; }
.fa-house-medical-circle-xmark::before { content: "\e513"; }
.fa-house-medical-flag::before { content: "\e514"; }
.fa-house-night::before { content: "\e010"; }
.fa-house-person-arrive::before { content: "\e011"; }
.fa-house-person-depart::before { content: "\e00f"; }
.fa-house-person-leave::before { content: "\e00f"; }
.fa-house-person-return::before { content: "\e011"; }
.fa-house-return::before { content: "\e011"; }
.fa-house-signal::before { content: "\e012"; }
.fa-house-tree::before { content: "\e1b3"; }
.fa-house-tsunami::before { content: "\e515"; }
.fa-house-turret::before { content: "\e1b4"; }
.fa-house-user::before { content: "\e1b0"; }
.fa-house-water::before { content: "\f74f"; }
.fa-house-window::before { content: "\e3b3"; }
.fa-hryvnia::before { content: "\f6f2"; }
.fa-hryvnia-sign::before { content: "\f6f2"; }
.fa-humidity::before { content: "\f750"; }
.fa-hundred-points::before { content: "\e41c"; }
.fa-hurricane::before { content: "\f751"; }
.fa-hydra::before { content: "\e686"; }
.fa-hyphen::before { content: "\2d"; }
.fa-i::before { content: "\49"; }
.fa-i-cursor::before { content: "\f246"; }
.fa-ice-cream::before { content: "\f810"; }
.fa-ice-skate::before { content: "\f7ac"; }
.fa-icicles::before { content: "\f7ad"; }
.fa-icons::before { content: "\f86d"; }
.fa-icons-alt::before { content: "\f86e"; }
.fa-id-badge::before { content: "\f2c1"; }
.fa-id-card::before { content: "\f2c2"; }
.fa-id-card-alt::before { content: "\f47f"; }
.fa-id-card-clip::before { content: "\f47f"; }
.fa-igloo::before { content: "\f7ae"; }
.fa-ils::before { content: "\f20b"; }
.fa-image::before { content: "\f03e"; }
.fa-image-landscape::before { content: "\e1b5"; }
.fa-image-polaroid::before { content: "\f8c4"; }
.fa-image-polaroid-user::before { content: "\e1b6"; }
.fa-image-portrait::before { content: "\f3e0"; }
.fa-image-slash::before { content: "\e1b7"; }
.fa-image-user::before { content: "\e1b8"; }
.fa-images::before { content: "\f302"; }
.fa-images-user::before { content: "\e1b9"; }
.fa-inbox::before { content: "\f01c"; }
.fa-inbox-arrow-down::before { content: "\f310"; }
.fa-inbox-arrow-up::before { content: "\f311"; }
.fa-inbox-full::before { content: "\e1ba"; }
.fa-inbox-in::before { content: "\f310"; }
.fa-inbox-out::before { content: "\f311"; }
.fa-inboxes::before { content: "\e1bb"; }
.fa-indent::before { content: "\f03c"; }
.fa-indian-rupee::before { content: "\e1bc"; }
.fa-indian-rupee-sign::before { content: "\e1bc"; }
.fa-industry::before { content: "\f275"; }
.fa-industry-alt::before { content: "\f3b3"; }
.fa-industry-windows::before { content: "\f3b3"; }
.fa-infinity::before { content: "\f534"; }
.fa-info::before { content: "\f129"; }
.fa-info-circle::before { content: "\f05a"; }
.fa-info-square::before { content: "\f30f"; }
.fa-inhaler::before { content: "\f5f9"; }
.fa-input-numeric::before { content: "\e1bd"; }
.fa-input-pipe::before { content: "\e1be"; }
.fa-input-text::before { content: "\e1bf"; }
.fa-inr::before { content: "\e1bc"; }
.fa-institution::before { content: "\f19c"; }
.fa-integral::before { content: "\f667"; }
.fa-interrobang::before { content: "\e5ba"; }
.fa-intersection::before { content: "\f668"; }
.fa-inventory::before { content: "\f480"; }
.fa-island-tree-palm::before { content: "\f811"; }
.fa-island-tropical::before { content: "\f811"; }
.fa-italic::before { content: "\f033"; }
.fa-j::before { content: "\4a"; }
.fa-jack-o-lantern::before { content: "\f30e"; }
.fa-jar::before { content: "\e516"; }
.fa-jar-wheat::before { content: "\e517"; }
.fa-jedi::before { content: "\f669"; }
.fa-jet-fighter::before { content: "\f0fb"; }
.fa-jet-fighter-up::before { content: "\e518"; }
.fa-joint::before { content: "\f595"; }
.fa-journal-whills::before { content: "\f66a"; }
.fa-joystick::before { content: "\f8c5"; }
.fa-jpy::before { content: "\f157"; }
.fa-jug::before { content: "\f8c6"; }
.fa-jug-bottle::before { content: "\e5fb"; }
.fa-jug-detergent::before { content: "\e519"; }
.fa-k::before { content: "\4b"; }
.fa-kaaba::before { content: "\f66b"; }
.fa-kazoo::before { content: "\f8c7"; }
.fa-kerning::before { content: "\f86f"; }
.fa-key::before { content: "\f084"; }
.fa-key-skeleton::before { content: "\f6f3"; }
.fa-key-skeleton-left-right::before { content: "\e3b4"; }
.fa-keyboard::before { content: "\f11c"; }
.fa-keyboard-brightness::before { content: "\e1c0"; }
.fa-keyboard-brightness-low::before { content: "\e1c1"; }
.fa-keyboard-down::before { content: "\e1c2"; }
.fa-keyboard-left::before { content: "\e1c3"; }
.fa-keynote::before { content: "\f66c"; }
.fa-khanda::before { content: "\f66d"; }
.fa-kidneys::before { content: "\f5fb"; }
.fa-kip-sign::before { content: "\e1c4"; }
.fa-kiss::before { content: "\f596"; }
.fa-kiss-beam::before { content: "\f597"; }
.fa-kiss-wink-heart::before { content: "\f598"; }
.fa-kit-medical::before { content: "\f479"; }
.fa-kitchen-set::before { content: "\e51a"; }
.fa-kite::before { content: "\f6f4"; }
.fa-kiwi-bird::before { content: "\f535"; }
.fa-kiwi-fruit::before { content: "\e30c"; }
.fa-knife::before { content: "\f2e4"; }
.fa-knife-kitchen::before { content: "\f6f5"; }
.fa-krw::before { content: "\f159"; }
.fa-l::before { content: "\4c"; }
.fa-lacrosse-stick::before { content: "\e3b5"; }
.fa-lacrosse-stick-ball::before { content: "\e3b6"; }
.fa-ladder-water::before { content: "\f5c5"; }
.fa-lambda::before { content: "\f66e"; }
.fa-lamp::before { content: "\f4ca"; }
.fa-lamp-desk::before { content: "\e014"; }
.fa-lamp-floor::before { content: "\e015"; }
.fa-lamp-street::before { content: "\e1c5"; }
.fa-land-mine-on::before { content: "\e51b"; }
.fa-landmark::before { content: "\f66f"; }
.fa-landmark-alt::before { content: "\f752"; }
.fa-landmark-dome::before { content: "\f752"; }
.fa-landmark-flag::before { content: "\e51c"; }
.fa-landmark-magnifying-glass::before { content: "\e622"; }
.fa-landscape::before { content: "\e1b5"; }
.fa-language::before { content: "\f1ab"; }
.fa-laptop::before { content: "\f109"; }
.fa-laptop-arrow-down::before { content: "\e1c6"; }
.fa-laptop-binary::before { content: "\e5e7"; }
.fa-laptop-code::before { content: "\f5fc"; }
.fa-laptop-file::before { content: "\e51d"; }
.fa-laptop-house::before { content: "\e066"; }
.fa-laptop-medical::before { content: "\f812"; }
.fa-laptop-mobile::before { content: "\f87a"; }
.fa-laptop-slash::before { content: "\e1c7"; }
.fa-lari-sign::before { content: "\e1c8"; }
.fa-lasso::before { content: "\f8c8"; }
.fa-lasso-sparkles::before { content: "\e1c9"; }
.fa-laugh::before { content: "\f599"; }
.fa-laugh-beam::before { content: "\f59a"; }
.fa-laugh-squint::before { content: "\f59b"; }
.fa-laugh-wink::before { content: "\f59c"; }
.fa-layer-group::before { content: "\f5fd"; }
.fa-layer-group-minus::before { content: "\f5fe"; }
.fa-layer-group-plus::before { content: "\f5ff"; }
.fa-layer-minus::before { content: "\f5fe"; }
.fa-layer-plus::before { content: "\f5ff"; }
.fa-leaf::before { content: "\f06c"; }
.fa-leaf-heart::before { content: "\f4cb"; }
.fa-leaf-maple::before { content: "\f6f6"; }
.fa-leaf-oak::before { content: "\f6f7"; }
.fa-leafy-green::before { content: "\e41d"; }
.fa-left::before { content: "\f355"; }
.fa-left-from-bracket::before { content: "\e66c"; }
.fa-left-from-line::before { content: "\f348"; }
.fa-left-long::before { content: "\f30a"; }
.fa-left-long-to-line::before { content: "\e41e"; }
.fa-left-right::before { content: "\f337"; }
.fa-left-to-bracket::before { content: "\e66d"; }
.fa-left-to-line::before { content: "\f34b"; }
.fa-legal::before { content: "\f0e3"; }
.fa-lemon::before { content: "\f094"; }
.fa-less-than::before { content: "\3c"; }
.fa-less-than-equal::before { content: "\f537"; }
.fa-level-down::before { content: "\f149"; }
.fa-level-down-alt::before { content: "\f3be"; }
.fa-level-up::before { content: "\f148"; }
.fa-level-up-alt::before { content: "\f3bf"; }
.fa-life-ring::before { content: "\f1cd"; }
.fa-light-ceiling::before { content: "\e016"; }
.fa-light-emergency::before { content: "\e41f"; }
.fa-light-emergency-on::before { content: "\e420"; }
.fa-light-switch::before { content: "\e017"; }
.fa-light-switch-off::before { content: "\e018"; }
.fa-light-switch-on::before { content: "\e019"; }
.fa-lightbulb::before { content: "\f0eb"; }
.fa-lightbulb-cfl::before { content: "\e5a6"; }
.fa-lightbulb-cfl-on::before { content: "\e5a7"; }
.fa-lightbulb-dollar::before { content: "\f670"; }
.fa-lightbulb-exclamation::before { content: "\f671"; }
.fa-lightbulb-exclamation-on::before { content: "\e1ca"; }
.fa-lightbulb-gear::before { content: "\e5fd"; }
.fa-lightbulb-message::before { content: "\e687"; }
.fa-lightbulb-on::before { content: "\f672"; }
.fa-lightbulb-slash::before { content: "\f673"; }
.fa-lighthouse::before { content: "\e612"; }
.fa-lights-holiday::before { content: "\f7b2"; }
.fa-line-chart::before { content: "\f201"; }
.fa-line-columns::before { content: "\f870"; }
.fa-line-height::before { content: "\f871"; }
.fa-lines-leaning::before { content: "\e51e"; }
.fa-link::before { content: "\f0c1"; }
.fa-link-horizontal::before { content: "\e1cb"; }
.fa-link-horizontal-slash::before { content: "\e1cc"; }
.fa-link-simple::before { content: "\e1cd"; }
.fa-link-simple-slash::before { content: "\e1ce"; }
.fa-link-slash::before { content: "\f127"; }
.fa-lips::before { content: "\f600"; }
.fa-lira-sign::before { content: "\f195"; }
.fa-list::before { content: "\f03a"; }
.fa-list-1-2::before { content: "\f0cb"; }
.fa-list-alt::before { content: "\f022"; }
.fa-list-check::before { content: "\f0ae"; }
.fa-list-dots::before { content: "\f0ca"; }
.fa-list-dropdown::before { content: "\e1cf"; }
.fa-list-music::before { content: "\f8c9"; }
.fa-list-numeric::before { content: "\f0cb"; }
.fa-list-ol::before { content: "\f0cb"; }
.fa-list-radio::before { content: "\e1d0"; }
.fa-list-squares::before { content: "\f03a"; }
.fa-list-timeline::before { content: "\e1d1"; }
.fa-list-tree::before { content: "\e1d2"; }
.fa-list-ul::before { content: "\f0ca"; }
.fa-litecoin-sign::before { content: "\e1d3"; }
.fa-loader::before { content: "\e1d4"; }
.fa-lobster::before { content: "\e421"; }
.fa-location::before { content: "\f601"; }
.fa-location-arrow::before { content: "\f124"; }
.fa-location-arrow-up::before { content: "\e63a"; }
.fa-location-check::before { content: "\f606"; }
.fa-location-circle::before { content: "\f602"; }
.fa-location-crosshairs::before { content: "\f601"; }
.fa-location-crosshairs-slash::before { content: "\f603"; }
.fa-location-dot::before { content: "\f3c5"; }
.fa-location-dot-slash::before { content: "\f605"; }
.fa-location-exclamation::before { content: "\f608"; }
.fa-location-minus::before { content: "\f609"; }
.fa-location-pen::before { content: "\f607"; }
.fa-location-pin::before { content: "\f041"; }
.fa-location-pin-lock::before { content: "\e51f"; }
.fa-location-pin-slash::before { content: "\f60c"; }
.fa-location-plus::before { content: "\f60a"; }
.fa-location-question::before { content: "\f60b"; }
.fa-location-slash::before { content: "\f603"; }
.fa-location-smile::before { content: "\f60d"; }
.fa-location-xmark::before { content: "\f60e"; }
.fa-lock::before { content: "\f023"; }
.fa-lock-a::before { content: "\e422"; }
.fa-lock-alt::before { content: "\f30d"; }
.fa-lock-hashtag::before { content: "\e423"; }
.fa-lock-keyhole::before { content: "\f30d"; }
.fa-lock-keyhole-open::before { content: "\f3c2"; }
.fa-lock-open::before { content: "\f3c1"; }
.fa-lock-open-alt::before { content: "\f3c2"; }
.fa-locust::before { content: "\e520"; }
.fa-lollipop::before { content: "\e424"; }
.fa-lollypop::before { content: "\e424"; }
.fa-long-arrow-alt-down::before { content: "\f309"; }
.fa-long-arrow-alt-left::before { content: "\f30a"; }
.fa-long-arrow-alt-right::before { content: "\f30b"; }
.fa-long-arrow-alt-up::before { content: "\f30c"; }
.fa-long-arrow-down::before { content: "\f175"; }
.fa-long-arrow-left::before { content: "\f177"; }
.fa-long-arrow-right::before { content: "\f178"; }
.fa-long-arrow-up::before { content: "\f176"; }
.fa-loveseat::before { content: "\f4cc"; }
.fa-low-vision::before { content: "\f2a8"; }
.fa-luchador::before { content: "\f455"; }
.fa-luchador-mask::before { content: "\f455"; }
.fa-luggage-cart::before { content: "\f59d"; }
.fa-lungs::before { content: "\f604"; }
.fa-lungs-virus::before { content: "\e067"; }
.fa-m::before { content: "\4d"; }
.fa-mace::before { content: "\f6f8"; }
.fa-magic::before { content: "\f0d0"; }
.fa-magic-wand-sparkles::before { content: "\e2ca"; }
.fa-magnet::before { content: "\f076"; }
.fa-magnifying-glass::before { content: "\f002"; }
.fa-magnifying-glass-arrow-right::before { content: "\e521"; }
.fa-magnifying-glass-arrows-rotate::before { content: "\e65e"; }
.fa-magnifying-glass-chart::before { content: "\e522"; }
.fa-magnifying-glass-dollar::before { content: "\f688"; }
.fa-magnifying-glass-location::before { content: "\f689"; }
.fa-magnifying-glass-minus::before { content: "\f010"; }
.fa-magnifying-glass-music::before { content: "\e65f"; }
.fa-magnifying-glass-play::before { content: "\e660"; }
.fa-magnifying-glass-plus::before { content: "\f00e"; }
.fa-magnifying-glass-waveform::before { content: "\e661"; }
.fa-mail-bulk::before { content: "\f674"; }
.fa-mail-forward::before { content: "\f064"; }
.fa-mail-reply::before { content: "\f3e5"; }
.fa-mail-reply-all::before { content: "\f122"; }
.fa-mailbox::before { content: "\f813"; }
.fa-mailbox-flag-up::before { content: "\e5bb"; }
.fa-maki-roll::before { content: "\e48b"; }
.fa-makizushi::before { content: "\e48b"; }
.fa-male::before { content: "\f183"; }
.fa-manat-sign::before { content: "\e1d5"; }
.fa-mandolin::before { content: "\f6f9"; }
.fa-mango::before { content: "\e30f"; }
.fa-manhole::before { content: "\e1d6"; }
.fa-map::before { content: "\f279"; }
.fa-map-location::before { content: "\f59f"; }
.fa-map-location-dot::before { content: "\f5a0"; }
.fa-map-marked::before { content: "\f59f"; }
.fa-map-marked-alt::before { content: "\f5a0"; }
.fa-map-marker::before { content: "\f041"; }
.fa-map-marker-alt::before { content: "\f3c5"; }
.fa-map-marker-alt-slash::before { content: "\f605"; }
.fa-map-marker-check::before { content: "\f606"; }
.fa-map-marker-edit::before { content: "\f607"; }
.fa-map-marker-exclamation::before { content: "\f608"; }
.fa-map-marker-minus::before { content: "\f609"; }
.fa-map-marker-plus::before { content: "\f60a"; }
.fa-map-marker-question::before { content: "\f60b"; }
.fa-map-marker-slash::before { content: "\f60c"; }
.fa-map-marker-smile::before { content: "\f60d"; }
.fa-map-marker-times::before { content: "\f60e"; }
.fa-map-marker-xmark::before { content: "\f60e"; }
.fa-map-pin::before { content: "\f276"; }
.fa-map-signs::before { content: "\f277"; }
.fa-marker::before { content: "\f5a1"; }
.fa-mars::before { content: "\f222"; }
.fa-mars-and-venus::before { content: "\f224"; }
.fa-mars-and-venus-burst::before { content: "\e523"; }
.fa-mars-double::before { content: "\f227"; }
.fa-mars-stroke::before { content: "\f229"; }
.fa-mars-stroke-h::before { content: "\f22b"; }
.fa-mars-stroke-right::before { content: "\f22b"; }
.fa-mars-stroke-up::before { content: "\f22a"; }
.fa-mars-stroke-v::before { content: "\f22a"; }
.fa-martini-glass::before { content: "\f57b"; }
.fa-martini-glass-citrus::before { content: "\f561"; }
.fa-martini-glass-empty::before { content: "\f000"; }
.fa-mask::before { content: "\f6fa"; }
.fa-mask-face::before { content: "\e1d7"; }
.fa-mask-luchador::before { content: "\f455"; }
.fa-mask-snorkel::before { content: "\e3b7"; }
.fa-mask-ventilator::before { content: "\e524"; }
.fa-masks-theater::before { content: "\f630"; }
.fa-mattress-pillow::before { content: "\e525"; }
.fa-maximize::before { content: "\f31e"; }
.fa-meat::before { content: "\f814"; }
.fa-medal::before { content: "\f5a2"; }
.fa-medkit::before { content: "\f0fa"; }
.fa-megaphone::before { content: "\f675"; }
.fa-meh::before { content: "\f11a"; }
.fa-meh-blank::before { content: "\f5a4"; }
.fa-meh-rolling-eyes::before { content: "\f5a5"; }
.fa-melon::before { content: "\e310"; }
.fa-melon-slice::before { content: "\e311"; }
.fa-memo::before { content: "\e1d8"; }
.fa-memo-circle-check::before { content: "\e1d9"; }
.fa-memo-circle-info::before { content: "\e49a"; }
.fa-memo-pad::before { content: "\e1da"; }
.fa-memory::before { content: "\f538"; }
.fa-menorah::before { content: "\f676"; }
.fa-mercury::before { content: "\f223"; }
.fa-merge::before { content: "\e526"; }
.fa-message::before { content: "\f27a"; }
.fa-message-arrow-down::before { content: "\e1db"; }
.fa-message-arrow-up::before { content: "\e1dc"; }
.fa-message-arrow-up-right::before { content: "\e1dd"; }
.fa-message-bot::before { content: "\e3b8"; }
.fa-message-captions::before { content: "\e1de"; }
.fa-message-check::before { content: "\f4a2"; }
.fa-message-code::before { content: "\e1df"; }
.fa-message-dollar::before { content: "\f650"; }
.fa-message-dots::before { content: "\f4a3"; }
.fa-message-edit::before { content: "\f4a4"; }
.fa-message-exclamation::before { content: "\f4a5"; }
.fa-message-heart::before { content: "\e5c9"; }
.fa-message-image::before { content: "\e1e0"; }
.fa-message-lines::before { content: "\f4a6"; }
.fa-message-medical::before { content: "\f7f4"; }
.fa-message-middle::before { content: "\e1e1"; }
.fa-message-middle-top::before { content: "\e1e2"; }
.fa-message-minus::before { content: "\f4a7"; }
.fa-message-music::before { content: "\f8af"; }
.fa-message-pen::before { content: "\f4a4"; }
.fa-message-plus::before { content: "\f4a8"; }
.fa-message-question::before { content: "\e1e3"; }
.fa-message-quote::before { content: "\e1e4"; }
.fa-message-slash::before { content: "\f4a9"; }
.fa-message-smile::before { content: "\f4aa"; }
.fa-message-sms::before { content: "\e1e5"; }
.fa-message-text::before { content: "\e1e6"; }
.fa-message-times::before { content: "\f4ab"; }
.fa-message-xmark::before { content: "\f4ab"; }
.fa-messages::before { content: "\f4b6"; }
.fa-messages-dollar::before { content: "\f652"; }
.fa-messages-question::before { content: "\e1e7"; }
.fa-messaging::before { content: "\f4a3"; }
.fa-meteor::before { content: "\f753"; }
.fa-meter::before { content: "\e1e8"; }
.fa-meter-bolt::before { content: "\e1e9"; }
.fa-meter-droplet::before { content: "\e1ea"; }
.fa-meter-fire::before { content: "\e1eb"; }
.fa-microchip::before { content: "\f2db"; }
.fa-microchip-ai::before { content: "\e1ec"; }
.fa-microphone::before { content: "\f130"; }
.fa-microphone-alt::before { content: "\f3c9"; }
.fa-microphone-alt-slash::before { content: "\f539"; }
.fa-microphone-circle::before { content: "\e116"; }
.fa-microphone-circle-alt::before { content: "\e117"; }
.fa-microphone-lines::before { content: "\f3c9"; }
.fa-microphone-lines-slash::before { content: "\f539"; }
.fa-microphone-slash::before { content: "\f131"; }
.fa-microphone-stand::before { content: "\f8cb"; }
.fa-microscope::before { content: "\f610"; }
.fa-microwave::before { content: "\e01b"; }
.fa-mill-sign::before { content: "\e1ed"; }
.fa-mind-share::before { content: "\f677"; }
.fa-minimize::before { content: "\f78c"; }
.fa-minus::before { content: "\f068"; }
.fa-minus-circle::before { content: "\f056"; }
.fa-minus-hexagon::before { content: "\f307"; }
.fa-minus-large::before { content: "\e404"; }
.fa-minus-octagon::before { content: "\f308"; }
.fa-minus-square::before { content: "\f146"; }
.fa-mistletoe::before { content: "\f7b4"; }
.fa-mitten::before { content: "\f7b5"; }
.fa-mobile::before { content: "\f3ce"; }
.fa-mobile-alt::before { content: "\f3cd"; }
.fa-mobile-android::before { content: "\f3ce"; }
.fa-mobile-android-alt::before { content: "\f3cf"; }
.fa-mobile-button::before { content: "\f10b"; }
.fa-mobile-iphone::before { content: "\e1ee"; }
.fa-mobile-notch::before { content: "\e1ee"; }
.fa-mobile-phone::before { content: "\f3ce"; }
.fa-mobile-retro::before { content: "\e527"; }
.fa-mobile-screen::before { content: "\f3cf"; }
.fa-mobile-screen-button::before { content: "\f3cd"; }
.fa-mobile-signal::before { content: "\e1ef"; }
.fa-mobile-signal-out::before { content: "\e1f0"; }
.fa-money-bill::before { content: "\f0d6"; }
.fa-money-bill-1::before { content: "\f3d1"; }
.fa-money-bill-1-wave::before { content: "\f53b"; }
.fa-money-bill-alt::before { content: "\f3d1"; }
.fa-money-bill-simple::before { content: "\e1f1"; }
.fa-money-bill-simple-wave::before { content: "\e1f2"; }
.fa-money-bill-transfer::before { content: "\e528"; }
.fa-money-bill-trend-up::before { content: "\e529"; }
.fa-money-bill-wave::before { content: "\f53a"; }
.fa-money-bill-wave-alt::before { content: "\f53b"; }
.fa-money-bill-wheat::before { content: "\e52a"; }
.fa-money-bills::before { content: "\e1f3"; }
.fa-money-bills-alt::before { content: "\e1f4"; }
.fa-money-bills-simple::before { content: "\e1f4"; }
.fa-money-check::before { content: "\f53c"; }
.fa-money-check-alt::before { content: "\f53d"; }
.fa-money-check-dollar::before { content: "\f53d"; }
.fa-money-check-dollar-pen::before { content: "\f873"; }
.fa-money-check-edit::before { content: "\f872"; }
.fa-money-check-edit-alt::before { content: "\f873"; }
.fa-money-check-pen::before { content: "\f872"; }
.fa-money-from-bracket::before { content: "\e312"; }
.fa-money-simple-from-bracket::before { content: "\e313"; }
.fa-monitor-heart-rate::before { content: "\f611"; }
.fa-monitor-waveform::before { content: "\f611"; }
.fa-monkey::before { content: "\f6fb"; }
.fa-monument::before { content: "\f5a6"; }
.fa-moon::before { content: "\f186"; }
.fa-moon-cloud::before { content: "\f754"; }
.fa-moon-over-sun::before { content: "\f74a"; }
.fa-moon-stars::before { content: "\f755"; }
.fa-moped::before { content: "\e3b9"; }
.fa-mortar-board::before { content: "\f19d"; }
.fa-mortar-pestle::before { content: "\f5a7"; }
.fa-mosque::before { content: "\f678"; }
.fa-mosquito::before { content: "\e52b"; }
.fa-mosquito-net::before { content: "\e52c"; }
.fa-motorcycle::before { content: "\f21c"; }
.fa-mound::before { content: "\e52d"; }
.fa-mountain::before { content: "\f6fc"; }
.fa-mountain-city::before { content: "\e52e"; }
.fa-mountain-sun::before { content: "\e52f"; }
.fa-mountains::before { content: "\f6fd"; }
.fa-mouse::before { content: "\f8cc"; }
.fa-mouse-alt::before { content: "\f8cd"; }
.fa-mouse-field::before { content: "\e5a8"; }
.fa-mouse-pointer::before { content: "\f245"; }
.fa-mp3-player::before { content: "\f8ce"; }
.fa-mug::before { content: "\f874"; }
.fa-mug-hot::before { content: "\f7b6"; }
.fa-mug-marshmallows::before { content: "\f7b7"; }
.fa-mug-saucer::before { content: "\f0f4"; }
.fa-mug-tea::before { content: "\f875"; }
.fa-mug-tea-saucer::before { content: "\e1f5"; }
.fa-multiply::before { content: "\f00d"; }
.fa-museum::before { content: "\f19c"; }
.fa-mushroom::before { content: "\e425"; }
.fa-music::before { content: "\f001"; }
.fa-music-alt::before { content: "\f8cf"; }
.fa-music-alt-slash::before { content: "\f8d0"; }
.fa-music-magnifying-glass::before { content: "\e662"; }
.fa-music-note::before { content: "\f8cf"; }
.fa-music-note-slash::before { content: "\f8d0"; }
.fa-music-slash::before { content: "\f8d1"; }
.fa-mustache::before { content: "\e5bc"; }
.fa-n::before { content: "\4e"; }
.fa-naira-sign::before { content: "\e1f6"; }
.fa-narwhal::before { content: "\f6fe"; }
.fa-navicon::before { content: "\f0c9"; }
.fa-nesting-dolls::before { content: "\e3ba"; }
.fa-network-wired::before { content: "\f6ff"; }
.fa-neuter::before { content: "\f22c"; }
.fa-newspaper::before { content: "\f1ea"; }
.fa-nfc::before { content: "\e1f7"; }
.fa-nfc-lock::before { content: "\e1f8"; }
.fa-nfc-magnifying-glass::before { content: "\e1f9"; }
.fa-nfc-pen::before { content: "\e1fa"; }
.fa-nfc-signal::before { content: "\e1fb"; }
.fa-nfc-slash::before { content: "\e1fc"; }
.fa-nfc-symbol::before { content: "\e531"; }
.fa-nfc-trash::before { content: "\e1fd"; }
.fa-nigiri::before { content: "\e48a"; }
.fa-nose::before { content: "\e5bd"; }
.fa-not-equal::before { content: "\f53e"; }
.fa-notdef::before { content: "\e1fe"; }
.fa-note::before { content: "\e1ff"; }
.fa-note-medical::before { content: "\e200"; }
.fa-note-sticky::before { content: "\f249"; }
.fa-notebook::before { content: "\e201"; }
.fa-notes::before { content: "\e202"; }
.fa-notes-medical::before { content: "\f481"; }
.fa-o::before { content: "\4f"; }
.fa-object-exclude::before { content: "\e49c"; }
.fa-object-group::before { content: "\f247"; }
.fa-object-intersect::before { content: "\e49d"; }
.fa-object-subtract::before { content: "\e49e"; }
.fa-object-ungroup::before { content: "\f248"; }
.fa-object-union::before { content: "\e49f"; }
.fa-objects-align-bottom::before { content: "\e3bb"; }
.fa-objects-align-center-horizontal::before { content: "\e3bc"; }
.fa-objects-align-center-vertical::before { content: "\e3bd"; }
.fa-objects-align-left::before { content: "\e3be"; }
.fa-objects-align-right::before { content: "\e3bf"; }
.fa-objects-align-top::before { content: "\e3c0"; }
.fa-objects-column::before { content: "\e3c1"; }
.fa-octagon::before { content: "\f306"; }
.fa-octagon-check::before { content: "\e426"; }
.fa-octagon-divide::before { content: "\e203"; }
.fa-octagon-exclamation::before { content: "\e204"; }
.fa-octagon-minus::before { content: "\f308"; }
.fa-octagon-plus::before { content: "\f301"; }
.fa-octagon-xmark::before { content: "\f2f0"; }
.fa-octopus::before { content: "\e688"; }
.fa-oil-can::before { content: "\f613"; }
.fa-oil-can-drip::before { content: "\e205"; }
.fa-oil-temp::before { content: "\f614"; }
.fa-oil-temperature::before { content: "\f614"; }
.fa-oil-well::before { content: "\e532"; }
.fa-olive::before { content: "\e316"; }
.fa-olive-branch::before { content: "\e317"; }
.fa-om::before { content: "\f679"; }
.fa-omega::before { content: "\f67a"; }
.fa-onion::before { content: "\e427"; }
.fa-option::before { content: "\e318"; }
.fa-ornament::before { content: "\f7b8"; }
.fa-otter::before { content: "\f700"; }
.fa-outdent::before { content: "\f03b"; }
.fa-outlet::before { content: "\e01c"; }
.fa-oven::before { content: "\e01d"; }
.fa-overline::before { content: "\f876"; }
.fa-p::before { content: "\50"; }
.fa-page::before { content: "\e428"; }
.fa-page-break::before { content: "\f877"; }
.fa-page-caret-down::before { content: "\e429"; }
.fa-page-caret-up::before { content: "\e42a"; }
.fa-pager::before { content: "\f815"; }
.fa-paint-brush::before { content: "\f1fc"; }
.fa-paint-brush-alt::before { content: "\f5a9"; }
.fa-paint-brush-fine::before { content: "\f5a9"; }
.fa-paint-roller::before { content: "\f5aa"; }
.fa-paintbrush::before { content: "\f1fc"; }
.fa-paintbrush-alt::before { content: "\f5a9"; }
.fa-paintbrush-fine::before { content: "\f5a9"; }
.fa-paintbrush-pencil::before { content: "\e206"; }
.fa-palette::before { content: "\f53f"; }
.fa-palette-boxes::before { content: "\f483"; }
.fa-pallet::before { content: "\f482"; }
.fa-pallet-alt::before { content: "\f483"; }
.fa-pallet-box::before { content: "\e208"; }
.fa-pallet-boxes::before { content: "\f483"; }
.fa-pan-food::before { content: "\e42b"; }
.fa-pan-frying::before { content: "\e42c"; }
.fa-pancakes::before { content: "\e42d"; }
.fa-panel-ews::before { content: "\e42e"; }
.fa-panel-fire::before { content: "\e42f"; }
.fa-panorama::before { content: "\e209"; }
.fa-paper-plane::before { content: "\f1d8"; }
.fa-paper-plane-alt::before { content: "\e20a"; }
.fa-paper-plane-top::before { content: "\e20a"; }
.fa-paperclip::before { content: "\f0c6"; }
.fa-paperclip-vertical::before { content: "\e3c2"; }
.fa-parachute-box::before { content: "\f4cd"; }
.fa-paragraph::before { content: "\f1dd"; }
.fa-paragraph-left::before { content: "\f878"; }
.fa-paragraph-rtl::before { content: "\f878"; }
.fa-parentheses::before { content: "\e0c5"; }
.fa-parenthesis::before { content: "\28"; }
.fa-parking::before { content: "\f540"; }
.fa-parking-circle::before { content: "\f615"; }
.fa-parking-circle-slash::before { content: "\f616"; }
.fa-parking-slash::before { content: "\f617"; }
.fa-party-back::before { content: "\e45c"; }
.fa-party-bell::before { content: "\e31a"; }
.fa-party-horn::before { content: "\e31b"; }
.fa-passport::before { content: "\f5ab"; }
.fa-pastafarianism::before { content: "\f67b"; }
.fa-paste::before { content: "\f0ea"; }
.fa-pause::before { content: "\f04c"; }
.fa-pause-circle::before { content: "\f28b"; }
.fa-paw::before { content: "\f1b0"; }
.fa-paw-alt::before { content: "\f701"; }
.fa-paw-claws::before { content: "\f702"; }
.fa-paw-simple::before { content: "\f701"; }
.fa-peace::before { content: "\f67c"; }
.fa-peach::before { content: "\e20b"; }
.fa-peanut::before { content: "\e430"; }
.fa-peanuts::before { content: "\e431"; }
.fa-peapod::before { content: "\e31c"; }
.fa-pear::before { content: "\e20c"; }
.fa-pedestal::before { content: "\e20d"; }
.fa-pegasus::before { content: "\f703"; }
.fa-pen::before { content: "\f304"; }
.fa-pen-alt::before { content: "\f305"; }
.fa-pen-alt-slash::before { content: "\e20f"; }
.fa-pen-circle::before { content: "\e20e"; }
.fa-pen-clip::before { content: "\f305"; }
.fa-pen-clip-slash::before { content: "\e20f"; }
.fa-pen-fancy::before { content: "\f5ac"; }
.fa-pen-fancy-slash::before { content: "\e210"; }
.fa-pen-field::before { content: "\e211"; }
.fa-pen-line::before { content: "\e212"; }
.fa-pen-nib::before { content: "\f5ad"; }
.fa-pen-nib-slash::before { content: "\e4a1"; }
.fa-pen-paintbrush::before { content: "\f618"; }
.fa-pen-ruler::before { content: "\f5ae"; }
.fa-pen-slash::before { content: "\e213"; }
.fa-pen-square::before { content: "\f14b"; }
.fa-pen-swirl::before { content: "\e214"; }
.fa-pen-to-square::before { content: "\f044"; }
.fa-pencil::before { content: "\f303"; }
.fa-pencil-alt::before { content: "\f303"; }
.fa-pencil-mechanical::before { content: "\e5ca"; }
.fa-pencil-paintbrush::before { content: "\f618"; }
.fa-pencil-ruler::before { content: "\f5ae"; }
.fa-pencil-slash::before { content: "\e215"; }
.fa-pencil-square::before { content: "\f14b"; }
.fa-pennant::before { content: "\f456"; }
.fa-people::before { content: "\e216"; }
.fa-people-arrows::before { content: "\e068"; }
.fa-people-arrows-left-right::before { content: "\e068"; }
.fa-people-carry::before { content: "\f4ce"; }
.fa-people-carry-box::before { content: "\f4ce"; }
.fa-people-dress::before { content: "\e217"; }
.fa-people-dress-simple::before { content: "\e218"; }
.fa-people-group::before { content: "\e533"; }
.fa-people-line::before { content: "\e534"; }
.fa-people-pants::before { content: "\e219"; }
.fa-people-pants-simple::before { content: "\e21a"; }
.fa-people-pulling::before { content: "\e535"; }
.fa-people-robbery::before { content: "\e536"; }
.fa-people-roof::before { content: "\e537"; }
.fa-people-simple::before { content: "\e21b"; }
.fa-pepper::before { content: "\e432"; }
.fa-pepper-hot::before { content: "\f816"; }
.fa-percent::before { content: "\25"; }
.fa-percentage::before { content: "\25"; }
.fa-period::before { content: "\2e"; }
.fa-person::before { content: "\f183"; }
.fa-person-arrow-down-to-line::before { content: "\e538"; }
.fa-person-arrow-up-from-line::before { content: "\e539"; }
.fa-person-biking::before { content: "\f84a"; }
.fa-person-biking-mountain::before { content: "\f84b"; }
.fa-person-booth::before { content: "\f756"; }
.fa-person-breastfeeding::before { content: "\e53a"; }
.fa-person-burst::before { content: "\e53b"; }
.fa-person-cane::before { content: "\e53c"; }
.fa-person-carry::before { content: "\f4cf"; }
.fa-person-carry-box::before { content: "\f4cf"; }
.fa-person-chalkboard::before { content: "\e53d"; }
.fa-person-circle-check::before { content: "\e53e"; }
.fa-person-circle-exclamation::before { content: "\e53f"; }
.fa-person-circle-minus::before { content: "\e540"; }
.fa-person-circle-plus::before { content: "\e541"; }
.fa-person-circle-question::before { content: "\e542"; }
.fa-person-circle-xmark::before { content: "\e543"; }
.fa-person-digging::before { content: "\f85e"; }
.fa-person-dolly::before { content: "\f4d0"; }
.fa-person-dolly-empty::before { content: "\f4d1"; }
.fa-person-dots-from-line::before { content: "\f470"; }
.fa-person-dress::before { content: "\f182"; }
.fa-person-dress-burst::before { content: "\e544"; }
.fa-person-dress-fairy::before { content: "\e607"; }
.fa-person-dress-simple::before { content: "\e21c"; }
.fa-person-drowning::before { content: "\e545"; }
.fa-person-fairy::before { content: "\e608"; }
.fa-person-falling::before { content: "\e546"; }
.fa-person-falling-burst::before { content: "\e547"; }
.fa-person-from-portal::before { content: "\e023"; }
.fa-person-half-dress::before { content: "\e548"; }
.fa-person-harassing::before { content: "\e549"; }
.fa-person-hiking::before { content: "\f6ec"; }
.fa-person-military-pointing::before { content: "\e54a"; }
.fa-person-military-rifle::before { content: "\e54b"; }
.fa-person-military-to-person::before { content: "\e54c"; }
.fa-person-pinball::before { content: "\e21d"; }
.fa-person-praying::before { content: "\f683"; }
.fa-person-pregnant::before { content: "\e31e"; }
.fa-person-rays::before { content: "\e54d"; }
.fa-person-rifle::before { content: "\e54e"; }
.fa-person-running::before { content: "\f70c"; }
.fa-person-running-fast::before { content: "\e5ff"; }
.fa-person-seat::before { content: "\e21e"; }
.fa-person-seat-reclined::before { content: "\e21f"; }
.fa-person-shelter::before { content: "\e54f"; }
.fa-person-sign::before { content: "\f757"; }
.fa-person-simple::before { content: "\e220"; }
.fa-person-skating::before { content: "\f7c5"; }
.fa-person-ski-jumping::before { content: "\f7c7"; }
.fa-person-ski-lift::before { content: "\f7c8"; }
.fa-person-skiing::before { content: "\f7c9"; }
.fa-person-skiing-nordic::before { content: "\f7ca"; }
.fa-person-sledding::before { content: "\f7cb"; }
.fa-person-snowboarding::before { content: "\f7ce"; }
.fa-person-snowmobiling::before { content: "\f7d1"; }
.fa-person-swimming::before { content: "\f5c4"; }
.fa-person-through-window::before { content: "\e5a9"; }
.fa-person-to-door::before { content: "\e433"; }
.fa-person-to-portal::before { content: "\e022"; }
.fa-person-walking::before { content: "\f554"; }
.fa-person-walking-arrow-loop-left::before { content: "\e551"; }
.fa-person-walking-arrow-right::before { content: "\e552"; }
.fa-person-walking-dashed-line-arrow-right::before { content: "\e553"; }
.fa-person-walking-luggage::before { content: "\e554"; }
.fa-person-walking-with-cane::before { content: "\f29d"; }
.fa-peseta-sign::before { content: "\e221"; }
.fa-peso-sign::before { content: "\e222"; }
.fa-phone::before { content: "\f095"; }
.fa-phone-alt::before { content: "\f879"; }
.fa-phone-arrow-down::before { content: "\e223"; }
.fa-phone-arrow-down-left::before { content: "\e223"; }
.fa-phone-arrow-right::before { content: "\e5be"; }
.fa-phone-arrow-up::before { content: "\e224"; }
.fa-phone-arrow-up-right::before { content: "\e224"; }
.fa-phone-circle::before { content: "\e11b"; }
.fa-phone-circle-alt::before { content: "\e11c"; }
.fa-phone-circle-down::before { content: "\e11d"; }
.fa-phone-flip::before { content: "\f879"; }
.fa-phone-hangup::before { content: "\e225"; }
.fa-phone-incoming::before { content: "\e223"; }
.fa-phone-intercom::before { content: "\e434"; }
.fa-phone-laptop::before { content: "\f87a"; }
.fa-phone-missed::before { content: "\e226"; }
.fa-phone-office::before { content: "\f67d"; }
.fa-phone-outgoing::before { content: "\e224"; }
.fa-phone-plus::before { content: "\f4d2"; }
.fa-phone-rotary::before { content: "\f8d3"; }
.fa-phone-slash::before { content: "\f3dd"; }
.fa-phone-square::before { content: "\f098"; }
.fa-phone-square-alt::before { content: "\f87b"; }
.fa-phone-square-down::before { content: "\e27a"; }
.fa-phone-volume::before { content: "\f2a0"; }
.fa-phone-xmark::before { content: "\e227"; }
.fa-photo-film::before { content: "\f87c"; }
.fa-photo-film-music::before { content: "\e228"; }
.fa-photo-video::before { content: "\f87c"; }
.fa-pi::before { content: "\f67e"; }
.fa-piano::before { content: "\f8d4"; }
.fa-piano-keyboard::before { content: "\f8d5"; }
.fa-pickaxe::before { content: "\e5bf"; }
.fa-pickleball::before { content: "\e435"; }
.fa-pie::before { content: "\f705"; }
.fa-pie-chart::before { content: "\f200"; }
.fa-pig::before { content: "\f706"; }
.fa-piggy-bank::before { content: "\f4d3"; }
.fa-pills::before { content: "\f484"; }
.fa-pinata::before { content: "\e3c3"; }
.fa-pinball::before { content: "\e229"; }
.fa-pineapple::before { content: "\e31f"; }
.fa-ping-pong-paddle-ball::before { content: "\f45d"; }
.fa-pipe::before { content: "\7c"; }
.fa-pipe-circle-check::before { content: "\e436"; }
.fa-pipe-collar::before { content: "\e437"; }
.fa-pipe-section::before { content: "\e438"; }
.fa-pipe-smoking::before { content: "\e3c4"; }
.fa-pipe-valve::before { content: "\e439"; }
.fa-pizza::before { content: "\f817"; }
.fa-pizza-slice::before { content: "\f818"; }
.fa-place-of-worship::before { content: "\f67f"; }
.fa-plane::before { content: "\f072"; }
.fa-plane-alt::before { content: "\f3de"; }
.fa-plane-arrival::before { content: "\f5af"; }
.fa-plane-circle-check::before { content: "\e555"; }
.fa-plane-circle-exclamation::before { content: "\e556"; }
.fa-plane-circle-xmark::before { content: "\e557"; }
.fa-plane-departure::before { content: "\f5b0"; }
.fa-plane-engines::before { content: "\f3de"; }
.fa-plane-lock::before { content: "\e558"; }
.fa-plane-prop::before { content: "\e22b"; }
.fa-plane-slash::before { content: "\e069"; }
.fa-plane-tail::before { content: "\e22c"; }
.fa-plane-up::before { content: "\e22d"; }
.fa-plane-up-slash::before { content: "\e22e"; }
.fa-planet-moon::before { content: "\e01f"; }
.fa-planet-ringed::before { content: "\e020"; }
.fa-plant-wilt::before { content: "\e5aa"; }
.fa-plate-utensils::before { content: "\e43b"; }
.fa-plate-wheat::before { content: "\e55a"; }
.fa-play::before { content: "\f04b"; }
.fa-play-circle::before { content: "\f144"; }
.fa-play-pause::before { content: "\e22f"; }
.fa-plug::before { content: "\f1e6"; }
.fa-plug-circle-bolt::before { content: "\e55b"; }
.fa-plug-circle-check::before { content: "\e55c"; }
.fa-plug-circle-exclamation::before { content: "\e55d"; }
.fa-plug-circle-minus::before { content: "\e55e"; }
.fa-plug-circle-plus::before { content: "\e55f"; }
.fa-plug-circle-xmark::before { content: "\e560"; }
.fa-plus::before { content: "\2b"; }
.fa-plus-circle::before { content: "\f055"; }
.fa-plus-hexagon::before { content: "\f300"; }
.fa-plus-large::before { content: "\e59e"; }
.fa-plus-minus::before { content: "\e43c"; }
.fa-plus-octagon::before { content: "\f301"; }
.fa-plus-square::before { content: "\f0fe"; }
.fa-podcast::before { content: "\f2ce"; }
.fa-podium::before { content: "\f680"; }
.fa-podium-star::before { content: "\f758"; }
.fa-police-box::before { content: "\e021"; }
.fa-poll::before { content: "\f681"; }
.fa-poll-h::before { content: "\f682"; }
.fa-poll-people::before { content: "\f759"; }
.fa-pompebled::before { content: "\e43d"; }
.fa-poo::before { content: "\f2fe"; }
.fa-poo-bolt::before { content: "\f75a"; }
.fa-poo-storm::before { content: "\f75a"; }
.fa-pool-8-ball::before { content: "\e3c5"; }
.fa-poop::before { content: "\f619"; }
.fa-popcorn::before { content: "\f819"; }
.fa-popsicle::before { content: "\e43e"; }
.fa-portal-enter::before { content: "\e022"; }
.fa-portal-exit::before { content: "\e023"; }
.fa-portrait::before { content: "\f3e0"; }
.fa-pot-food::before { content: "\e43f"; }
.fa-potato::before { content: "\e440"; }
.fa-pound-sign::before { content: "\f154"; }
.fa-power-off::before { content: "\f011"; }
.fa-pray::before { content: "\f683"; }
.fa-praying-hands::before { content: "\f684"; }
.fa-prescription::before { content: "\f5b1"; }
.fa-prescription-bottle::before { content: "\f485"; }
.fa-prescription-bottle-alt::before { content: "\f486"; }
.fa-prescription-bottle-medical::before { content: "\f486"; }
.fa-prescription-bottle-pill::before { content: "\e5c0"; }
.fa-presentation::before { content: "\f685"; }
.fa-presentation-screen::before { content: "\f685"; }
.fa-pretzel::before { content: "\e441"; }
.fa-print::before { content: "\f02f"; }
.fa-print-magnifying-glass::before { content: "\f81a"; }
.fa-print-search::before { content: "\f81a"; }
.fa-print-slash::before { content: "\f686"; }
.fa-pro::before { content: "\e235"; }
.fa-procedures::before { content: "\f487"; }
.fa-project-diagram::before { content: "\f542"; }
.fa-projector::before { content: "\f8d6"; }
.fa-pump::before { content: "\e442"; }
.fa-pump-medical::before { content: "\e06a"; }
.fa-pump-soap::before { content: "\e06b"; }
.fa-pumpkin::before { content: "\f707"; }
.fa-puzzle::before { content: "\e443"; }
.fa-puzzle-piece::before { content: "\f12e"; }
.fa-puzzle-piece-alt::before { content: "\e231"; }
.fa-puzzle-piece-simple::before { content: "\e231"; }
.fa-q::before { content: "\51"; }
.fa-qrcode::before { content: "\f029"; }
.fa-question::before { content: "\3f"; }
.fa-question-circle::before { content: "\f059"; }
.fa-question-square::before { content: "\f2fd"; }
.fa-quidditch::before { content: "\f458"; }
.fa-quidditch-broom-ball::before { content: "\f458"; }
.fa-quote-left::before { content: "\f10d"; }
.fa-quote-left-alt::before { content: "\f10d"; }
.fa-quote-right::before { content: "\f10e"; }
.fa-quote-right-alt::before { content: "\f10e"; }
.fa-quotes::before { content: "\e234"; }
.fa-quran::before { content: "\f687"; }
.fa-r::before { content: "\52"; }
.fa-rabbit::before { content: "\f708"; }
.fa-rabbit-fast::before { content: "\f709"; }
.fa-rabbit-running::before { content: "\f709"; }
.fa-raccoon::before { content: "\e613"; }
.fa-racquet::before { content: "\f45a"; }
.fa-radar::before { content: "\e024"; }
.fa-radiation::before { content: "\f7b9"; }
.fa-radiation-alt::before { content: "\f7ba"; }
.fa-radio::before { content: "\f8d7"; }
.fa-radio-alt::before { content: "\f8d8"; }
.fa-radio-tuner::before { content: "\f8d8"; }
.fa-rainbow::before { content: "\f75b"; }
.fa-raindrops::before { content: "\f75c"; }
.fa-ram::before { content: "\f70a"; }
.fa-ramp-loading::before { content: "\f4d4"; }
.fa-random::before { content: "\f074"; }
.fa-ranking-star::before { content: "\e561"; }
.fa-raygun::before { content: "\e025"; }
.fa-receipt::before { content: "\f543"; }
.fa-record-vinyl::before { content: "\f8d9"; }
.fa-rectangle::before { content: "\f2fa"; }
.fa-rectangle-ad::before { content: "\f641"; }
.fa-rectangle-barcode::before { content: "\f463"; }
.fa-rectangle-code::before { content: "\e322"; }
.fa-rectangle-hd::before { content: "\e1ae"; }
.fa-rectangle-history::before { content: "\e4a2"; }
.fa-rectangle-history-circle-plus::before { content: "\e4a3"; }
.fa-rectangle-history-circle-user::before { content: "\e4a4"; }
.fa-rectangle-landscape::before { content: "\f2fa"; }
.fa-rectangle-list::before { content: "\f022"; }
.fa-rectangle-portrait::before { content: "\f2fb"; }
.fa-rectangle-pro::before { content: "\e235"; }
.fa-rectangle-sd::before { content: "\e28a"; }
.fa-rectangle-terminal::before { content: "\e236"; }
.fa-rectangle-times::before { content: "\f410"; }
.fa-rectangle-vertical::before { content: "\f2fb"; }
.fa-rectangle-vertical-history::before { content: "\e237"; }
.fa-rectangle-wide::before { content: "\f2fc"; }
.fa-rectangle-xmark::before { content: "\f410"; }
.fa-rectangles-mixed::before { content: "\e323"; }
.fa-recycle::before { content: "\f1b8"; }
.fa-redo::before { content: "\f01e"; }
.fa-redo-alt::before { content: "\f2f9"; }
.fa-reel::before { content: "\e238"; }
.fa-reflect-both::before { content: "\e66f"; }
.fa-reflect-horizontal::before { content: "\e664"; }
.fa-reflect-vertical::before { content: "\e665"; }
.fa-refresh::before { content: "\f021"; }
.fa-refrigerator::before { content: "\e026"; }
.fa-registered::before { content: "\f25d"; }
.fa-remove::before { content: "\f00d"; }
.fa-remove-format::before { content: "\f87d"; }
.fa-reorder::before { content: "\f550"; }
.fa-repeat::before { content: "\f363"; }
.fa-repeat-1::before { content: "\f365"; }
.fa-repeat-1-alt::before { content: "\f366"; }
.fa-repeat-alt::before { content: "\f364"; }
.fa-reply::before { content: "\f3e5"; }
.fa-reply-all::before { content: "\f122"; }
.fa-reply-clock::before { content: "\e239"; }
.fa-reply-time::before { content: "\e239"; }
.fa-republican::before { content: "\f75e"; }
.fa-restroom::before { content: "\f7bd"; }
.fa-restroom-simple::before { content: "\e23a"; }
.fa-retweet::before { content: "\f079"; }
.fa-retweet-alt::before { content: "\f361"; }
.fa-rhombus::before { content: "\e23b"; }
.fa-ribbon::before { content: "\f4d6"; }
.fa-right::before { content: "\f356"; }
.fa-right-from-bracket::before { content: "\f2f5"; }
.fa-right-from-line::before { content: "\f347"; }
.fa-right-left::before { content: "\f362"; }
.fa-right-left-large::before { content: "\e5e1"; }
.fa-right-long::before { content: "\f30b"; }
.fa-right-long-to-line::before { content: "\e444"; }
.fa-right-to-bracket::before { content: "\f2f6"; }
.fa-right-to-line::before { content: "\f34c"; }
.fa-ring::before { content: "\f70b"; }
.fa-ring-diamond::before { content: "\e5ab"; }
.fa-rings-wedding::before { content: "\f81b"; }
.fa-rmb::before { content: "\f157"; }
.fa-road::before { content: "\f018"; }
.fa-road-barrier::before { content: "\e562"; }
.fa-road-bridge::before { content: "\e563"; }
.fa-road-circle-check::before { content: "\e564"; }
.fa-road-circle-exclamation::before { content: "\e565"; }
.fa-road-circle-xmark::before { content: "\e566"; }
.fa-road-lock::before { content: "\e567"; }
.fa-road-spikes::before { content: "\e568"; }
.fa-robot::before { content: "\f544"; }
.fa-robot-astromech::before { content: "\e2d2"; }
.fa-rocket::before { content: "\f135"; }
.fa-rocket-launch::before { content: "\e027"; }
.fa-rod-asclepius::before { content: "\e579"; }
.fa-rod-snake::before { content: "\e579"; }
.fa-roller-coaster::before { content: "\e324"; }
.fa-rotate::before { content: "\f2f1"; }
.fa-rotate-back::before { content: "\f2ea"; }
.fa-rotate-backward::before { content: "\f2ea"; }
.fa-rotate-exclamation::before { content: "\e23c"; }
.fa-rotate-forward::before { content: "\f2f9"; }
.fa-rotate-left::before { content: "\f2ea"; }
.fa-rotate-reverse::before { content: "\e631"; }
.fa-rotate-right::before { content: "\f2f9"; }
.fa-rouble::before { content: "\f158"; }
.fa-route::before { content: "\f4d7"; }
.fa-route-highway::before { content: "\f61a"; }
.fa-route-interstate::before { content: "\f61b"; }
.fa-router::before { content: "\f8da"; }
.fa-rows::before { content: "\e292"; }
.fa-rss::before { content: "\f09e"; }
.fa-rss-square::before { content: "\f143"; }
.fa-rub::before { content: "\f158"; }
.fa-ruble::before { content: "\f158"; }
.fa-ruble-sign::before { content: "\f158"; }
.fa-rug::before { content: "\e569"; }
.fa-rugby-ball::before { content: "\e3c6"; }
.fa-ruler::before { content: "\f545"; }
.fa-ruler-combined::before { content: "\f546"; }
.fa-ruler-horizontal::before { content: "\f547"; }
.fa-ruler-triangle::before { content: "\f61c"; }
.fa-ruler-vertical::before { content: "\f548"; }
.fa-running::before { content: "\f70c"; }
.fa-rupee::before { content: "\f156"; }
.fa-rupee-sign::before { content: "\f156"; }
.fa-rupiah-sign::before { content: "\e23d"; }
.fa-rv::before { content: "\f7be"; }
.fa-s::before { content: "\53"; }
.fa-sack::before { content: "\f81c"; }
.fa-sack-dollar::before { content: "\f81d"; }
.fa-sack-xmark::before { content: "\e56a"; }
.fa-sad-cry::before { content: "\f5b3"; }
.fa-sad-tear::before { content: "\f5b4"; }
.fa-sailboat::before { content: "\e445"; }
.fa-salad::before { content: "\f81e"; }
.fa-salt-shaker::before { content: "\e446"; }
.fa-sandwich::before { content: "\f81f"; }
.fa-satellite::before { content: "\f7bf"; }
.fa-satellite-dish::before { content: "\f7c0"; }
.fa-sausage::before { content: "\f820"; }
.fa-save::before { content: "\f0c7"; }
.fa-save-circle-arrow-right::before { content: "\e180"; }
.fa-save-circle-xmark::before { content: "\e181"; }
.fa-save-times::before { content: "\e181"; }
.fa-sax-hot::before { content: "\f8db"; }
.fa-saxophone::before { content: "\f8dc"; }
.fa-saxophone-fire::before { content: "\f8db"; }
.fa-scale-balanced::before { content: "\f24e"; }
.fa-scale-unbalanced::before { content: "\f515"; }
.fa-scale-unbalanced-flip::before { content: "\f516"; }
.fa-scalpel::before { content: "\f61d"; }
.fa-scalpel-line-dashed::before { content: "\f61e"; }
.fa-scalpel-path::before { content: "\f61e"; }
.fa-scanner::before { content: "\f488"; }
.fa-scanner-gun::before { content: "\f488"; }
.fa-scanner-image::before { content: "\f8f3"; }
.fa-scanner-keyboard::before { content: "\f489"; }
.fa-scanner-touchscreen::before { content: "\f48a"; }
.fa-scarecrow::before { content: "\f70d"; }
.fa-scarf::before { content: "\f7c1"; }
.fa-school::before { content: "\f549"; }
.fa-school-circle-check::before { content: "\e56b"; }
.fa-school-circle-exclamation::before { content: "\e56c"; }
.fa-school-circle-xmark::before { content: "\e56d"; }
.fa-school-flag::before { content: "\e56e"; }
.fa-school-lock::before { content: "\e56f"; }
.fa-scissors::before { content: "\f0c4"; }
.fa-screen-users::before { content: "\f63d"; }
.fa-screencast::before { content: "\e23e"; }
.fa-screenshot::before { content: "\e0da"; }
.fa-screwdriver::before { content: "\f54a"; }
.fa-screwdriver-wrench::before { content: "\f7d9"; }
.fa-scribble::before { content: "\e23f"; }
.fa-scroll::before { content: "\f70e"; }
.fa-scroll-old::before { content: "\f70f"; }
.fa-scroll-ribbon::before { content: "\f5ea"; }
.fa-scroll-torah::before { content: "\f6a0"; }
.fa-scrubber::before { content: "\f2f8"; }
.fa-scythe::before { content: "\f710"; }
.fa-sd-card::before { content: "\f7c2"; }
.fa-sd-cards::before { content: "\e240"; }
.fa-seal::before { content: "\e241"; }
.fa-seal-exclamation::before { content: "\e242"; }
.fa-seal-question::before { content: "\e243"; }
.fa-search::before { content: "\f002"; }
.fa-search-dollar::before { content: "\f688"; }
.fa-search-location::before { content: "\f689"; }
.fa-search-minus::before { content: "\f010"; }
.fa-search-plus::before { content: "\f00e"; }
.fa-seat-airline::before { content: "\e244"; }
.fa-section::before { content: "\e447"; }
.fa-seedling::before { content: "\f4d8"; }
.fa-semicolon::before { content: "\3b"; }
.fa-send::before { content: "\e20a"; }
.fa-send-back::before { content: "\f87e"; }
.fa-send-backward::before { content: "\f87f"; }
.fa-sensor::before { content: "\e028"; }
.fa-sensor-alert::before { content: "\e029"; }
.fa-sensor-cloud::before { content: "\e02c"; }
.fa-sensor-fire::before { content: "\e02a"; }
.fa-sensor-on::before { content: "\e02b"; }
.fa-sensor-smoke::before { content: "\e02c"; }
.fa-sensor-triangle-exclamation::before { content: "\e029"; }
.fa-server::before { content: "\f233"; }
.fa-shapes::before { content: "\f61f"; }
.fa-share::before { content: "\f064"; }
.fa-share-all::before { content: "\f367"; }
.fa-share-alt::before { content: "\f1e0"; }
.fa-share-alt-square::before { content: "\f1e1"; }
.fa-share-from-square::before { content: "\f14d"; }
.fa-share-nodes::before { content: "\f1e0"; }
.fa-share-square::before { content: "\f14d"; }
.fa-sheep::before { content: "\f711"; }
.fa-sheet-plastic::before { content: "\e571"; }
.fa-shekel::before { content: "\f20b"; }
.fa-shekel-sign::before { content: "\f20b"; }
.fa-shelves::before { content: "\f480"; }
.fa-shelves-empty::before { content: "\e246"; }
.fa-sheqel::before { content: "\f20b"; }
.fa-sheqel-sign::before { content: "\f20b"; }
.fa-shield::before { content: "\f132"; }
.fa-shield-alt::before { content: "\f3ed"; }
.fa-shield-blank::before { content: "\f132"; }
.fa-shield-cat::before { content: "\e572"; }
.fa-shield-check::before { content: "\f2f7"; }
.fa-shield-cross::before { content: "\f712"; }
.fa-shield-dog::before { content: "\e573"; }
.fa-shield-exclamation::before { content: "\e247"; }
.fa-shield-halved::before { content: "\f3ed"; }
.fa-shield-heart::before { content: "\e574"; }
.fa-shield-keyhole::before { content: "\e248"; }
.fa-shield-minus::before { content: "\e249"; }
.fa-shield-plus::before { content: "\e24a"; }
.fa-shield-quartered::before { content: "\e575"; }
.fa-shield-slash::before { content: "\e24b"; }
.fa-shield-times::before { content: "\e24c"; }
.fa-shield-virus::before { content: "\e06c"; }
.fa-shield-xmark::before { content: "\e24c"; }
.fa-ship::before { content: "\f21a"; }
.fa-shipping-fast::before { content: "\f48b"; }
.fa-shipping-timed::before { content: "\f48c"; }
.fa-shirt::before { content: "\f553"; }
.fa-shirt-long-sleeve::before { content: "\e3c7"; }
.fa-shirt-running::before { content: "\e3c8"; }
.fa-shirt-tank-top::before { content: "\e3c9"; }
.fa-shish-kebab::before { content: "\f821"; }
.fa-shoe-prints::before { content: "\f54b"; }
.fa-shop::before { content: "\f54f"; }
.fa-shop-lock::before { content: "\e4a5"; }
.fa-shop-slash::before { content: "\e070"; }
.fa-shopping-bag::before { content: "\f290"; }
.fa-shopping-basket::before { content: "\f291"; }
.fa-shopping-basket-alt::before { content: "\e0af"; }
.fa-shopping-cart::before { content: "\f07a"; }
.fa-shortcake::before { content: "\e3e5"; }
.fa-shovel::before { content: "\f713"; }
.fa-shovel-snow::before { content: "\f7c3"; }
.fa-shower::before { content: "\f2cc"; }
.fa-shower-alt::before { content: "\e24d"; }
.fa-shower-down::before { content: "\e24d"; }
.fa-shredder::before { content: "\f68a"; }
.fa-shrimp::before { content: "\e448"; }
.fa-shuffle::before { content: "\f074"; }
.fa-shutters::before { content: "\e449"; }
.fa-shuttle-space::before { content: "\f197"; }
.fa-shuttle-van::before { content: "\f5b6"; }
.fa-shuttlecock::before { content: "\f45b"; }
.fa-sickle::before { content: "\f822"; }
.fa-sidebar::before { content: "\e24e"; }
.fa-sidebar-flip::before { content: "\e24f"; }
.fa-sigma::before { content: "\f68b"; }
.fa-sign::before { content: "\f4d9"; }
.fa-sign-hanging::before { content: "\f4d9"; }
.fa-sign-in::before { content: "\f090"; }
.fa-sign-in-alt::before { content: "\f2f6"; }
.fa-sign-language::before { content: "\f2a7"; }
.fa-sign-out::before { content: "\f08b"; }
.fa-sign-out-alt::before { content: "\f2f5"; }
.fa-sign-post::before { content: "\e624"; }
.fa-sign-posts::before { content: "\e625"; }
.fa-sign-posts-wrench::before { content: "\e626"; }
.fa-signal::before { content: "\f012"; }
.fa-signal-1::before { content: "\f68c"; }
.fa-signal-2::before { content: "\f68d"; }
.fa-signal-3::before { content: "\f68e"; }
.fa-signal-4::before { content: "\f68f"; }
.fa-signal-5::before { content: "\f012"; }
.fa-signal-alt::before { content: "\f690"; }
.fa-signal-alt-1::before { content: "\f691"; }
.fa-signal-alt-2::before { content: "\f692"; }
.fa-signal-alt-3::before { content: "\f693"; }
.fa-signal-alt-4::before { content: "\f690"; }
.fa-signal-alt-slash::before { content: "\f694"; }
.fa-signal-bars::before { content: "\f690"; }
.fa-signal-bars-fair::before { content: "\f692"; }
.fa-signal-bars-good::before { content: "\f693"; }
.fa-signal-bars-slash::before { content: "\f694"; }
.fa-signal-bars-strong::before { content: "\f690"; }
.fa-signal-bars-weak::before { content: "\f691"; }
.fa-signal-fair::before { content: "\f68d"; }
.fa-signal-good::before { content: "\f68e"; }
.fa-signal-perfect::before { content: "\f012"; }
.fa-signal-slash::before { content: "\f695"; }
.fa-signal-stream::before { content: "\f8dd"; }
.fa-signal-stream-slash::before { content: "\e250"; }
.fa-signal-strong::before { content: "\f68f"; }
.fa-signal-weak::before { content: "\f68c"; }
.fa-signature::before { content: "\f5b7"; }
.fa-signature-lock::before { content: "\e3ca"; }
.fa-signature-slash::before { content: "\e3cb"; }
.fa-signing::before { content: "\f2a7"; }
.fa-signs-post::before { content: "\f277"; }
.fa-sim-card::before { content: "\f7c4"; }
.fa-sim-cards::before { content: "\e251"; }
.fa-sink::before { content: "\e06d"; }
.fa-siren::before { content: "\e02d"; }
.fa-siren-on::before { content: "\e02e"; }
.fa-sitemap::before { content: "\f0e8"; }
.fa-skating::before { content: "\f7c5"; }
.fa-skeleton::before { content: "\f620"; }
.fa-skeleton-ribs::before { content: "\e5cb"; }
.fa-ski-boot::before { content: "\e3cc"; }
.fa-ski-boot-ski::before { content: "\e3cd"; }
.fa-ski-jump::before { content: "\f7c7"; }
.fa-ski-lift::before { content: "\f7c8"; }
.fa-skiing::before { content: "\f7c9"; }
.fa-skiing-nordic::before { content: "\f7ca"; }
.fa-skull::before { content: "\f54c"; }
.fa-skull-cow::before { content: "\f8de"; }
.fa-skull-crossbones::before { content: "\f714"; }
.fa-slash::before { content: "\f715"; }
.fa-slash-back::before { content: "\5c"; }
.fa-slash-forward::before { content: "\2f"; }
.fa-sledding::before { content: "\f7cb"; }
.fa-sleigh::before { content: "\f7cc"; }
.fa-slider::before { content: "\e252"; }
.fa-sliders::before { content: "\f1de"; }
.fa-sliders-h::before { content: "\f1de"; }
.fa-sliders-h-square::before { content: "\f3f0"; }
.fa-sliders-simple::before { content: "\e253"; }
.fa-sliders-up::before { content: "\f3f1"; }
.fa-sliders-v::before { content: "\f3f1"; }
.fa-sliders-v-square::before { content: "\f3f2"; }
.fa-slot-machine::before { content: "\e3ce"; }
.fa-smile::before { content: "\f118"; }
.fa-smile-beam::before { content: "\f5b8"; }
.fa-smile-plus::before { content: "\f5b9"; }
.fa-smile-wink::before { content: "\f4da"; }
.fa-smog::before { content: "\f75f"; }
.fa-smoke::before { content: "\f760"; }
.fa-smoking::before { content: "\f48d"; }
.fa-smoking-ban::before { content: "\f54d"; }
.fa-sms::before { content: "\f7cd"; }
.fa-snake::before { content: "\f716"; }
.fa-snooze::before { content: "\f880"; }
.fa-snow-blowing::before { content: "\f761"; }
.fa-snowboarding::before { content: "\f7ce"; }
.fa-snowflake::before { content: "\f2dc"; }
.fa-snowflake-droplets::before { content: "\e5c1"; }
.fa-snowflakes::before { content: "\f7cf"; }
.fa-snowman::before { content: "\f7d0"; }
.fa-snowman-head::before { content: "\f79b"; }
.fa-snowmobile::before { content: "\f7d1"; }
.fa-snowplow::before { content: "\f7d2"; }
.fa-soap::before { content: "\e06e"; }
.fa-soccer-ball::before { content: "\f1e3"; }
.fa-socks::before { content: "\f696"; }
.fa-soft-serve::before { content: "\e400"; }
.fa-solar-panel::before { content: "\f5ba"; }
.fa-solar-system::before { content: "\e02f"; }
.fa-sort::before { content: "\f0dc"; }
.fa-sort-alpha-asc::before { content: "\f15d"; }
.fa-sort-alpha-desc::before { content: "\f881"; }
.fa-sort-alpha-down::before { content: "\f15d"; }
.fa-sort-alpha-down-alt::before { content: "\f881"; }
.fa-sort-alpha-up::before { content: "\f15e"; }
.fa-sort-alpha-up-alt::before { content: "\f882"; }
.fa-sort-alt::before { content: "\f883"; }
.fa-sort-amount-asc::before { content: "\f160"; }
.fa-sort-amount-desc::before { content: "\f884"; }
.fa-sort-amount-down::before { content: "\f160"; }
.fa-sort-amount-down-alt::before { content: "\f884"; }
.fa-sort-amount-up::before { content: "\f161"; }
.fa-sort-amount-up-alt::before { content: "\f885"; }
.fa-sort-asc::before { content: "\f0de"; }
.fa-sort-circle::before { content: "\e030"; }
.fa-sort-circle-down::before { content: "\e031"; }
.fa-sort-circle-up::before { content: "\e032"; }
.fa-sort-desc::before { content: "\f0dd"; }
.fa-sort-down::before { content: "\f0dd"; }
.fa-sort-numeric-asc::before { content: "\f162"; }
.fa-sort-numeric-desc::before { content: "\f886"; }
.fa-sort-numeric-down::before { content: "\f162"; }
.fa-sort-numeric-down-alt::before { content: "\f886"; }
.fa-sort-numeric-up::before { content: "\f163"; }
.fa-sort-numeric-up-alt::before { content: "\f887"; }
.fa-sort-shapes-down::before { content: "\f888"; }
.fa-sort-shapes-down-alt::before { content: "\f889"; }
.fa-sort-shapes-up::before { content: "\f88a"; }
.fa-sort-shapes-up-alt::before { content: "\f88b"; }
.fa-sort-size-down::before { content: "\f88c"; }
.fa-sort-size-down-alt::before { content: "\f88d"; }
.fa-sort-size-up::before { content: "\f88e"; }
.fa-sort-size-up-alt::before { content: "\f88f"; }
.fa-sort-up::before { content: "\f0de"; }
.fa-sort-up-down::before { content: "\e099"; }
.fa-soup::before { content: "\f823"; }
.fa-spa::before { content: "\f5bb"; }
.fa-space-shuttle::before { content: "\f197"; }
.fa-space-station-moon::before { content: "\e033"; }
.fa-space-station-moon-alt::before { content: "\e034"; }
.fa-space-station-moon-construction::before { content: "\e034"; }
.fa-spade::before { content: "\f2f4"; }
.fa-spaghetti-monster-flying::before { content: "\f67b"; }
.fa-sparkle::before { content: "\e5d6"; }
.fa-sparkles::before { content: "\f890"; }
.fa-speaker::before { content: "\f8df"; }
.fa-speakers::before { content: "\f8e0"; }
.fa-spell-check::before { content: "\f891"; }
.fa-spider::before { content: "\f717"; }
.fa-spider-black-widow::before { content: "\f718"; }
.fa-spider-web::before { content: "\f719"; }
.fa-spinner::before { content: "\f110"; }
.fa-spinner-scale::before { content: "\e62a"; }
.fa-spinner-third::before { content: "\f3f4"; }
.fa-split::before { content: "\e254"; }
.fa-splotch::before { content: "\f5bc"; }
.fa-spoon::before { content: "\f2e5"; }
.fa-sportsball::before { content: "\e44b"; }
.fa-spray-can::before { content: "\f5bd"; }
.fa-spray-can-sparkles::before { content: "\f5d0"; }
.fa-sprinkler::before { content: "\e035"; }
.fa-sprinkler-ceiling::before { content: "\e44c"; }
.fa-sprout::before { content: "\f4d8"; }
.fa-square::before { content: "\f0c8"; }
.fa-square-0::before { content: "\e255"; }
.fa-square-1::before { content: "\e256"; }
.fa-square-2::before { content: "\e257"; }
.fa-square-3::before { content: "\e258"; }
.fa-square-4::before { content: "\e259"; }
.fa-square-5::before { content: "\e25a"; }
.fa-square-6::before { content: "\e25b"; }
.fa-square-7::before { content: "\e25c"; }
.fa-square-8::before { content: "\e25d"; }
.fa-square-9::before { content: "\e25e"; }
.fa-square-a::before { content: "\e25f"; }
.fa-square-a-lock::before { content: "\e44d"; }
.fa-square-ampersand::before { content: "\e260"; }
.fa-square-arrow-down::before { content: "\f339"; }
.fa-square-arrow-down-left::before { content: "\e261"; }
.fa-square-arrow-down-right::before { content: "\e262"; }
.fa-square-arrow-left::before { content: "\f33a"; }
.fa-square-arrow-right::before { content: "\f33b"; }
.fa-square-arrow-up::before { content: "\f33c"; }
.fa-square-arrow-up-left::before { content: "\e263"; }
.fa-square-arrow-up-right::before { content: "\f14c"; }
.fa-square-b::before { content: "\e264"; }
.fa-square-bolt::before { content: "\e265"; }
.fa-square-c::before { content: "\e266"; }
.fa-square-caret-down::before { content: "\f150"; }
.fa-square-caret-left::before { content: "\f191"; }
.fa-square-caret-right::before { content: "\f152"; }
.fa-square-caret-up::before { content: "\f151"; }
.fa-square-check::before { content: "\f14a"; }
.fa-square-chevron-down::before { content: "\f329"; }
.fa-square-chevron-left::before { content: "\f32a"; }
.fa-square-chevron-right::before { content: "\f32b"; }
.fa-square-chevron-up::before { content: "\f32c"; }
.fa-square-code::before { content: "\e267"; }
.fa-square-d::before { content: "\e268"; }
.fa-square-dashed::before { content: "\e269"; }
.fa-square-dashed-circle-plus::before { content: "\e5c2"; }
.fa-square-divide::before { content: "\e26a"; }
.fa-square-dollar::before { content: "\f2e9"; }
.fa-square-down::before { content: "\f350"; }
.fa-square-down-left::before { content: "\e26b"; }
.fa-square-down-right::before { content: "\e26c"; }
.fa-square-e::before { content: "\e26d"; }
.fa-square-ellipsis::before { content: "\e26e"; }
.fa-square-ellipsis-vertical::before { content: "\e26f"; }
.fa-square-envelope::before { content: "\f199"; }
.fa-square-exclamation::before { content: "\f321"; }
.fa-square-f::before { content: "\e270"; }
.fa-square-fragile::before { content: "\f49b"; }
.fa-square-full::before { content: "\f45c"; }
.fa-square-g::before { content: "\e271"; }
.fa-square-h::before { content: "\f0fd"; }
.fa-square-heart::before { content: "\f4c8"; }
.fa-square-i::before { content: "\e272"; }
.fa-square-info::before { content: "\f30f"; }
.fa-square-j::before { content: "\e273"; }
.fa-square-k::before { content: "\e274"; }
.fa-square-kanban::before { content: "\e488"; }
.fa-square-l::before { content: "\e275"; }
.fa-square-left::before { content: "\f351"; }
.fa-square-list::before { content: "\e489"; }
.fa-square-m::before { content: "\e276"; }
.fa-square-minus::before { content: "\f146"; }
.fa-square-n::before { content: "\e277"; }
.fa-square-nfi::before { content: "\e576"; }
.fa-square-o::before { content: "\e278"; }
.fa-square-p::before { content: "\e279"; }
.fa-square-parking::before { content: "\f540"; }
.fa-square-parking-slash::before { content: "\f617"; }
.fa-square-pen::before { content: "\f14b"; }
.fa-square-person-confined::before { content: "\e577"; }
.fa-square-phone::before { content: "\f098"; }
.fa-square-phone-flip::before { content: "\f87b"; }
.fa-square-phone-hangup::before { content: "\e27a"; }
.fa-square-plus::before { content: "\f0fe"; }
.fa-square-poll-horizontal::before { content: "\f682"; }
.fa-square-poll-vertical::before { content: "\f681"; }
.fa-square-q::before { content: "\e27b"; }
.fa-square-quarters::before { content: "\e44e"; }
.fa-square-question::before { content: "\f2fd"; }
.fa-square-quote::before { content: "\e329"; }
.fa-square-r::before { content: "\e27c"; }
.fa-square-right::before { content: "\f352"; }
.fa-square-ring::before { content: "\e44f"; }
.fa-square-root::before { content: "\f697"; }
.fa-square-root-alt::before { content: "\f698"; }
.fa-square-root-variable::before { content: "\f698"; }
.fa-square-rss::before { content: "\f143"; }
.fa-square-s::before { content: "\e27d"; }
.fa-square-share-nodes::before { content: "\f1e1"; }
.fa-square-sliders::before { content: "\f3f0"; }
.fa-square-sliders-vertical::before { content: "\f3f2"; }
.fa-square-small::before { content: "\e27e"; }
.fa-square-star::before { content: "\e27f"; }
.fa-square-t::before { content: "\e280"; }
.fa-square-terminal::before { content: "\e32a"; }
.fa-square-this-way-up::before { content: "\f49f"; }
.fa-square-u::before { content: "\e281"; }
.fa-square-up::before { content: "\f353"; }
.fa-square-up-left::before { content: "\e282"; }
.fa-square-up-right::before { content: "\f360"; }
.fa-square-user::before { content: "\e283"; }
.fa-square-v::before { content: "\e284"; }
.fa-square-virus::before { content: "\e578"; }
.fa-square-w::before { content: "\e285"; }
.fa-square-wine-glass-crack::before { content: "\f49b"; }
.fa-square-x::before { content: "\e286"; }
.fa-square-xmark::before { content: "\f2d3"; }
.fa-square-y::before { content: "\e287"; }
.fa-square-z::before { content: "\e288"; }
.fa-squid::before { content: "\e450"; }
.fa-squirrel::before { content: "\f71a"; }
.fa-staff::before { content: "\f71b"; }
.fa-staff-aesculapius::before { content: "\e579"; }
.fa-staff-snake::before { content: "\e579"; }
.fa-stairs::before { content: "\e289"; }
.fa-stamp::before { content: "\f5bf"; }
.fa-standard-definition::before { content: "\e28a"; }
.fa-stapler::before { content: "\e5af"; }
.fa-star::before { content: "\f005"; }
.fa-star-and-crescent::before { content: "\f699"; }
.fa-star-christmas::before { content: "\f7d4"; }
.fa-star-circle::before { content: "\e123"; }
.fa-star-exclamation::before { content: "\f2f3"; }
.fa-star-half::before { content: "\f089"; }
.fa-star-half-alt::before { content: "\f5c0"; }
.fa-star-half-stroke::before { content: "\f5c0"; }
.fa-star-of-david::before { content: "\f69a"; }
.fa-star-of-life::before { content: "\f621"; }
.fa-star-sharp::before { content: "\e28b"; }
.fa-star-sharp-half::before { content: "\e28c"; }
.fa-star-sharp-half-alt::before { content: "\e28d"; }
.fa-star-sharp-half-stroke::before { content: "\e28d"; }
.fa-star-shooting::before { content: "\e036"; }
.fa-starfighter::before { content: "\e037"; }
.fa-starfighter-alt::before { content: "\e038"; }
.fa-starfighter-alt-advanced::before { content: "\e28e"; }
.fa-starfighter-twin-ion-engine::before { content: "\e038"; }
.fa-starfighter-twin-ion-engine-advanced::before { content: "\e28e"; }
.fa-stars::before { content: "\f762"; }
.fa-starship::before { content: "\e039"; }
.fa-starship-freighter::before { content: "\e03a"; }
.fa-steak::before { content: "\f824"; }
.fa-steering-wheel::before { content: "\f622"; }
.fa-step-backward::before { content: "\f048"; }
.fa-step-forward::before { content: "\f051"; }
.fa-sterling-sign::before { content: "\f154"; }
.fa-stethoscope::before { content: "\f0f1"; }
.fa-sticky-note::before { content: "\f249"; }
.fa-stocking::before { content: "\f7d5"; }
.fa-stomach::before { content: "\f623"; }
.fa-stop::before { content: "\f04d"; }
.fa-stop-circle::before { content: "\f28d"; }
.fa-stopwatch::before { content: "\f2f2"; }
.fa-stopwatch-20::before { content: "\e06f"; }
.fa-store::before { content: "\f54e"; }
.fa-store-alt::before { content: "\f54f"; }
.fa-store-alt-slash::before { content: "\e070"; }
.fa-store-lock::before { content: "\e4a6"; }
.fa-store-slash::before { content: "\e071"; }
.fa-strawberry::before { content: "\e32b"; }
.fa-stream::before { content: "\f550"; }
.fa-street-view::before { content: "\f21d"; }
.fa-stretcher::before { content: "\f825"; }
.fa-strikethrough::before { content: "\f0cc"; }
.fa-stroopwafel::before { content: "\f551"; }
.fa-subscript::before { content: "\f12c"; }
.fa-subtitles::before { content: "\e60f"; }
.fa-subtitles-slash::before { content: "\e610"; }
.fa-subtract::before { content: "\f068"; }
.fa-subway::before { content: "\f239"; }
.fa-subway-tunnel::before { content: "\e2a3"; }
.fa-suitcase::before { content: "\f0f2"; }
.fa-suitcase-medical::before { content: "\f0fa"; }
.fa-suitcase-rolling::before { content: "\f5c1"; }
.fa-sun::before { content: "\f185"; }
.fa-sun-alt::before { content: "\e28f"; }
.fa-sun-bright::before { content: "\e28f"; }
.fa-sun-cloud::before { content: "\f763"; }
.fa-sun-dust::before { content: "\f764"; }
.fa-sun-haze::before { content: "\f765"; }
.fa-sun-plant-wilt::before { content: "\e57a"; }
.fa-sunglasses::before { content: "\f892"; }
.fa-sunrise::before { content: "\f766"; }
.fa-sunset::before { content: "\f767"; }
.fa-superscript::before { content: "\f12b"; }
.fa-surprise::before { content: "\f5c2"; }
.fa-sushi::before { content: "\e48a"; }
.fa-sushi-roll::before { content: "\e48b"; }
.fa-swap::before { content: "\e609"; }
.fa-swap-arrows::before { content: "\e60a"; }
.fa-swatchbook::before { content: "\f5c3"; }
.fa-swimmer::before { content: "\f5c4"; }
.fa-swimming-pool::before { content: "\f5c5"; }
.fa-sword::before { content: "\f71c"; }
.fa-sword-laser::before { content: "\e03b"; }
.fa-sword-laser-alt::before { content: "\e03c"; }
.fa-swords::before { content: "\f71d"; }
.fa-swords-laser::before { content: "\e03d"; }
.fa-symbols::before { content: "\f86e"; }
.fa-synagogue::before { content: "\f69b"; }
.fa-sync::before { content: "\f021"; }
.fa-sync-alt::before { content: "\f2f1"; }
.fa-syringe::before { content: "\f48e"; }
.fa-t::before { content: "\54"; }
.fa-t-rex::before { content: "\e629"; }
.fa-t-shirt::before { content: "\f553"; }
.fa-table::before { content: "\f0ce"; }
.fa-table-cells::before { content: "\f00a"; }
.fa-table-cells-column-lock::before { content: "\e678"; }
.fa-table-cells-column-unlock::before { content: "\e690"; }
.fa-table-cells-large::before { content: "\f009"; }
.fa-table-cells-lock::before { content: "\e679"; }
.fa-table-cells-row-lock::before { content: "\e67a"; }
.fa-table-cells-row-unlock::before { content: "\e691"; }
.fa-table-cells-unlock::before { content: "\e692"; }
.fa-table-columns::before { content: "\f0db"; }
.fa-table-layout::before { content: "\e290"; }
.fa-table-list::before { content: "\f00b"; }
.fa-table-picnic::before { content: "\e32d"; }
.fa-table-pivot::before { content: "\e291"; }
.fa-table-rows::before { content: "\e292"; }
.fa-table-tennis::before { content: "\f45d"; }
.fa-table-tennis-paddle-ball::before { content: "\f45d"; }
.fa-table-tree::before { content: "\e293"; }
.fa-tablet::before { content: "\f3fb"; }
.fa-tablet-alt::before { content: "\f3fa"; }
.fa-tablet-android::before { content: "\f3fb"; }
.fa-tablet-android-alt::before { content: "\f3fc"; }
.fa-tablet-button::before { content: "\f10a"; }
.fa-tablet-rugged::before { content: "\f48f"; }
.fa-tablet-screen::before { content: "\f3fc"; }
.fa-tablet-screen-button::before { content: "\f3fa"; }
.fa-tablets::before { content: "\f490"; }
.fa-tachograph-digital::before { content: "\f566"; }
.fa-tachometer::before { content: "\f62a"; }
.fa-tachometer-alt::before { content: "\f625"; }
.fa-tachometer-alt-average::before { content: "\f624"; }
.fa-tachometer-alt-fast::before { content: "\f625"; }
.fa-tachometer-alt-fastest::before { content: "\f626"; }
.fa-tachometer-alt-slow::before { content: "\f627"; }
.fa-tachometer-alt-slowest::before { content: "\f628"; }
.fa-tachometer-average::before { content: "\f629"; }
.fa-tachometer-fast::before { content: "\f62a"; }
.fa-tachometer-fastest::before { content: "\f62b"; }
.fa-tachometer-slow::before { content: "\f62c"; }
.fa-tachometer-slowest::before { content: "\f62d"; }
.fa-taco::before { content: "\f826"; }
.fa-tag::before { content: "\f02b"; }
.fa-tags::before { content: "\f02c"; }
.fa-tally::before { content: "\f69c"; }
.fa-tally-1::before { content: "\e294"; }
.fa-tally-2::before { content: "\e295"; }
.fa-tally-3::before { content: "\e296"; }
.fa-tally-4::before { content: "\e297"; }
.fa-tally-5::before { content: "\f69c"; }
.fa-tamale::before { content: "\e451"; }
.fa-tanakh::before { content: "\f827"; }
.fa-tank-water::before { content: "\e452"; }
.fa-tape::before { content: "\f4db"; }
.fa-tarp::before { content: "\e57b"; }
.fa-tarp-droplet::before { content: "\e57c"; }
.fa-tasks::before { content: "\f0ae"; }
.fa-tasks-alt::before { content: "\f828"; }
.fa-taxi::before { content: "\f1ba"; }
.fa-taxi-bus::before { content: "\e298"; }
.fa-teddy-bear::before { content: "\e3cf"; }
.fa-teeth::before { content: "\f62e"; }
.fa-teeth-open::before { content: "\f62f"; }
.fa-telescope::before { content: "\e03e"; }
.fa-teletype::before { content: "\f1e4"; }
.fa-teletype-answer::before { content: "\e2b9"; }
.fa-television::before { content: "\f26c"; }
.fa-temperature-0::before { content: "\f2cb"; }
.fa-temperature-1::before { content: "\f2ca"; }
.fa-temperature-2::before { content: "\f2c9"; }
.fa-temperature-3::before { content: "\f2c8"; }
.fa-temperature-4::before { content: "\f2c7"; }
.fa-temperature-arrow-down::before { content: "\e03f"; }
.fa-temperature-arrow-up::before { content: "\e040"; }
.fa-temperature-down::before { content: "\e03f"; }
.fa-temperature-empty::before { content: "\f2cb"; }
.fa-temperature-frigid::before { content: "\f768"; }
.fa-temperature-full::before { content: "\f2c7"; }
.fa-temperature-half::before { content: "\f2c9"; }
.fa-temperature-high::before { content: "\f769"; }
.fa-temperature-hot::before { content: "\f76a"; }
.fa-temperature-list::before { content: "\e299"; }
.fa-temperature-low::before { content: "\f76b"; }
.fa-temperature-quarter::before { content: "\f2ca"; }
.fa-temperature-snow::before { content: "\f768"; }
.fa-temperature-sun::before { content: "\f76a"; }
.fa-temperature-three-quarters::before { content: "\f2c8"; }
.fa-temperature-up::before { content: "\e040"; }
.fa-tenge::before { content: "\f7d7"; }
.fa-tenge-sign::before { content: "\f7d7"; }
.fa-tennis-ball::before { content: "\f45e"; }
.fa-tent::before { content: "\e57d"; }
.fa-tent-arrow-down-to-line::before { content: "\e57e"; }
.fa-tent-arrow-left-right::before { content: "\e57f"; }
.fa-tent-arrow-turn-left::before { content: "\e580"; }
.fa-tent-arrows-down::before { content: "\e581"; }
.fa-tent-double-peak::before { content: "\e627"; }
.fa-tents::before { content: "\e582"; }
.fa-terminal::before { content: "\f120"; }
.fa-text::before { content: "\f893"; }
.fa-text-height::before { content: "\f034"; }
.fa-text-size::before { content: "\f894"; }
.fa-text-slash::before { content: "\f87d"; }
.fa-text-width::before { content: "\f035"; }
.fa-th::before { content: "\f00a"; }
.fa-th-large::before { content: "\f009"; }
.fa-th-list::before { content: "\f00b"; }
.fa-theater-masks::before { content: "\f630"; }
.fa-thermometer::before { content: "\f491"; }
.fa-thermometer-0::before { content: "\f2cb"; }
.fa-thermometer-1::before { content: "\f2ca"; }
.fa-thermometer-2::before { content: "\f2c9"; }
.fa-thermometer-3::before { content: "\f2c8"; }
.fa-thermometer-4::before { content: "\f2c7"; }
.fa-thermometer-empty::before { content: "\f2cb"; }
.fa-thermometer-full::before { content: "\f2c7"; }
.fa-thermometer-half::before { content: "\f2c9"; }
.fa-thermometer-quarter::before { content: "\f2ca"; }
.fa-thermometer-three-quarters::before { content: "\f2c8"; }
.fa-theta::before { content: "\f69e"; }
.fa-thought-bubble::before { content: "\e32e"; }
.fa-thumb-tack::before { content: "\f08d"; }
.fa-thumb-tack-slash::before { content: "\e68f"; }
.fa-thumbs-down::before { content: "\f165"; }
.fa-thumbs-up::before { content: "\f164"; }
.fa-thumbtack::before { content: "\f08d"; }
.fa-thumbtack-slash::before { content: "\e68f"; }
.fa-thunderstorm::before { content: "\f76c"; }
.fa-thunderstorm-moon::before { content: "\f76d"; }
.fa-thunderstorm-sun::before { content: "\f76e"; }
.fa-tick::before { content: "\e32f"; }
.fa-ticket::before { content: "\f145"; }
.fa-ticket-airline::before { content: "\e29a"; }
.fa-ticket-alt::before { content: "\f3ff"; }
.fa-ticket-perforated::before { content: "\e63e"; }
.fa-ticket-perforated-plane::before { content: "\e29a"; }
.fa-ticket-plane::before { content: "\e29a"; }
.fa-ticket-simple::before { content: "\f3ff"; }
.fa-tickets::before { content: "\e658"; }
.fa-tickets-airline::before { content: "\e29b"; }
.fa-tickets-perforated::before { content: "\e63f"; }
.fa-tickets-perforated-plane::before { content: "\e29b"; }
.fa-tickets-plane::before { content: "\e29b"; }
.fa-tickets-simple::before { content: "\e659"; }
.fa-tilde::before { content: "\7e"; }
.fa-timeline::before { content: "\e29c"; }
.fa-timeline-arrow::before { content: "\e29d"; }
.fa-timer::before { content: "\e29e"; }
.fa-times::before { content: "\f00d"; }
.fa-times-circle::before { content: "\f057"; }
.fa-times-hexagon::before { content: "\f2ee"; }
.fa-times-octagon::before { content: "\f2f0"; }
.fa-times-rectangle::before { content: "\f410"; }
.fa-times-square::before { content: "\f2d3"; }
.fa-times-to-slot::before { content: "\f771"; }
.fa-tint::before { content: "\f043"; }
.fa-tint-slash::before { content: "\f5c7"; }
.fa-tire::before { content: "\f631"; }
.fa-tire-flat::before { content: "\f632"; }
.fa-tire-pressure-warning::before { content: "\f633"; }
.fa-tire-rugged::before { content: "\f634"; }
.fa-tired::before { content: "\f5c8"; }
.fa-toggle-large-off::before { content: "\e5b0"; }
.fa-toggle-large-on::before { content: "\e5b1"; }
.fa-toggle-off::before { content: "\f204"; }
.fa-toggle-on::before { content: "\f205"; }
.fa-toilet::before { content: "\f7d8"; }
.fa-toilet-paper::before { content: "\f71e"; }
.fa-toilet-paper-alt::before { content: "\f71f"; }
.fa-toilet-paper-blank::before { content: "\f71f"; }
.fa-toilet-paper-blank-under::before { content: "\e29f"; }
.fa-toilet-paper-check::before { content: "\e5b2"; }
.fa-toilet-paper-reverse::before { content: "\e2a0"; }
.fa-toilet-paper-reverse-alt::before { content: "\e29f"; }
.fa-toilet-paper-reverse-slash::before { content: "\e2a1"; }
.fa-toilet-paper-slash::before { content: "\e072"; }
.fa-toilet-paper-under::before { content: "\e2a0"; }
.fa-toilet-paper-under-slash::before { content: "\e2a1"; }
.fa-toilet-paper-xmark::before { content: "\e5b3"; }
.fa-toilet-portable::before { content: "\e583"; }
.fa-toilets-portable::before { content: "\e584"; }
.fa-tomato::before { content: "\e330"; }
.fa-tombstone::before { content: "\f720"; }
.fa-tombstone-alt::before { content: "\f721"; }
.fa-tombstone-blank::before { content: "\f721"; }
.fa-toolbox::before { content: "\f552"; }
.fa-tools::before { content: "\f7d9"; }
.fa-tooth::before { content: "\f5c9"; }
.fa-toothbrush::before { content: "\f635"; }
.fa-torah::before { content: "\f6a0"; }
.fa-torii-gate::before { content: "\f6a1"; }
.fa-tornado::before { content: "\f76f"; }
.fa-tower-broadcast::before { content: "\f519"; }
.fa-tower-cell::before { content: "\e585"; }
.fa-tower-control::before { content: "\e2a2"; }
.fa-tower-observation::before { content: "\e586"; }
.fa-tractor::before { content: "\f722"; }
.fa-trademark::before { content: "\f25c"; }
.fa-traffic-cone::before { content: "\f636"; }
.fa-traffic-light::before { content: "\f637"; }
.fa-traffic-light-go::before { content: "\f638"; }
.fa-traffic-light-slow::before { content: "\f639"; }
.fa-traffic-light-stop::before { content: "\f63a"; }
.fa-trailer::before { content: "\e041"; }
.fa-train::before { content: "\f238"; }
.fa-train-subway::before { content: "\f239"; }
.fa-train-subway-tunnel::before { content: "\e2a3"; }
.fa-train-track::before { content: "\e453"; }
.fa-train-tram::before { content: "\e5b4"; }
.fa-train-tunnel::before { content: "\e454"; }
.fa-tram::before { content: "\f7da"; }
.fa-transformer-bolt::before { content: "\e2a4"; }
.fa-transgender::before { content: "\f225"; }
.fa-transgender-alt::before { content: "\f225"; }
.fa-transporter::before { content: "\e042"; }
.fa-transporter-1::before { content: "\e043"; }
.fa-transporter-2::before { content: "\e044"; }
.fa-transporter-3::before { content: "\e045"; }
.fa-transporter-4::before { content: "\e2a5"; }
.fa-transporter-5::before { content: "\e2a6"; }
.fa-transporter-6::before { content: "\e2a7"; }
.fa-transporter-7::before { content: "\e2a8"; }
.fa-transporter-empty::before { content: "\e046"; }
.fa-trash::before { content: "\f1f8"; }
.fa-trash-alt::before { content: "\f2ed"; }
.fa-trash-alt-slash::before { content: "\e2ad"; }
.fa-trash-arrow-turn-left::before { content: "\f895"; }
.fa-trash-arrow-up::before { content: "\f829"; }
.fa-trash-can::before { content: "\f2ed"; }
.fa-trash-can-arrow-turn-left::before { content: "\f896"; }
.fa-trash-can-arrow-up::before { content: "\f82a"; }
.fa-trash-can-check::before { content: "\e2a9"; }
.fa-trash-can-clock::before { content: "\e2aa"; }
.fa-trash-can-list::before { content: "\e2ab"; }
.fa-trash-can-plus::before { content: "\e2ac"; }
.fa-trash-can-slash::before { content: "\e2ad"; }
.fa-trash-can-undo::before { content: "\f896"; }
.fa-trash-can-xmark::before { content: "\e2ae"; }
.fa-trash-check::before { content: "\e2af"; }
.fa-trash-circle::before { content: "\e126"; }
.fa-trash-clock::before { content: "\e2b0"; }
.fa-trash-list::before { content: "\e2b1"; }
.fa-trash-plus::before { content: "\e2b2"; }
.fa-trash-restore::before { content: "\f829"; }
.fa-trash-restore-alt::before { content: "\f82a"; }
.fa-trash-slash::before { content: "\e2b3"; }
.fa-trash-undo::before { content: "\f895"; }
.fa-trash-undo-alt::before { content: "\f896"; }
.fa-trash-xmark::before { content: "\e2b4"; }
.fa-treasure-chest::before { content: "\f723"; }
.fa-tree::before { content: "\f1bb"; }
.fa-tree-alt::before { content: "\f400"; }
.fa-tree-christmas::before { content: "\f7db"; }
.fa-tree-city::before { content: "\e587"; }
.fa-tree-deciduous::before { content: "\f400"; }
.fa-tree-decorated::before { content: "\f7dc"; }
.fa-tree-large::before { content: "\f7dd"; }
.fa-tree-palm::before { content: "\f82b"; }
.fa-trees::before { content: "\f724"; }
.fa-trian-balbot::before { content: "\e45c"; }
.fa-triangle::before { content: "\f2ec"; }
.fa-triangle-circle-square::before { content: "\f61f"; }
.fa-triangle-exclamation::before { content: "\f071"; }
.fa-triangle-instrument::before { content: "\f8e2"; }
.fa-triangle-music::before { content: "\f8e2"; }
.fa-triangle-person-digging::before { content: "\f85d"; }
.fa-tricycle::before { content: "\e5c3"; }
.fa-tricycle-adult::before { content: "\e5c4"; }
.fa-trillium::before { content: "\e588"; }
.fa-trophy::before { content: "\f091"; }
.fa-trophy-alt::before { content: "\f2eb"; }
.fa-trophy-star::before { content: "\f2eb"; }
.fa-trowel::before { content: "\e589"; }
.fa-trowel-bricks::before { content: "\e58a"; }
.fa-truck::before { content: "\f0d1"; }
.fa-truck-arrow-right::before { content: "\e58b"; }
.fa-truck-bolt::before { content: "\e3d0"; }
.fa-truck-clock::before { content: "\f48c"; }
.fa-truck-container::before { content: "\f4dc"; }
.fa-truck-container-empty::before { content: "\e2b5"; }
.fa-truck-couch::before { content: "\f4dd"; }
.fa-truck-droplet::before { content: "\e58c"; }
.fa-truck-fast::before { content: "\f48b"; }
.fa-truck-field::before { content: "\e58d"; }
.fa-truck-field-un::before { content: "\e58e"; }
.fa-truck-fire::before { content: "\e65a"; }
.fa-truck-flatbed::before { content: "\e2b6"; }
.fa-truck-front::before { content: "\e2b7"; }
.fa-truck-ladder::before { content: "\e657"; }
.fa-truck-loading::before { content: "\f4de"; }
.fa-truck-medical::before { content: "\f0f9"; }
.fa-truck-monster::before { content: "\f63b"; }
.fa-truck-moving::before { content: "\f4df"; }
.fa-truck-pickup::before { content: "\f63c"; }
.fa-truck-plane::before { content: "\e58f"; }
.fa-truck-plow::before { content: "\f7de"; }
.fa-truck-ramp::before { content: "\f4e0"; }
.fa-truck-ramp-box::before { content: "\f4de"; }
.fa-truck-ramp-couch::before { content: "\f4dd"; }
.fa-truck-tow::before { content: "\e2b8"; }
.fa-truck-utensils::before { content: "\e628"; }
.fa-trumpet::before { content: "\f8e3"; }
.fa-try::before { content: "\e2bb"; }
.fa-tshirt::before { content: "\f553"; }
.fa-tty::before { content: "\f1e4"; }
.fa-tty-answer::before { content: "\e2b9"; }
.fa-tugrik-sign::before { content: "\e2ba"; }
.fa-turkey::before { content: "\f725"; }
.fa-turkish-lira::before { content: "\e2bb"; }
.fa-turkish-lira-sign::before { content: "\e2bb"; }
.fa-turn-down::before { content: "\f3be"; }
.fa-turn-down-left::before { content: "\e331"; }
.fa-turn-down-right::before { content: "\e455"; }
.fa-turn-left::before { content: "\e636"; }
.fa-turn-left-down::before { content: "\e637"; }
.fa-turn-left-up::before { content: "\e638"; }
.fa-turn-right::before { content: "\e639"; }
.fa-turn-up::before { content: "\f3bf"; }
.fa-turntable::before { content: "\f8e4"; }
.fa-turtle::before { content: "\f726"; }
.fa-tv::before { content: "\f26c"; }
.fa-tv-alt::before { content: "\f26c"; }
.fa-tv-music::before { content: "\f8e6"; }
.fa-tv-retro::before { content: "\f401"; }
.fa-typewriter::before { content: "\f8e7"; }
.fa-u::before { content: "\55"; }
.fa-ufo::before { content: "\e047"; }
.fa-ufo-beam::before { content: "\e048"; }
.fa-umbrella::before { content: "\f0e9"; }
.fa-umbrella-alt::before { content: "\e2bc"; }
.fa-umbrella-beach::before { content: "\f5ca"; }
.fa-umbrella-simple::before { content: "\e2bc"; }
.fa-underline::before { content: "\f0cd"; }
.fa-undo::before { content: "\f0e2"; }
.fa-undo-alt::before { content: "\f2ea"; }
.fa-unicorn::before { content: "\f727"; }
.fa-uniform-martial-arts::before { content: "\e3d1"; }
.fa-union::before { content: "\f6a2"; }
.fa-universal-access::before { content: "\f29a"; }
.fa-university::before { content: "\f19c"; }
.fa-unlink::before { content: "\f127"; }
.fa-unlock::before { content: "\f09c"; }
.fa-unlock-alt::before { content: "\f13e"; }
.fa-unlock-keyhole::before { content: "\f13e"; }
.fa-unsorted::before { content: "\f0dc"; }
.fa-up::before { content: "\f357"; }
.fa-up-down::before { content: "\f338"; }
.fa-up-down-left-right::before { content: "\f0b2"; }
.fa-up-from-bracket::before { content: "\e590"; }
.fa-up-from-dotted-line::before { content: "\e456"; }
.fa-up-from-line::before { content: "\f346"; }
.fa-up-left::before { content: "\e2bd"; }
.fa-up-long::before { content: "\f30c"; }
.fa-up-right::before { content: "\e2be"; }
.fa-up-right-and-down-left-from-center::before { content: "\f424"; }
.fa-up-right-from-square::before { content: "\f35d"; }
.fa-up-to-bracket::before { content: "\e66e"; }
.fa-up-to-dotted-line::before { content: "\e457"; }
.fa-up-to-line::before { content: "\f34d"; }
.fa-upload::before { content: "\f093"; }
.fa-usb-drive::before { content: "\f8e9"; }
.fa-usd::before { content: "\24"; }
.fa-usd-circle::before { content: "\f2e8"; }
.fa-usd-square::before { content: "\f2e9"; }
.fa-user::before { content: "\f007"; }
.fa-user-alien::before { content: "\e04a"; }
.fa-user-alt::before { content: "\f406"; }
.fa-user-alt-slash::before { content: "\f4fa"; }
.fa-user-astronaut::before { content: "\f4fb"; }
.fa-user-beard-bolt::before { content: "\e689"; }
.fa-user-bounty-hunter::before { content: "\e2bf"; }
.fa-user-chart::before { content: "\f6a3"; }
.fa-user-check::before { content: "\f4fc"; }
.fa-user-chef::before { content: "\e3d2"; }
.fa-user-circle::before { content: "\f2bd"; }
.fa-user-clock::before { content: "\f4fd"; }
.fa-user-cog::before { content: "\f4fe"; }
.fa-user-construction::before { content: "\f82c"; }
.fa-user-cowboy::before { content: "\f8ea"; }
.fa-user-crown::before { content: "\f6a4"; }
.fa-user-doctor::before { content: "\f0f0"; }
.fa-user-doctor-hair::before { content: "\e458"; }
.fa-user-doctor-hair-long::before { content: "\e459"; }
.fa-user-doctor-message::before { content: "\f82e"; }
.fa-user-edit::before { content: "\f4ff"; }
.fa-user-friends::before { content: "\f500"; }
.fa-user-gear::before { content: "\f4fe"; }
.fa-user-graduate::before { content: "\f501"; }
.fa-user-group::before { content: "\f500"; }
.fa-user-group-crown::before { content: "\f6a5"; }
.fa-user-group-simple::before { content: "\e603"; }
.fa-user-hair::before { content: "\e45a"; }
.fa-user-hair-buns::before { content: "\e3d3"; }
.fa-user-hair-long::before { content: "\e45b"; }
.fa-user-hair-mullet::before { content: "\e45c"; }
.fa-user-hard-hat::before { content: "\f82c"; }
.fa-user-headset::before { content: "\f82d"; }
.fa-user-helmet-safety::before { content: "\f82c"; }
.fa-user-hoodie::before { content: "\e68a"; }
.fa-user-injured::before { content: "\f728"; }
.fa-user-large::before { content: "\f406"; }
.fa-user-large-slash::before { content: "\f4fa"; }
.fa-user-lock::before { content: "\f502"; }
.fa-user-magnifying-glass::before { content: "\e5c5"; }
.fa-user-md::before { content: "\f0f0"; }
.fa-user-md-chat::before { content: "\f82e"; }
.fa-user-minus::before { content: "\f503"; }
.fa-user-music::before { content: "\f8eb"; }
.fa-user-ninja::before { content: "\f504"; }
.fa-user-nurse::before { content: "\f82f"; }
.fa-user-nurse-hair::before { content: "\e45d"; }
.fa-user-nurse-hair-long::before { content: "\e45e"; }
.fa-user-pen::before { content: "\f4ff"; }
.fa-user-pilot::before { content: "\e2c0"; }
.fa-user-pilot-tie::before { content: "\e2c1"; }
.fa-user-plus::before { content: "\f234"; }
.fa-user-police::before { content: "\e333"; }
.fa-user-police-tie::before { content: "\e334"; }
.fa-user-robot::before { content: "\e04b"; }
.fa-user-robot-xmarks::before { content: "\e4a7"; }
.fa-user-secret::before { content: "\f21b"; }
.fa-user-shakespeare::before { content: "\e2c2"; }
.fa-user-shield::before { content: "\f505"; }
.fa-user-slash::before { content: "\f506"; }
.fa-user-tag::before { content: "\f507"; }
.fa-user-tie::before { content: "\f508"; }
.fa-user-tie-hair::before { content: "\e45f"; }
.fa-user-tie-hair-long::before { content: "\e460"; }
.fa-user-times::before { content: "\f235"; }
.fa-user-unlock::before { content: "\e058"; }
.fa-user-visor::before { content: "\e04c"; }
.fa-user-vneck::before { content: "\e461"; }
.fa-user-vneck-hair::before { content: "\e462"; }
.fa-user-vneck-hair-long::before { content: "\e463"; }
.fa-user-xmark::before { content: "\f235"; }
.fa-users::before { content: "\f0c0"; }
.fa-users-between-lines::before { content: "\e591"; }
.fa-users-class::before { content: "\f63d"; }
.fa-users-cog::before { content: "\f509"; }
.fa-users-crown::before { content: "\f6a5"; }
.fa-users-gear::before { content: "\f509"; }
.fa-users-line::before { content: "\e592"; }
.fa-users-medical::before { content: "\f830"; }
.fa-users-rays::before { content: "\e593"; }
.fa-users-rectangle::before { content: "\e594"; }
.fa-users-slash::before { content: "\e073"; }
.fa-users-viewfinder::before { content: "\e595"; }
.fa-utensil-fork::before { content: "\f2e3"; }
.fa-utensil-knife::before { content: "\f2e4"; }
.fa-utensil-spoon::before { content: "\f2e5"; }
.fa-utensils::before { content: "\f2e7"; }
.fa-utensils-alt::before { content: "\f2e6"; }
.fa-utensils-slash::before { content: "\e464"; }
.fa-utility-pole::before { content: "\e2c3"; }
.fa-utility-pole-double::before { content: "\e2c4"; }
.fa-v::before { content: "\56"; }
.fa-vacuum::before { content: "\e04d"; }
.fa-vacuum-robot::before { content: "\e04e"; }
.fa-value-absolute::before { content: "\f6a6"; }
.fa-van-shuttle::before { content: "\f5b6"; }
.fa-vault::before { content: "\e2c5"; }
.fa-vcard::before { content: "\f2bb"; }
.fa-vector-circle::before { content: "\e2c6"; }
.fa-vector-polygon::before { content: "\e2c7"; }
.fa-vector-square::before { content: "\f5cb"; }
.fa-vent-damper::before { content: "\e465"; }
.fa-venus::before { content: "\f221"; }
.fa-venus-double::before { content: "\f226"; }
.fa-venus-mars::before { content: "\f228"; }
.fa-vest::before { content: "\e085"; }
.fa-vest-patches::before { content: "\e086"; }
.fa-vhs::before { content: "\f8ec"; }
.fa-vial::before { content: "\f492"; }
.fa-vial-circle-check::before { content: "\e596"; }
.fa-vial-virus::before { content: "\e597"; }
.fa-vials::before { content: "\f493"; }
.fa-video::before { content: "\f03d"; }
.fa-video-arrow-down-left::before { content: "\e2c8"; }
.fa-video-arrow-up-right::before { content: "\e2c9"; }
.fa-video-camera::before { content: "\f03d"; }
.fa-video-circle::before { content: "\e12b"; }
.fa-video-handheld::before { content: "\f8a8"; }
.fa-video-plus::before { content: "\f4e1"; }
.fa-video-slash::before { content: "\f4e2"; }
.fa-vihara::before { content: "\f6a7"; }
.fa-violin::before { content: "\f8ed"; }
.fa-virus::before { content: "\e074"; }
.fa-virus-covid::before { content: "\e4a8"; }
.fa-virus-covid-slash::before { content: "\e4a9"; }
.fa-virus-slash::before { content: "\e075"; }
.fa-viruses::before { content: "\e076"; }
.fa-voicemail::before { content: "\f897"; }
.fa-volcano::before { content: "\f770"; }
.fa-volleyball::before { content: "\f45f"; }
.fa-volleyball-ball::before { content: "\f45f"; }
.fa-volume::before { content: "\f6a8"; }
.fa-volume-control-phone::before { content: "\f2a0"; }
.fa-volume-down::before { content: "\f027"; }
.fa-volume-high::before { content: "\f028"; }
.fa-volume-low::before { content: "\f027"; }
.fa-volume-medium::before { content: "\f6a8"; }
.fa-volume-mute::before { content: "\f6a9"; }
.fa-volume-off::before { content: "\f026"; }
.fa-volume-slash::before { content: "\f2e2"; }
.fa-volume-times::before { content: "\f6a9"; }
.fa-volume-up::before { content: "\f028"; }
.fa-volume-xmark::before { content: "\f6a9"; }
.fa-vote-nay::before { content: "\f771"; }
.fa-vote-yea::before { content: "\f772"; }
.fa-vr-cardboard::before { content: "\f729"; }
.fa-w::before { content: "\57"; }
.fa-waffle::before { content: "\e466"; }
.fa-wagon-covered::before { content: "\f8ee"; }
.fa-walker::before { content: "\f831"; }
.fa-walkie-talkie::before { content: "\f8ef"; }
.fa-walking::before { content: "\f554"; }
.fa-wall-brick::before { content: "\e3db"; }
.fa-wallet::before { content: "\f555"; }
.fa-wand::before { content: "\f72a"; }
.fa-wand-magic::before { content: "\f0d0"; }
.fa-wand-magic-sparkles::before { content: "\e2ca"; }
.fa-wand-sparkles::before { content: "\f72b"; }
.fa-warehouse::before { content: "\f494"; }
.fa-warehouse-alt::before { content: "\f495"; }
.fa-warehouse-full::before { content: "\f495"; }
.fa-warning::before { content: "\f071"; }
.fa-washer::before { content: "\f898"; }
.fa-washing-machine::before { content: "\f898"; }
.fa-watch::before { content: "\f2e1"; }
.fa-watch-apple::before { content: "\e2cb"; }
.fa-watch-calculator::before { content: "\f8f0"; }
.fa-watch-fitness::before { content: "\f63e"; }
.fa-watch-smart::before { content: "\e2cc"; }
.fa-water::before { content: "\f773"; }
.fa-water-arrow-down::before { content: "\f774"; }
.fa-water-arrow-up::before { content: "\f775"; }
.fa-water-ladder::before { content: "\f5c5"; }
.fa-water-lower::before { content: "\f774"; }
.fa-water-rise::before { content: "\f775"; }
.fa-watermelon-slice::before { content: "\e337"; }
.fa-wave::before { content: "\e65b"; }
.fa-wave-pulse::before { content: "\f5f8"; }
.fa-wave-sine::before { content: "\f899"; }
.fa-wave-square::before { content: "\f83e"; }
.fa-wave-triangle::before { content: "\f89a"; }
.fa-waveform::before { content: "\f8f1"; }
.fa-waveform-circle::before { content: "\e12d"; }
.fa-waveform-lines::before { content: "\f8f2"; }
.fa-waveform-path::before { content: "\f8f2"; }
.fa-waves-sine::before { content: "\e65d"; }
.fa-web-awesome::before { content: "\e682"; }
.fa-webcam::before { content: "\f832"; }
.fa-webcam-slash::before { content: "\f833"; }
.fa-webhook::before { content: "\e5d5"; }
.fa-weight::before { content: "\f496"; }
.fa-weight-hanging::before { content: "\f5cd"; }
.fa-weight-scale::before { content: "\f496"; }
.fa-whale::before { content: "\f72c"; }
.fa-wheat::before { content: "\f72d"; }
.fa-wheat-alt::before { content: "\e2cd"; }
.fa-wheat-awn::before { content: "\e2cd"; }
.fa-wheat-awn-circle-exclamation::before { content: "\e598"; }
.fa-wheat-awn-slash::before { content: "\e338"; }
.fa-wheat-slash::before { content: "\e339"; }
.fa-wheelchair::before { content: "\f193"; }
.fa-wheelchair-alt::before { content: "\e2ce"; }
.fa-wheelchair-move::before { content: "\e2ce"; }
.fa-whiskey-glass::before { content: "\f7a0"; }
.fa-whiskey-glass-ice::before { content: "\f7a1"; }
.fa-whistle::before { content: "\f460"; }
.fa-wifi::before { content: "\f1eb"; }
.fa-wifi-1::before { content: "\f6aa"; }
.fa-wifi-2::before { content: "\f6ab"; }
.fa-wifi-3::before { content: "\f1eb"; }
.fa-wifi-exclamation::before { content: "\e2cf"; }
.fa-wifi-fair::before { content: "\f6ab"; }
.fa-wifi-slash::before { content: "\f6ac"; }
.fa-wifi-strong::before { content: "\f1eb"; }
.fa-wifi-weak::before { content: "\f6aa"; }
.fa-wind::before { content: "\f72e"; }
.fa-wind-circle-exclamation::before { content: "\f776"; }
.fa-wind-turbine::before { content: "\f89b"; }
.fa-wind-warning::before { content: "\f776"; }
.fa-window::before { content: "\f40e"; }
.fa-window-alt::before { content: "\f40f"; }
.fa-window-close::before { content: "\f410"; }
.fa-window-flip::before { content: "\f40f"; }
.fa-window-frame::before { content: "\e04f"; }
.fa-window-frame-open::before { content: "\e050"; }
.fa-window-maximize::before { content: "\f2d0"; }
.fa-window-minimize::before { content: "\f2d1"; }
.fa-window-restore::before { content: "\f2d2"; }
.fa-windsock::before { content: "\f777"; }
.fa-wine-bottle::before { content: "\f72f"; }
.fa-wine-glass::before { content: "\f4e3"; }
.fa-wine-glass-alt::before { content: "\f5ce"; }
.fa-wine-glass-crack::before { content: "\f4bb"; }
.fa-wine-glass-empty::before { content: "\f5ce"; }
.fa-won::before { content: "\f159"; }
.fa-won-sign::before { content: "\f159"; }
.fa-worm::before { content: "\e599"; }
.fa-wreath::before { content: "\f7e2"; }
.fa-wreath-laurel::before { content: "\e5d2"; }
.fa-wrench::before { content: "\f0ad"; }
.fa-wrench-simple::before { content: "\e2d1"; }
.fa-x::before { content: "\58"; }
.fa-x-ray::before { content: "\f497"; }
.fa-xmark::before { content: "\f00d"; }
.fa-xmark-circle::before { content: "\f057"; }
.fa-xmark-hexagon::before { content: "\f2ee"; }
.fa-xmark-large::before { content: "\e59b"; }
.fa-xmark-octagon::before { content: "\f2f0"; }
.fa-xmark-square::before { content: "\f2d3"; }
.fa-xmark-to-slot::before { content: "\f771"; }
.fa-xmarks-lines::before { content: "\e59a"; }
.fa-y::before { content: "\59"; }
.fa-yen::before { content: "\f157"; }
.fa-yen-sign::before { content: "\f157"; }
.fa-yin-yang::before { content: "\f6ad"; }
.fa-z::before { content: "\5a"; }
.fa-zap::before { content: "\f0e7"; }
.fa-zzz::before { content: "\f880"; }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }
